import Image, {Shimmer,} from 'react-shimmer';
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";

const imagePlaceHolder = "http://kurdsale.com/images/logo.png";
const validURL = (str) => {
    const pattern = new RegExp("(http?:\\/\\/.*\\.(?:png|jpg))"); // fragment locator
    return !!pattern.test(str);
}

const ImageLoaderView = ({src, className, width, height}) => (
    <div style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 'auto',
        width: '100%',
        borderRadius: 8,
        overflow: "hidden",
        backgroundColor: "#f6f7f8"
    }}>
        <Image
            src={src }
            fadeIn={true}
            fallback={
                <Shimmer width={width} height={height}/>
            }
            errorFallback={() => <FontAwesomeIcon icon={faExclamationTriangle} color={"red"}/>}
            NativeImgProps={{
                className,
                style: {
                    width: width,
                    height: height
                },
            }}
        />
    </div>
);

ImageLoaderView.defaultProps = {
    width: 360,
    height: 360 * 9 / 16,
};

export default ImageLoaderView;