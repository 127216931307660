import {applyMiddleware, combineReducers, createStore} from 'redux'
import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';
import {filtersReducer} from 'src/components/filters';
import {mainLayoutReducer} from "src/layouts/main-layout";
import {actionsButtonsReducer} from 'src/components/actions-buttons';
import {SortReducer} from "src/pages/sidebar-search-filters/components/sort-action";
import {PageReducer} from "src/components/featured-cars";

const RootReducer = combineReducers({
    filters: filtersReducer,
    sort: SortReducer,
    page: PageReducer,
    mainLayout: mainLayoutReducer,
    user: actionsButtonsReducer,
});

const composeEnhancers = composeWithDevTools({
    realtime: true,
    hostname: 'localhost',
    port: 8000
});

export const store = createStore(RootReducer, composeEnhancers(
    applyMiddleware(thunk),
));

