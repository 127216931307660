import { getSlugData } from "src/pages/sidebar-search-filters/filters/filters.config";
import {CLEAR_FILTERS, SET_DEFAULT_FILTER, UPDATE_FILTERS} from "./types";

export const updateFilters = (name, value) => {
    return {
        type: UPDATE_FILTERS,
        name, value,
    };
};


export const clearFilters = (filters) => {
    return {
        type: CLEAR_FILTERS,
        filters: {},
    };
};



export const setDefaultFilter = (slug,filters) => {

    var defaultData = getSlugData(slug);
    var defaultFilter = {...filters,...defaultData.activeFilter}; 

    return {
        type: SET_DEFAULT_FILTER,
        filters : defaultFilter,
    };
};
