import React from "react";
import CarBoxView from "../../../../components/featured-cars/components/car-details-box/car-box";
import CarDetails from "../../../../components/featured-cars/components/car-details-box/car-details";

const SearchCarBox = ({car}) => {
    return (
        <div className="car-box-2">
            <div className="row">
                <div className="col-lg-5 col-md-5 col-pad">
                    <CarBoxView car={car} detailsUrl={`/car-details/${car.id}`}/>
                </div>
                <div className="col-lg-7 col-md-7 col-pad align-self-center">
                    <CarDetails car={car} detailsUrl={`/car-details/${car.id}`}/>
                </div>
            </div>
        </div>
    )
};
export default SearchCarBox;