import React, { useEffect } from "react";
import useAxios from 'axios-hooks';
import { withRouter } from "react-router-dom";
import API from "src/classes/API";
import AgentCarsView from "./agent-cars.view";
import { erasePageLoaderItem, insertPageLoaderItem } from "src/layouts/main-layout";
import { useDispatch } from "react-redux";

const AgentCarsContainer = ({ filters, page, onFilterChange, match: { params: { id } } }) => {
    const dispatch = useDispatch();
    const pageSize = 12;

    const [{ data: _data, loading, error: fetchCarsError }] = useAxios({
        url: API.cars,
        method: "PUT",
        data: {
            filters: filters,
            skip: (page - 1) * pageSize,
            take: pageSize,
            agency: id
        }
    });

    const [{ data: agent, loading: _loading, error: _error }] = useAxios({
        url: `${API.agent}/${id}`,
        method: "GET",
    });

    // change 2022 issue-4
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, [page]);
    // end

    if (_loading) {
        dispatch(insertPageLoaderItem("CarGalleriesData"));
        return "Loading";
    }

    dispatch(erasePageLoaderItem("CarGalleriesData"));

    const data = _data ?? {
        agencies: [],
        total: 0,
        totalFiltered: 0
    };

    const { cars, totalFiltered } = data;
    const totalPages = Math.ceil(totalFiltered / pageSize);

    return (
        <AgentCarsView
            page={page}
            totalPages={totalPages}
            loading={loading}
            cars={cars}
            agent={agent}
        />
    )
};

export default withRouter(AgentCarsContainer);
