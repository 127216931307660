import "./filters.style.scss";
import React, { Fragment, useState } from "react";
import FilterSelect from "src/components/forms/select/filter-select";
import PropTypes from "prop-types";
import classes from "classnames";
import { useTranslation } from "react-i18next";
import { generateToken } from "src/helper";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { useHistory } from "react-router-dom";
import FilterStatics from "src/classes/Filter.Statics";

const storeLocalItem = (key, value) => {
  localStorage.setItem(key, value);
};

const getLocalItem = (key) => {
  return localStorage.getItem(key);
};

const FiltersView = ({
  onClear,
  onChange,
  filtersConfig,
  filtersValue,
  className,
  loading,
  hasMore,
  onMore,
  ...rest
}) => {
  const { t } = useTranslation();
  const selectedFilter = filtersConfig
    // .filter((filter) => filter.name !== FilterStatics.specifc1)
    .filter((filter) => !filtersValue[filter.name])?.[0];

  const [seeMore, setSeeMore] = useState(false);
  // const [activeFilterTitle, setActiveFilterTitle] = useState("");

  let history = useHistory();

  const handleOnSelectFilter = (value, name, options = null) => {
    try {
      var title = "";
      title = options.find((option) => option.value === value)?.en;

      if (!title) {
        onChange(name, value);
        return false;
      }

      title = title
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");

      var url = "";

      switch (name) {
        case FilterStatics.specifc1:
          storeLocalItem("activeBrand", title);
          url = "" + title;
          break;

        case FilterStatics.specifc2:
          storeLocalItem("activeModel", title);
          url = `${getLocalItem("activeBrand")}_${title}`;
          break;

        case FilterStatics.specifc3:
          storeLocalItem("activeGroup", title);
          // console.debug(title);
          url = `${getLocalItem("activeBrand")}_${getLocalItem(
            "activeModel"
          )}_${title}`;

          break;

        case FilterStatics.specifc4:
          storeLocalItem("activeKind", title);
          url = `${getLocalItem("activeBrand")}_${getLocalItem(
            "activeModel"
          )}_${getLocalItem("activeGroup")}_${title}`;
          break;

        default:
          break;
      }

      // change 2022 issue-1
      let query = window.location.search;
      if (query) {
        window.history.pushState(
          "",
          "KurdSale",
          "/search/" + url + "/" + query
        );
      } else {
        window.history.pushState("", "KurdSale", "/search/" + url);
      }
      // end
      onChange(name, value);
    } catch (error) {
      onChange(name, value);
    }
  };

  const handleClearSearch = () => {
    history.push("/");
    onClear();
  };

  return (
    <div className={classes("search-box-2", className)}>
      <div className="container filters">
        <div className="row">
          <div className="col-lg-12">
            <div className="inline-search-area">
              <div className="row row-3 language-flex-direction">
                {filtersConfig.map(({ name, title, options }, index) => (
                  <div
                    key={index}
                    className="col-xl-2 col-lg-2 col-md-6 col-sm-6 col-6 search-col"
                  >
                    <FilterSelect
                      name={name}
                      title={title}
                      options={options}
                      value={filtersValue[name]}
                      onChange={(value) =>
                        handleOnSelectFilter(value, name, options)
                      }
                    />
                  </div>
                ))}
                {hasMore ? (
                  <Fragment>
                    <div className="col-xl-1 col-lg-1 col-md-3 col-sm-3 col-3 search-col">
                      <button
                        className="btn white-btn btn-search btn-block language-flex-direction d-flex align-items-center text-overflow-ellipsis p-1"
                        onClick={handleClearSearch}
                      >
                        <i className="fa fa-trash" />
                        <span>&nbsp;</span>
                        <OverlayTrigger
                          key={"reset"}
                          placement={"bottom"}
                          overlay={
                            <Tooltip id={`tooltip-${"bottom"}`}>
                              {t("reset")}
                            </Tooltip>
                          }
                        >
                          <strong className="text-overflow-ellipsis overflow-hidden language-text-align language-text-dir">
                            {t("reset")}
                          </strong>
                        </OverlayTrigger>
                      </button>
                    </div>

                    <div className="col-xl-1 col-lg-1 col-md-3 col-sm-3 col-3 search-col">
                      <button
                        className="btn white-btn btn-search  btn-block language-flex-direction d-flex align-items-center text-overflow-ellipsis p-1"
                        onClick={onMore}
                      >
                        <i className="fa fa-bars" />
                        <span>&nbsp;</span>
                        <OverlayTrigger
                          key={"more"}
                          placement={"bottom"}
                          overlay={
                            <Tooltip id={`tooltip-${"bottom"}`}>
                              {t("filterMore")}
                            </Tooltip>
                          }
                        >
                          <strong className="text-overflow-ellipsis overflow-hidden language-text-align language-text-dir">
                            {t("filterMore")}&nbsp;
                          </strong>
                        </OverlayTrigger>
                      </button>
                    </div>
                  </Fragment>
                ) : (
                  <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6 col-6 search-col ">
                    <button
                      className="btn white-btn btn-search  btn-block language-flex-direction-reverse d-flex text-overflow-ellipsis"
                      onClick={onClear}
                    >
                      <i className="fa fa-trash" />
                      <span>&nbsp;</span>
                      <OverlayTrigger
                        key={"reset"}
                        placement={"bottom"}
                        overlay={
                          <Tooltip id={`tooltip-${"bottom"}`}>
                            {t("reset")}
                          </Tooltip>
                        }
                      >
                        <strong className="text-overflow-ellipsis overflow-hidden language-text-align language-text-dir">
                          {t("reset")}&nbsp;
                        </strong>
                      </OverlayTrigger>
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="breadcrumb-areas language-text-dir">
              <ul className="breadcrumbs language-text-align">
                <li className="language-text-align">
                  <a onClick={() => onClear()}> {t("allCars")}</a>
                </li>
                {filtersConfig
                  .filter((filter) => filtersValue[filter.name])
                  .map((filter, index, array) => {
                    const title = filter.options.find(
                      (option) => option.value === filtersValue[filter.name]
                    )?.title;
                    return (
                      <li className="language-text-align text-light">

                        {" "}
                        {`- ${title}`}{" "}
                      </li>
                    );
                  })}
              </ul>
              {loading && (
                <div className="loading text-center"> {t("loading")} ... </div>
              )}
              {/*{<span className="text-center" style={{color: 'white'}}> Loading ... </span>}*/}
              {!loading && (
                <ul className="options">
                  {/*{console.log(filtersConfig)}*/}
                  {selectedFilter?.options
                    .slice(0, seeMore ? selectedFilter?.options.length : 19)
                    .map((item) => (


                      item.count != 0 ?
                        <li
                          className="language-text-align"
                          onClick={() =>
                            handleOnSelectFilter(
                              item.value,
                              selectedFilter.name,
                              selectedFilter?.options
                            )
                          }
                        >
                          <OverlayTrigger
                            key={generateToken(12)}
                            placement={"bottom"}
                            overlay={
                              <Tooltip id={`tooltip-${"bottom"}`}>
                                {`${item.title} (${item.count})`}
                              </Tooltip>
                            }
                          >
                            <a className="text-overflow-ellipsis">
                              <div className=" language-text-align justify-content-flex-start-ar">
                                <span>{item.title}</span>
                                <span>&nbsp;</span>
                                <span>({item.count})</span>
                              </div>
                            </a>
                          </OverlayTrigger>
                        </li>
                        : <a></a>

                    ))}


                  {!seeMore && selectedFilter?.options?.length > 40 && (
                    <li className="see-more language-text-align">
                      <a onClick={() => setSeeMore(true)}> {t("more")} </a>
                    </li>
                  )}
                  {seeMore && (
                    <li className="see-more language-text-align">
                      <a onClick={() => setSeeMore(false)}> {t("hide")} </a>
                    </li>
                  )}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
FiltersView.propTypes = {
  filtersConfig: PropTypes.array.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default FiltersView;
