import useAxios from "axios-hooks";
import API from "../../classes/API";
import React from "react";
import FiltersView from "./filters.view";
import { useHistory } from "react-router-dom";
import { carsSearchFilterPath } from "src/pages";
import FilterStatics from "src/classes/Filter.Statics";

const getLocalItem = (key) => {
  return localStorage.getItem(key);
};

const FiltersContainer = ({ filters, onClear, onChange, ...rest }) => {
  const [{ data: _data, loading, error }] = useAxios({
    url: API.filterInitialize,
    method: "PUT",
    data: filters,
  });

  const data = _data ?? {};
  
  const filterConfig = [
    { title: FilterStatics.specifc1, name: FilterStatics.specifc1, options: data[FilterStatics.specifc1] ?? [] },
    { title: FilterStatics.specifc2, name: FilterStatics.specifc2, options: data[FilterStatics.specifc2] ?? [] },
    { title: FilterStatics.specifc3, name: FilterStatics.specifc3, options: data[FilterStatics.specifc3] ?? [] },
    { title: FilterStatics.specifc4, name: FilterStatics.specifc4, options: data[FilterStatics.specifc4] ?? [] },
    // { title: "Type", name: "type", options: data["type"] ?? [] },
  ];

  const history = useHistory();

  // change 2022 issue-2 (click on (More) button so url not keep same search parameters)
  const handleMore = () => {
    let url = carsSearchFilterPath;
    let make = getLocalItem("activeBrand");
    let clas = getLocalItem("activeModel");
    let model = getLocalItem("activeGroup");
    let type = getLocalItem("activeKind");
    if (filters) {
      if (filters.make && !filters.model) {
        url = url + "/" + make;
      } else if (filters.class && filters.model && !filters.model) {
        url = url + "/" + `${clas}_${model}`;
      } else if (
        filters.make &&
        filters.class &&
        filters.model &&
        !filters.type
      ) {
        url = url + "/" + `${make}_${model}_${model}`;
      } else if (
        filters.make &&
        filters.class &&
        filters.model &&
        filters.type
      ) {
        url = url + "/" + `${make}_${clas}_${model}_${type}`;
      }
    }
    history.push(url);
  };

  return (
    <FiltersView
      filtersConfig={filterConfig}
      filtersValue={filters}
      onChange={onChange}
      onClear={onClear}
      onMore={handleMore}
      loading={loading}
      {...rest}
    />
  );
};

export default FiltersContainer;
