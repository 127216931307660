import axios from "axios";

axios.interceptors.request.use((config) => {
    const token = localStorage.getItem('deviceToken');
    const lang = localStorage.getItem('lang')  ?? 'en';

    if (token) {
        config.headers['device-token'] = token;
    }

    if (lang && !config.headers['lang']) {
        config.headers['lang'] = lang;
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});

// axios.interceptors.response.use((response) => {
//     const token = response?.data?.deviceToken;
//
//     if (token) {
//         localStorage.setItem('deviceToken', token);
//     }
//
//     return response;
// }, (error) => {
//     return Promise.reject(error);
// });