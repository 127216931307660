import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {updateFilters} from "src/components/filters";
import DependenciesContainer from "./dependencies.container";

const DependenciesRedux = () => {
    const filters = useSelector(state => state.filters);
    const dispatch = useDispatch();

    const handleFilterChange = (value) => {
        dispatch(updateFilters('region', value));
    };
    const handleLevelChange = (value) => {
        console.debug({value});
        dispatch(updateFilters('level', value));
    };
    return (
        <DependenciesContainer
            filters={filters}
            onFilterChange={handleFilterChange}
            handleLevelChange={handleLevelChange}
        />
    )
};

export default DependenciesRedux;