import {CLEAR_FILTERS, SET_DEFAULT_FILTER, UPDATE_FILTERS} from "./types";

const initState = {
    brand: null,
    model: null,
    kind: null,
    group: null,
    region: null,
    type: null,
    year: null,
    price: null,
    cylinder: null,
    sold: null,
    mileage: null,
    gear: null,
    fuel: null,
    horsePower: null,
    numberOfSeats: null,
    wheelSize: null,
    warranty: null,
    status: null,
    color: null,
    vehicleBodyStyle: null,
    transmission: null,
    import: null,
    publisher: null
};

const filtersTypes = {
    BRAND: 'brand',
    MODEL: 'model',
    KIND: 'kind',
    GROUP: 'group',
    REGION: 'region',
    LEVEL: 'level',
    TYPE: 'type',
    YEAR: "year",
    PRICE: "price",
    CYLINDER: "cylinder",
    SOLD: "sold",
    MILEAGE: "mileage",
    GEAR: "gear",
    COLOR: "color",
    FUEL: "fuel",
    TRANSMITION: "transmission",
    VehicleBodyStyle: "vehicleBodyStyle",
    HORSEPOWER: "horsePower",
    NUMBEROFSEATS: "numberOfSeats",
    WHEELSIZE: "wheelSize",
    WARRANTY: "warranty",
    STATUS: "status",
    IMPORT: "import",
    PUBLISHER: "publisher"
};
export const Reducer = (state = initState, action) => {
    console.log(action);
    console.log(state);
    switch (action.type) {
        case UPDATE_FILTERS:
            switch (action.name) {
                case filtersTypes.BRAND:
                    return Object.assign({}, state, {
                        ...state,
                        brand: action.value,
                        model: null,
                        kind: null,
                        group: null,
                    });

                case filtersTypes.MODEL:
                    return Object.assign({}, state, {
                        ...state,
                        model: action.value,
                        kind: null,
                        group: null,
                    });

                case filtersTypes.GROUP:
                    return Object.assign({}, state, {
                        ...state,
                        group: action.value,
                        kind: null,
                    });

                case filtersTypes.KIND:
                    return Object.assign({}, state, {
                        ...state,
                        kind: action.value,
                    });


                case filtersTypes.REGION:
                    return Object.assign({}, state, {
                        ...state,
                        region: action.value,
                    });
                case filtersTypes.LEVEL:
                        return Object.assign({}, state, {
                            ...state,
                            level: action.value,
                        });
    
                case filtersTypes.YEAR:
                    return Object.assign({}, state, {
                        ...state,
                        year: action.value,
                    });
                // case filtersTypes.Mileage:
                //     return Object.assign({}, state, {
                //         ...state,
                //         year: action.value,
                //     });

                case filtersTypes.PRICE:
                    return Object.assign({}, state, {
                        ...state,
                        price: action.value,
                    });

                case filtersTypes.TYPE:
                    return Object.assign({}, state, {
                        ...state,
                        type: action.value,
                    });

                case filtersTypes.IMPORT:
                    return Object.assign({}, state, {
                        ...state,
                        import: action.value,
                    });
                case filtersTypes.TRANSMITION:
                    return Object.assign({}, state, {
                        ...state,
                        transmission: action.value,
                    });

                case filtersTypes.VehicleBodyStyle:
                        return Object.assign({}, state, {
                            ...state,
                            vehicleBodyStyle: action.value,
                        });
                        
                case filtersTypes.COLOR:
                        return Object.assign({}, state, {
                            ...state,
                            color: action.value,
                        });
                case filtersTypes.STATUS:
                    return Object.assign({}, state, {
                        ...state,
                        status: action.value,
                    });

                case filtersTypes.PUBLISHER:
                    return Object.assign({}, state, {
                        ...state,
                        publisher: action.value,
                    });
                case filtersTypes.FUELTYPE:
                    return Object.assign({}, state, {
                        ...state,
                        fuelType: action.value,
                    });

                // case filtersTypes.CYLINDER:
                //     return Object.assign({}, state, {
                //         ...state,
                //         type: action.value,
                //     });
                //
                // case filtersTypes.SOLD:
                //     return Object.assign({}, state, {
                //         ...state,
                //         type: action.value,
                //     });
                //
                // case filtersTypes.MILEAGE:
                //     return Object.assign({}, state, {
                //         ...state,
                //         type: action.value,
                //     });
                //
                // case filtersTypes.GEAR:
                //     return Object.assign({}, state, {
                //         ...state,
                //         type: action.value,
                //     });
                //
                //
                // case filtersTypes.HORSEPOWER:
                //     return Object.assign({}, state, {
                //         ...state,
                //         type: action.value,
                //     });
                //
                // case filtersTypes.NUMBEROFSEATS:
                //     return Object.assign({}, state, {
                //         ...state,
                //         type: action.value,
                //     });
                //
                // case filtersTypes.WHEELSIZE:
                //     return Object.assign({}, state, {
                //         ...state,
                //         type: action.value,
                //     });

                default:
                    return Object.assign({}, state, {
                        ...state,
                        [action.name]: action.value,
                    });
            }

        case CLEAR_FILTERS:
            return initState;


        case CLEAR_FILTERS:
            return initState;


        case SET_DEFAULT_FILTER:
            console.table('action.filters',action.filters)
            return {...state,...action.filters};

        default:
            return state;
    }
};

export default Reducer;