import ImageLoaderView from "./image-loader-view";
import React from "react";


const ImageLoaderContainer = ({src}) => {
    return (
        <ImageLoaderView
            className="w-100"
            src={src}
        />
    );
}

export default ImageLoaderContainer;