/**
 * Generates random string.
 * @param length {int} The length of the random string.
 * @returns {string} The random string.
 */
export const generateToken = (length) => {
    const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789_';
    let token = '';
    for (let i = 0; i < length; i++)
        token += chars[Math.floor(Math.random() * chars.length)];
    return token;
};

export const generateArray = length =>  Array.from(Array(length).keys());