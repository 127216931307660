import {FeaturedCars, Filters, PageLoader, SideBanner, SubBanner} from "src/components";
import React, {Fragment} from "react";
import {useTranslation} from "react-i18next";

const WatchlistView = () => {
    const{t}=useTranslation();
    return (
        <Fragment>
            <PageLoader/>
            <SubBanner title={t("home")} name={t("favoriteCars")}/>
            <SideBanner/>
            {/* <Filters/> */}
            <FeaturedCars isWatchlist title="favoriteCars"/>
        </Fragment>
    );
};
export default WatchlistView;