import FeaturedCarsView from "./featured-cars.view";
import React, { useEffect } from "react";
import useAxios from "axios-hooks";
import API from "../../classes/API";
import { ErrorFeedback } from "src/components/feedback";
import stringQuery from "query-string";
import { Redirect } from "react-router-dom";

const navigateTo = (page, totalPages) => {
  const { query } = stringQuery.parse(window.location.search);

  // change 2022 issue-1 issue-3
  let path = window.location.pathname;

  if (path && path.includes("search")) {
    let convertedPath = path.endsWith("/") ? path.slice(0, -1) : path;
    return {
      pathname: convertedPath + "/",
      search: stringQuery.stringify({
        ...query,
        page: Math.max(1, Math.min(page, totalPages)),
      }),
    };
  }
  // end

  return {
    search: stringQuery.stringify({
      ...query,
      page: Math.max(1, Math.min(page, totalPages)),
    }),
  };
};

const FeaturedCarsContainer = ({
  filters,
  page,
  sort,
  onFilterChange,
  onPageChange,
  watchlist,
  title,
  isHome,
}) => {
  const pageSize = 12;
  const [{ data: _data, loading, error: fetchCarsError }] = useAxios({
    url: API.cars,
    method: "PUT",
    data: {
      filters: filters,
      sort: sort,
      skip: (page - 1) * pageSize,
      take: pageSize,
      watchlist: watchlist,
    },
  });

  const [{ data: _regionsData, loading: regionsLoading, error: regionsError }] =
    useAxios({
      method: "GET",
      url: API.filterRegions,
    });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [page]);

  const error = fetchCarsError || regionsError;
  if (!!error) return <ErrorFeedback error={error} />;

  const data = _data ?? {
    cars: [],
    total: 0,
    totalFiltered: 0,
  };

  const { cars: _cars, totalFiltered } = data;
  const totalPages = Math.ceil(totalFiltered / pageSize);

  if (!_cars) return <ErrorFeedback error={error} />;

  const cars = _cars.map(
    ({
      id,
      name,
      image,
      // images,\
      Price,
      Year,
      Mileage,
      City,
      AdNumber,
      // features,
      Sold,
      Card,
      // features: { tag, operationType },
    }) => ({
      id,
      Sold,
      // images,
      // features,
      Card,
      Price,
      Year,
      Mileage,
      City,
      AdNumber,
      name,
      image,
      // tag,
    })
  );

  const regions = _regionsData
    ? [{ title: "All", value: -1 }, ..._regionsData]
    : [];

  if (data.totalFiltered > 0 && cars.length === 0 && page !== 1) {
    return <Redirect to={navigateTo(1, totalPages)} />;
  }

  const selectedRegion = filters["region"];

  return (
    <FeaturedCarsView
      loading={loading}
      isWhatchList={watchlist} 
      isHome = {isHome}
      selectedRegion={selectedRegion}
      onFilterChange={onFilterChange}
      page={page}
      totalPages={totalPages}
      onPageChange={onPageChange}
      title={title}
      regions={regions}
      cars={cars}
    />
  );
};

export default FeaturedCarsContainer;
