import React from "react";
import PropTypes from 'prop-types';
import { BoxShimmer, FeedbackWrapper, SideBanner, SubBanner } from "../../components";
import { generateArray } from "src/helper";
import RegionFilter from "src/components/region-filter";
import InfiniteScroll from 'react-infinite-scroll-component';
import OffersBox from "src/components/latest-offers/component/offers-box-view";
import { useTranslation } from "react-i18next";
import GoogleAdsView from "src/components/google-ads/google-ads-view";
import useWindowDimensions from "src/hooks/useWindowDimensions";

const AgenciesView = ({ url, galleries, regions, selectedRegion, total, hasMore, onLoadMore, onFilterChange, loading, title }) => {
    const { t } = useTranslation();
    const { width } = useWindowDimensions();
    // console.table(galleries.length)
    return (
        <div className="gallery">
            <SubBanner />
            {(width <= 750) ?
                <GoogleAdsView
                    path='/21894097782/Kurdsale_300x250(1)'
                    size={[300, 250]}
                    id='div-gpt-ad-1617871697027-0'
                    style={{ width: '300px', height: '250px', margin: '10px auto 10px auto' }}
                />
                :
                <GoogleAdsView
                    path='/21894097782/Kurdsale_728x90_970x90_970x250(1)'
                    size={[[970, 90], [970, 250], [728, 90]]}
                    id='div-gpt-ad-1617871600777-0'
                    style={{ textAlign: 'center', padding: '10px 0px 10px 0px' }}
                />
            }
            <SideBanner />
            {(width <= 750) ?
                <GoogleAdsView
                    path='/21894097782/Kurdsale_300x250(2)'
                    size={[300, 250]}
                    id='div-gpt-ad-1617871672756-0'
                    style={{ width: '300px', height: '250px', margin: '10px auto 10px auto' }}
                />
                :
                <GoogleAdsView
                    path='/21894097782/Kurdsale_728x90_970x90_970x250(2)'
                    size={[[728, 90], [970, 90], [970, 250]]}
                    id='div-gpt-ad-1617871549077-0'
                    style={{ textAlign: 'center', padding: '10px 0px 10px 0px' }}
                />
            }
            <div className="featured-car mt-5 language-text-dir">
                <div className="container">
                    <div className="main-title mt2">
                        <h1>{t(`${title}`)}</h1>
                        <RegionFilter regions={regions} value={selectedRegion} onChange={onFilterChange} />
                    </div>
                    <FeedbackWrapper noResultFound={!loading && galleries.length === 0}>
                        <InfiniteScroll
                            className="row filter-portfolio"
                            dataLength={galleries.length}
                            next={onLoadMore}
                            hasMore={hasMore}
                            loader={
                                generateArray(12).map((i) =>
                                    <div key={`dummy-${i}`}
                                        className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="cars">
                                            <BoxShimmer number={0} />
                                        </div>
                                    </div>
                                )
                            }>
                            {galleries.map(({ name, images, image, id }, index) => {
                                return (
                                    <>
                                    {(index!=0&&index%9==0)&&
                                        <div key={`agency-${id}`} className="col-lg-12 col-md-12 col-sm-12 filtr-item">
                                            {console.log(index+1)}
                                         {(width<=750)?
                                             <GoogleAdsView 
                                             path='/21894097782/Kurdsale_300x250(3)'
                                             size={[300, 250]} 
                                             id='div-gpt-ad-1617871650849-0'
                                             style={{width:'300px',height:'250px',margin:'10px auto 10px auto'}}
                                             />
                                             :
                                             <GoogleAdsView 
                                             path='/21894097782/Kurdsale_728x90_970x90_970x250(3)'
                                             size={[[970, 90], [728, 90], [970, 250]]    } 
                                             id='div-gpt-ad-1617871393551-0'
                                             style={{textAlign:'center',padding: '10px 0px 10px 0px'}}
                                             />
                                         }
                                         </div>
                                     }
                                         <div key={`agency-${id}`} className="col-lg-4 col-md-6 col-sm-12 filtr-item">
                                             <div className="cars">
                                                 <OffersBox
                                                     name={name}
                                                     images={images}
                                                     mainImage={image}
                                                     loading={loading}
                                                     url={`${url}/${id}/${name.replace(/ /g, '-').toLowerCase()}`}
                                                 />
                                             </div>
                                         </div>
                                    </>
                                )
                            }
                            )}
                        </InfiniteScroll>
                    </FeedbackWrapper>
                </div>
            </div>
        </div>
    );
};

AgenciesView.propTypes = {
    hasMore: PropTypes.bool.isRequired,
    galleries: PropTypes.array.isRequired,
    regions: PropTypes.array.isRequired,
    selectedRegion: PropTypes.string,
    onLoadMore: PropTypes.func,
    onFilterChange: PropTypes.func,
    loading: PropTypes.bool,
    path: PropTypes.string,
};

export default AgenciesView;