import ActionsButtonsView from "src/components/actions-buttons/actions-buttons.view";
import React from "react";
import PropTypes from "prop-types";

const ActionsButtonsContainer = ({id,navigate, detailsUrl,wishlist, inWatchList, inCompareList, onCompareClick, onWatchlistClick}) => (
    <ActionsButtonsView
    id={id}
    navigate={navigate}
    detailsUrl={detailsUrl}
        wishlist={wishlist}
        inCompareList={inCompareList}
        inWatchList={inWatchList}
        onWatchlistClick={onWatchlistClick}/>
);

ActionsButtonsContainer.propTypes = {
    wishlist: PropTypes.bool,
    compare: PropTypes.bool,
};

ActionsButtonsContainer.defaultProps = {
    wishlist: true,
    compare: true,
}

export default ActionsButtonsContainer;