import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

const Error404View = (props) => {
    // console.log(props, "PROPS");
    const {title, text} = props;
    const{t}=useTranslation();
    return (
        <Fragment>
            <div>
                <div className="pages-404">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="error404-content">
                                    <h1>{t(`${title}`)}</h1>
                                    <p>{(`${text}`)}</p>
                                    <Link to="/" className="btn border-thn">{t("home")}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

Error404View.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
};

Error404View.defaultProps = {
    title: 'Oops... You got lost.',
    text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Esse non earum consectetur, ratione.',
};


export default Error404View;