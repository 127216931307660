import { Modal } from "react-bootstrap";
import { AppStoreImage, GooglePlayImage, KurdsaleLogo } from "src/layouts/main-layout/components/images";
import { Image } from "react-bootstrap";
import MobileDetect from 'mobile-detect';
import React, { Fragment, useEffect, useState } from "react";


const DownloadAppDialog = () => {
  const md = new MobileDetect(window.navigator.userAgent);

  const [showDialog, setShowDialog] = useState(false);
  const handleClose = () => {
    setShowDialog(false);
  }
  let deviceType;

  if (md.os() === 'AndroidOS') {
    deviceType = 'Android';
  } else if (md.os() === 'iOS') {
    deviceType = 'iOS';
  } else {
    deviceType = 'Desktop';
  }
  const dialogShows = JSON.parse(sessionStorage.getItem('dialogShows'));
  useEffect(() => {
    if (!dialogShows) {
      setShowDialog(true);
       sessionStorage.setItem('dialogShows', true);
    }
  }, []);
  const clickeLink = deviceType === 'Android' ? "https://play.google.com/store/apps/details?id=com.kurdsale&hl=en_US" : 'https://apps.apple.com/us/app/kurdsale-%D9%83%D9%88%D8%B1%D8%AF-%D8%B3%D9%8A%D9%84/id1007362393';

  return (
    deviceType === 'Desktop' ? <></> : <Modal show={showDialog} centered={true} onHide={handleClose} className="p-1" >
      <div className="bg-light">
        <a href={clickeLink}>
          <div className="modal-header bg-light" style={{ display: 'inline-block' }}>
            <h5 className="modal-title">Download Our App</h5>

          </div>
        </a>
        <button type="button" className="close" style={{
          width: '50px',
          height: '70px',
        }} onClick={handleClose}>
          &times;
        </button>
      </div>
      <a href={clickeLink}>
        <div className="modal-body bg-light">
          <p>
            Download our app to get the best experience while using our
            service.
          </p>
          {deviceType === 'Desktop' ?
            <></> : deviceType == 'Android' ? <div className=" " style={{ width: '30%', height: '10%', margin: 'auto' }}>
              <a href="https://play.google.com/store/apps/details?id=com.kurdsale&hl=en_US">
                <Image src={GooglePlayImage} fluid={true} />
              </a>
            </div> :
              <div style={{ width: '30%', height: '10%', margin: 'auto' }}>
                <a
                  href="https://apps.apple.com/us/app/kurdsale-%D9%83%D9%88%D8%B1%D8%AF-%D8%B3%D9%8A%D9%84/id1007362393">
                  <Image src={AppStoreImage} fluid={true} />
                </a>
              </div>}

        </div>
      </a>
    </Modal>
  );
}

export default DownloadAppDialog;