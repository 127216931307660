import useAxios from "axios-hooks";
import API from "../../../classes/API";
import React, { useEffect, useState } from "react";
import SidebarFilters from "./filters.view";

import { getFiltersConfig, getMoreFiltersConfig, getSlugData } from './filters.config';
import { ErrorFeedback, LoadingFeedback } from "src/components/feedback";

import { useParams } from 'react-router-dom'
import { useDispatch } from "react-redux";
import { setDefaultFilter } from "src/components/filters/redux/actions";
import Axios from "axios";

const getLocalItem = localStorage.getItem('__initialBrands__');
const FiltersContainer = ({ filters, onClear, onChange, isInit }) => {

    // let { slug } = useParams();

    // const dispatch = useDispatch();


    const [{ data: _staticData, _loading, _error }] = useAxios({
        url: API.filterInitialize,
        method: 'PUT',
        headers: {
            "lang": "en"
        },
        data: filters,
    });
    const [{ data: _data, loading, error }] = useAxios({
        url: API.filterInitialize,
        method: 'PUT',
        headers: {
            // "lang" : "en"
        },
        data: filters,
    });

    var data = _data ?? {};
    const staticData = _staticData ?? {};



    const filtersConfig = getFiltersConfig(data,);
    const moreFiltersConfig = getMoreFiltersConfig(staticData);



    if (!!error) return <ErrorFeedback error={error} />

    return (
        <SidebarFilters
            filtersConfig={filtersConfig}
            moreFiltersConfig={moreFiltersConfig}
            filtersValue={filters}
            onChange={onChange}
            onClear={onClear}
            loading={loading}
            data={data}
        />
    );
};

export default FiltersContainer;