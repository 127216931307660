import React, { useEffect, useState } from 'react'
import {clearFilters, updateFilters} from "../../components/filters/redux/actions";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from 'prop-types';
import SidebarSearchFiltersContainer from "src/pages/sidebar-search-filters/sidebar-search-filters-container";
import {useHistory, useLocation} from "react-router-dom";
import queryString from "query-string";

const FiltersRedux = ({reduxSelector}) => {
   
    const filters = useSelector(state => state.filters);
    const sort = useSelector(state => state.sort);
    const history = useHistory();
    const {query: {page: _page = "1"}} = queryString.parseUrl(history.location.search);
    const [page, setPage] = useState(parseInt(_page));
  
    useEffect(() => {
        return history.listen((location, action) => {
            const {query: {page}} = queryString.parseUrl(location.search);
            setPage(parseInt(page));
        })
    })


    return (
        <SidebarSearchFiltersContainer
            filters={filters}
            sort={sort}
            page={page}
        />
    );
};

FiltersRedux.propTypes = {
    stateSelector: PropTypes.string,
};

FiltersRedux.defaultProps = {
    reduxSelector: 'filters',
};

export default FiltersRedux;