import React from "react";
import OffersBox from "./component/offers-box-view";
import {FeaturedCarCardShimmer} from "src/components/featured-cars/components";
import {generateArray, generateToken} from "src/helper";
import {useTranslation} from "react-i18next";

const LatestOffersView = ({cars, loading}) => {
    const {t} = useTranslation();
    return (
        <div className="latest-offers categories content-area-13">
            <div className="container">
                <div className="main-title text-center">
                    <h1>{t("latestOffers")}</h1>
                    <p></p>
                </div>
                <div className="row row-2">
                    {loading && generateArray(3).map((_, index) =>
                        <div key={index} className="col-lg-4 col-md-12">
                            <div className="row">
                                <div className="col-md-6 col-lg-12 col-pad">
                                    <FeaturedCarCardShimmer detailsNumber={0}/>
                                </div>
                            </div>
                        </div>)}
                    {!loading && cars?.map(({id, Name, Image, Images, Price,}) =>
                        <div key={generateToken(16)} className="col-lg-4 col-md-12">
                            <div className="row">
                                <div className="col-md-6 col-lg-12 col-pad">
                                    <OffersBox isCar={true} name={Name} mainImage={Image} images={Images} loading={loading}
                                               price={Price}
                                               id={id} url={`/car-details/${id}/${Name.replace(/ /g, '-').toLowerCase()}`} overLay={true}/>
                                </div>
                            </div>
                        </div>)}
                </div>
            </div>
        </div>
    );
};
export default LatestOffersView;