import i18n from "../../../i18n";
import { UseAxios } from "axios-hooks";
import FilterStatics from "src/classes/Filter.Statics";

export const filtersTypes = {
    NORMAL_SELECT: "NORMAL_SELECT",
    RANGE_SLIDER: "RANGE_SLIDER",
    COLLAPSE: "COLLAPSE",
    MULTI_SELECT: "MULTI_SELECT",
};
export const getFiltersConfig = (data) => [
    {
        type: filtersTypes.NORMAL_SELECT,
        title: FilterStatics.specifc1,
        name: FilterStatics.specifc1,
        options: data[FilterStatics.specifc1] ?? []
    },{
        type: filtersTypes.NORMAL_SELECT,
        title: FilterStatics.specifc2,
        name: FilterStatics.specifc2,
        options: data[FilterStatics.specifc2] ?? []
    },{
        type: filtersTypes.NORMAL_SELECT,
        title:FilterStatics.specifc3,
        name: FilterStatics.specifc3,
        options: data[FilterStatics.specifc3] ?? []
    },
    {
        type: filtersTypes.NORMAL_SELECT,
        title: FilterStatics.specifc4,
        name: FilterStatics.specifc4,
        options: data[FilterStatics.specifc4] ?? []
    },
    {
        type: filtersTypes.RANGE_SLIDER,
        title: "price",
        name: 'price',
        range: data['price'] ?? {
            min: 0,
            max: 300000,
        },
        unit: "USD",
        step: 50
    },
    //TODO: Mileage Filter
    // {
    //     type: filtersTypes.RANGE_SLIDER,
    //     title: "mileage",
    //     name: 'mileage',
    //     range: data['mileage'] ?? {
    //         min: 0,
    //         max: 300000,
    //     },
    //     unit: "USD",
    //     step: 1
    // },
    {
        type: filtersTypes.RANGE_SLIDER,
        title: "year",
        name: "year",
        range: data['year'] ?? {
            min: 1950,
            max: new Date().getFullYear() + 1,
        },
    },

];

export const getMoreFiltersConfig = (data) => [
    {
        type: filtersTypes.NORMAL_SELECT,
        title: "transmission",
        name: 'transmission',
        options: data['Transmission'] ?? [],
    },
    // TODO : until set the api to bring gears data
    {
        type: filtersTypes.NORMAL_SELECT,
        title: "fuel",
        name: 'fuel',
        options: data['Fuel'] ?? []
    },
    // {
    //     type: filtersTypes.NORMAL_SELECT,
    //     title: "vehicleBodyStyle",
    //     name: 'vehicleBodyStyle',
    //     options: data['Vehicle body style'] ?? [],
    // },
    {
        type: filtersTypes.NORMAL_SELECT,
        title: "status",
        name: 'status',
        options: data['Status'] ?? [],
    },
    {
        type: filtersTypes.NORMAL_SELECT,
        title: "color",
        name: 'color',
        options: data['Color'] ?? []
    },
        
    // {
    //     type: filtersTypes.NORMAL_SELECT,
    //     title: "Status",
    //     name: 'status',
    //     options: data['status'] ?? []
    // },
    // {
    //     type: filtersTypes.NORMAL_SELECT,
    //     title: "Import",
    //     name: '',
    //     options: data['import'] ?? []
    // },
    // // there you can remove publish
    // {
    //     type: filtersTypes.NORMAL_SELECT,
    //     title: "Publisher",
    //     name: 'publisher',
    //     options: data['publisher'] ?? [],
    // },
    // {
    //     type: filtersTypes.NORMAL_SELECT,
    //     title: "Warranty",
    //     name: 'warranty',
    //     options: data['warranty'] 
    // },
];



const toSlog = (title) => {
    return title.toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
}

const saveSlug = (slug,remove = false) => {

    if(remove){
        window.localStorage.removeItem('__slug__');    
    }

    var exist = window.localStorage.getItem('__slug__') || '';

    window.localStorage.setItem('__slug__', exist == '' ? toSlog(slug) : exist + '_' + toSlog(slug));

}





export const getSlugData = (slug) => {


    try {

        const parseSlug = slug.split('_');

        const response = {
            type : [],
            model : [],
            group : [],
            kind : [],
            // type : [],
            activeBrand : {},
            activeModel : {},
            activeGroup : {},
            activeKind : {},
            activeRegion : {},
            activeFilter : {}
        }

        var getInitialData = localStorage.getItem('__initialBrands__');
        if(getInitialData){
            getInitialData = JSON.parse(getInitialData);
        }

        response.type = getInitialData.brand;
        if(parseSlug.length == 0){
            return response;
        }

        // GET MODEL FROM BRAND
        if(parseSlug[0]){

            const selectedBrand = getInitialData.brand.find((b) => toSlog(b.title) == parseSlug[0]);
            response.activeBrand = selectedBrand;

            // GET MODEL

            if(selectedBrand){
                saveSlug(selectedBrand.title,true);
                const model = getInitialData.model.filter(m => m.belongs == selectedBrand.value);
                response.model = model;
            }

        }

        //  // GET GROUP FROM MODEL
         if(parseSlug[1] && response.activeBrand){

            const selectedModel = getInitialData.model.find((b) => toSlog(b.title) == parseSlug[1] && b.belongs == response.activeBrand.value);
            response.activeModel = selectedModel;

            // GET MODEL
            if(selectedModel){
                saveSlug(selectedModel.title);
                const group = getInitialData.group.filter(m => m.belongs == selectedModel.value);
                response.group = group;
            }

        }


        // GET KIND FROM GROUP
        if(parseSlug[2] && response.activeModel.value){

            const selectedGroup = getInitialData.group.find((b) => toSlog(b.title) == parseSlug[2] && b.belongs == response.activeModel.value);
            response.activeGroup = selectedGroup;

            // GET MODEL

            if(selectedGroup){
                saveSlug(selectedGroup.title);
                const kind = getInitialData.kind.filter(m => m.belongs == selectedGroup.value);
                response.kind = kind;
            }

        }


        if(parseSlug[3] && response.activeGroup.value){

            const selectedKind = getInitialData.kind.find((b) => toSlog(b.title) == parseSlug[3] && b.belongs == response.activeGroup.value);
            response.activeKind = selectedKind;

            if(selectedKind){
                saveSlug(selectedKind.title);
            }

        }


        if(parseSlug[4]){
            const selectedRegion = getInitialData.region.find((b) => toSlog(b.title) == parseSlug[4]);
            response.activeRegion = selectedRegion;

            if(selectedRegion){
                saveSlug(selectedRegion.title);
            }

        }


        response.activeFilter = {
            brand : response.activeBrand?.value ? response.activeBrand.value : null,
            model : response.activeModel?.value ? response.activeModel.value : null,
            group : response.activeGroup?.value ? response.activeGroup.value : null,
            kind : response.activeKind?.value ? response.activeKind.value : null,
            region : response.activeRegion?.value ? response.activeRegion.value : null,
        }

        return response;

        
    } catch (error) {
        
        // console.table('ERR',error)
    }
    

    return {}

};