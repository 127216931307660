import Swal from 'sweetalert2'
import {CLEAR_COMPARE_LIST, TOGGLE_COMPARE_LIST_ITEM, TOGGLE_WATCHLIST_ITEM} from "./types";
import queryString from "query-string";
import {comparePath} from "src/pages";
import axios from "axios";
import API from "src/classes/API";

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

export const toggleWatchList = (id) => (dispatch, getState) => {
    const state = getState();
    const watchlist = state.user.watchList;

    if (watchlist.some(_id => _id === id)) {
        Toast.fire({
            icon: 'success',
            title: 'The car has been removed from watchlist successfully'
        });

    } else {
        axios.post(API.reaction, {id, type: 'favorite'});
        Toast.fire({
            icon: 'success',
            title: 'The car has been added to watchlist successfully'
        });
    }

    return dispatch({
        type: TOGGLE_WATCHLIST_ITEM, id,
    });
};

export const toggleCompareList = (id, history) => (dispatch, getState) => {
    const state = getState();
    const compareList = state.user.compareList;

    const isRemove = compareList.some(_id => _id === id);
    const isAddFirst = compareList.length === 0;

    let message = "";
    if (isRemove) {
        message = 'The car has been removed from compare successfully';
    } else if (isAddFirst) {
        message = 'The car has been added to compare successfully';
    }

    if (isRemove || isAddFirst) {
        Toast.fire({icon: 'success', title: message});

        return dispatch({
            type: TOGGLE_COMPARE_LIST_ITEM, id,
        });
    }

    axios.post(API.reaction, {first: compareList[0], second: id, type: 'compare'});
    history.push(queryString.stringifyUrl({
        url: comparePath,
        query: {
            first: compareList[0],
            second: id,
        }
    }));

    return dispatch({
        type: CLEAR_COMPARE_LIST
    });
};

