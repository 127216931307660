import React, { useEffect, useState } from "react";
import useAxios from "axios-hooks";
import API from "../../classes/API";
import DependenciesCarsView from "./dependencies-cars-view";
import {withRouter} from "react-router-dom";
import { ErrorFeedback } from "src/components/feedback";

const DependenciesCarsContainer = ({ page, filters, onFilterChange,match:{params:{id,name}}}) => {
    const title =`${name.charAt(0).toUpperCase() + name.slice(1).replace("-", " ")}`;;
    const pageSize = 12;
    const depId = id;  
    const [{ data: _data, loading, error: fetchCarsError }] = useAxios({
        url: API.cars,
        method: 'PUT',
        data: {
            filters: filters,
            skip: (page - 1) * pageSize,
            take: pageSize,
            dependency: depId
        },
    });

    const [{ data: _regionsData, loading: regionsLoading, error: regionsError }] = useAxios({
        method: 'GET',
        url: API.filterRegions,
    });

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [page]);

    const error = fetchCarsError || regionsError;
    if (!!error) return <ErrorFeedback error={error} />;

    const data = _data ?? {
        cars: [],
        total: 0,
        totalFiltered: 0
    };

    const { cars: _cars, totalFiltered } = data;
    const totalPages = Math.ceil(totalFiltered / pageSize);

    if (!_cars) return <ErrorFeedback error={error} />;

    const cars = _cars.map(({ id, name, image,  features,
        Price,
        Year,
        Mileage,
        Card,
        City,
        Sold,
        AdNumber,
    }) => ({
        id,
        features,
        Price,
        Year,
        Mileage,
        City,
        Card,
        Sold,
        AdNumber,
        name,
        image,
    }));

    const regions = _regionsData ?? [];
    const selectedRegion = filters['region'];


    return (
        <DependenciesCarsView
            page={page}
            title={title}
            totalPages={totalPages}
            loading={loading}
            selectedRegion={selectedRegion}
            onFilterChange={onFilterChange}
            regions={regions}
            cars={cars}
        />
    );
};

export default withRouter(DependenciesCarsContainer);