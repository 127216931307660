import API from "src/classes/API";
import useAxios from "axios-hooks"
import {ErrorFeedback} from "src/components";
import React from "react";
import SideBannerView from "./side-banner.view";

const SideBannerContainer = ({}) => {

    // const [{data: _data, loading, error}] = useAxios({
    //     url: API.sideBanner,
    //     method: "GET"
    // })

    // if (error)
    //     return (<ErrorFeedback/>);


    // const data = _data ?? Array(2);
    return (
        <SideBannerView 
        // images={data} loading={loading}
        />
    )

}
export default SideBannerContainer;