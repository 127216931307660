import "./call-dialog-button.style.scss";

import React, { Fragment, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { faPhoneSquareAlt } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";
import Axios from "axios";
import API from "src/classes/API";

import { Dialog, List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { Phone, WhatsApp } from '@material-ui/icons';

const PhoneDialog = ({id, open, onClose, phones }) => {
  const { t } = useTranslation();

  const [copied, setCopied] = useState(false);
  const [copiedExtra, setCopiedExtra] = useState(false);
  const handleCopy = () => {
    setCopied(true);
    setCopiedExtra(false);
  };
const pushAction = ()=>{
  Axios.post(API.action,{'category':'ad','actions':id,'name':'call','source':'web',});
}

  return (
    <Dialog open={open} onClose={onClose} className="language-text-dir">
      <span className="m-1 p-3">{t('callNow')} </span>
      {/* <hr/> */}
      
        {phones?.map(phone => (
          <>{phone.number ? (
            <div onClick={pushAction} className="d-flex justify-content-between justify-content-lg-start m-2 p-3">
              <span className="call-dialog-button--number text-lg pr-4">
                {" "}
                {`0${phone.number.slice(-10)}`}{" "}
              </span>
              {/* Check phone number call +964 */}
              <a target="_blank" title="Call" href={`tel:00${phone.number}`}>
                <FontAwesomeIcon
                  className="ml-1 mr-1 hover-pointer call-dialog-button--icon"
                  icon={faPhoneSquareAlt}
                  color="blue"
                />
              </a>
              <a
                target="_blank"
                title="Whatsapp"
                href={`https://api.whatsapp.com/send?phone=${phone.number}`}
              >
                <FontAwesomeIcon
                  className="ml-1 mr-1 hover-pointer call-dialog-button--icon"
                  icon={faWhatsapp}
                  color="green"
                />
              </a>

              <CopyToClipboard text={phone.number} onCopy={handleCopy}>
                <FontAwesomeIcon
                  className="ml-1 mr-1 hover-pointer call-dialog-button--icon"
                  icon={faCopy}
                  color="red"
                />
              </CopyToClipboard>
            </div>
          ) : null}

          </>
        ))}
    </Dialog>
  );
};
export default PhoneDialog;

// const CallDialogButton = ({ phones, title }) => {
//   const { t } = useTranslation();
//   const [show, setShow] = useState(false);
//   const [copied, setCopied] = useState(false);
//   const [copiedExtra, setCopiedExtra] = useState(false);

//   const handleCopy = () => {
//     setCopied(true);
//     setCopiedExtra(false);
//   };
//   const handleCopyExtra = () => {
//     setCopied(false);
//     setCopiedExtra(true);
//   };

//   let valid;
//   let onlyNumberTwo;
//   // if (number && number.length > 3) {
//   //   valid = true;
//   // }
//   // const styleNumber = (number) =>
//   //   valid && `0${number.slice(3)}`.replace(/(\d{3})(\d{3})(\d{4})/, "$1$2$3");

//   const handleClick = () => {
//     // axios.post(API.reaction, {type: "call" });
//     setShow(true);
//   };

//   return (
//     <div className=" language-text-dir">
//       {valid && (
//         <Button
//           disabled={!valid}
//           variant="none"
//           onClick={handleClick}
//           style={{ background: "#f0151f", color: "white" }}
//         >
//           {" "}
//           {title}{" "}
//         </Button>
//       )}

//       {/*{valid && isMobile &&*/}

//       <Modal show={show} centered={true} onHide={() => setShow(false)}>
//         <Modal.Header closeButton={true}>{title}</Modal.Header>
//         <Modal.Body className="social-links d-flex flex-column call-dialog-button">
//           {/* change 2022 issue-7 */}
//           {phones?.map((number, whatsapp, description) =>
//             <>{number ? (
//               <div className="d-flex justify-content-between justify-content-lg-start">
//                 <span className="call-dialog-button--number">
//                   {" "}
//                   {number}{" "}
//                 </span>
//                 <a target="_blank" title="Call" href={`tel:00${number}`}>
//                   <FontAwesomeIcon
//                     className="ml-1 mr-1 hover-pointer call-dialog-button--icon"
//                     icon={faPhoneSquareAlt}
//                     color="blue"
//                   />
//                 </a>
//                 <a
//                   target="_blank"
//                   title="Whatsapp"
//                   href={`https://api.whatsapp.com/send?phone=${number}`}
//                 >
//                   <FontAwesomeIcon
//                     className="ml-1 mr-1 hover-pointer call-dialog-button--icon"
//                     icon={faWhatsapp}
//                     color="green"
//                   />
//                 </a>

//                 <CopyToClipboard text={number} onCopy={handleCopy}>
//                   <FontAwesomeIcon
//                     className="ml-1 mr-2 hover-pointer call-dialog-button--icon"
//                     icon={faCopy}
//                     color="red"
//                   />
//                 </CopyToClipboard>
//                 {copied ? <span style={{ color: "red" }}>Copied.</span> : null}
//               </div>
//             ) : null}

//             </>
//           )}
//           <hr />

//           {/* <div className={`d-flex ${onlyNumberTwo ? "hideExtraNum" : ""}`}> */}
//           {/* <div className={`d-flex`}>
//             {numberExtra ? (
//               <Fragment>
//                 <span className="call-dialog-button--number">
//                   {" "}
//                   {styleNumber(numberExtra)}{" "}
//                 </span>
//                 <a target="_blank" title="Call" href={`tel:00${numberExtra}`}>
//                   <FontAwesomeIcon
//                     className="ml-1 mr-1 hover-pointer call-dialog-button--icon"
//                     icon={faPhoneSquareAlt}
//                     color="blue"
//                   />
//                 </a>
//                 <a
//                   target="_blank"
//                   title="Whatsapp"
//                   href={`https://api.whatsapp.com/send?phone=${numberExtra}`}
//                 >
//                   <FontAwesomeIcon
//                     className="ml-1 mr-1 hover-pointer call-dialog-button--icon"
//                     icon={faWhatsapp}
//                     color="green"
//                   />
//                 </a>

//                 <CopyToClipboard text={numberExtra} onCopy={handleCopyExtra}>
//                   <FontAwesomeIcon
//                     className="ml-2 mr-2 hover-pointer call-dialog-button--icon"
//                     icon={faCopy}
//                     color="red"
//                   />
//                 </CopyToClipboard>
//                 {copiedExtra ? (
//                   <span style={{ color: "red" }}>Copied.</span>
//                 ) : null}
//               </Fragment>
//             ) : null}
//           </div> */}
//           {/* end */}
//         </Modal.Body>
//       </Modal>
//     </div>
//   );
// };
