import './language-switcher.style.scss';
import React, {useState} from "react";
import classes from 'classnames';
import {IraqFlag, KurdFlag, UkFlag} from "./icons";
import {Image} from "react-bootstrap";

const languages = [
    {title: "ENGLISH", icon: UkFlag, value: "en"},
    {title: "عربي", icon: IraqFlag, value: "ar"},
    {title: "كوردى", icon: KurdFlag, value: "fr"},
];

const LanguageSwitcherView = ({language, onLanguageChange}) => {
    const [show, setShow] = useState(false);

    const selectedLanguage = languages.find(({value, icon}) => value === language);
    return (
        <div className="language-switcher">
            <div className={classes("custom-sel", {"show-sel": show})}
                 onMouseEnter={() => setShow(true)}
                 onMouseLeave={() => setShow(false)}

            >
                <span className="selected"
                      onClick={() => setShow(!show)}
                      onBlur={() => setShow(false)}

                >
                    {selectedLanguage.title}
                    {/* <Image className="flag" fluid={true} src={selectedLanguage.icon}/> */}
                    {/*{!show && <i className="fa fa-caret-down lightblue m-1" aria-hidden="true"/>}*/}
                </span>
                {languages.filter(({value, icon}) => value !== language).map(({title, icon, value}) =>
                    <span className={classes({hidden: !show})}
                          onClick={() => onLanguageChange(value)}>
                        {title}
                        {/* <Image className="flag" fluid={true}src={icon}/> */}
                    </span>)}
            </div>
        </div>
        // <select className="selectpicker language-switcher" data-width="fit" value={language}
        //         onChange={(e) => onLanguageChange(e.target.value)}>
        //     <option data-content='<span class="flag-icon flag-icon-us"></span> English' value='en'><Image
        //         src={UkFlag}/> English
        //     </option>
        //     <option data-content='<span class="flag-icon flag-icon-mx"></span> Iraq' value='ar'><Image
        //         src={IraqFlag}/> Arabic
        //     </option>
        //     <option data-content='<span class="flag-icon flag-icon-mx"></span> Kurd' value='fr'><Image
        //         src={KurdFlag}/> Kurdish
        //     </option>
        // </select>
        //
    );
}

export default LanguageSwitcherView;
