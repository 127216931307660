import React, {useEffect, useReducer, useState} from "react";
import useAxios from "axios-hooks";
import API from "../../classes/API";
import {withRouter} from "react-router-dom";
import DependenciesView from "./dependencies.view";
import {ErrorFeedback} from "src/components/feedback";

const DependenciesContainer = ({filters, onFilterChange,handleLevelChange, match: {path,params:{id,name}}}) => {

    let  title;
    switch (path) {
        case `/companies/:id/:name`:
            title = `${name.charAt(0).toUpperCase() + name.slice(1).replace("-", " ")}`;
            break;
        default:
            title = "NA";
    }

    const pageSize = 12;
    const [page, setPage] = useState(1);
    const handleLoadMore = ()=>{
       setPage(page + 1)
    };

    const [{data: _data, loading: fetchLoading, error: fetchError}] = useAxios({
        url: `${API.dependencies}/${id}`,
        method: 'PUT',
        data: { 
            region: filters.region,
            level: filters.level,
            skip: 0,
            take: (page) * pageSize,
        },
    });

    const [{data: _regionsData, loading: regionsLoading, error: regionsError}] = useAxios({
        method: 'GET',
        url: API.filterRegions,
    });

    const [agencies, dispatch] = useReducer((myArray, {type, value}) => {
        switch (type) {
            case "add":
                return [...myArray, ...value.filter(({id}) => !myArray.some(({id: _id}) => id === _id))];
            case "clear":
                return [];
            default:
                return myArray;
        }
    }, []);

    useEffect(() => {
        setPage(1);
        dispatch({type: 'clear'});
    }, [filters]);


    const loading = fetchLoading || regionsLoading;
    const error = fetchError || regionsError;

    if (!!error) return <ErrorFeedback error={error}/>;

    const regions = _regionsData ? [
        {title: "All", value: -1},
        ..._regionsData,
    ] : [];

    const selectedRegion = filters['region'];
    const selectedLevel = filters['level'];
    const data = _data ?? {
        dependency_details: [],
        total: 0,
        totalFiltered: 0
    };

    const {dependency_details: newAgencies, totalFiltered} = data;

    if (!loading) {
        if (agencies.length === 0 && newAgencies.length !== 0) {
            dispatch({type: "add", value: newAgencies}); //newAgencies.map(item => ({key: item.id, ...item})));
        } else if (agencies.length !== 0 && newAgencies.length !== 0 && agencies[agencies.length - 1].id !== newAgencies[newAgencies.length - 1].id) {
            dispatch({type: "add", value: newAgencies}); //newAgencies.map(item => ({key: item.id, ...item})));
        }
    }

    return (
        <DependenciesView
            total={totalFiltered}
            url={`/companies/${name}`}
            loading={loading}
            selectedRegion={selectedRegion}
            selectedLevel={selectedLevel}
            onFilterChange={onFilterChange}
            handleLevelChange={handleLevelChange}
            regions={regions}
            onLoadMore={handleLoadMore}
            hasMore={agencies.length < totalFiltered}
            galleries={agencies}
            title={title}
        />
    );

};

export default withRouter(DependenciesContainer);