import React, { useEffect, useState } from "react";
import useAxios from "axios-hooks";
import API from "src/classes/API";
import axios from "axios";
import { Modal } from "react-bootstrap";
import Cookie from 'universal-cookie';
import CloseIcon from '@material-ui/icons/Close';

const WelcomeBannerView = () => {
    const [show, setShow] = useState(false)
    const [image, setImage] = useState([])
    const CloseButton = ({ onClick }) => (
        <button className="close-button" onClick={onClick}>
          &times;
        </button>
      );
    var cookies = new Cookie();
    useEffect(() => {
        fetch(API.welcomeBanner)
            .then(response => response.json())
            .then(data=>setImage(data.banner.album[0].image))
            .catch(error => console.error(error));

        var deviceToken = localStorage.getItem('deviceToken');
        
        if (cookies.get('displyPop') !== 'no1') {
            if(image !== null){
                setShow(true);
            }
        }
        cookies.set('displyPop', 'no1', { path: '/' });
    }, []);
    function handleClick() {
        setShow(false)
    }
    return (
        <>
            <Modal show={show} size="lg" centered={true} onHide={() => setShow(false)}>
                {/* <div style={{ backgroundColor: 'transparent', margin: 'auto' }}> */}
                    <img src={image}>
                    </img>
                    <CloseButton onClick={handleClick} />
                {/* </div> */}
            </Modal>
            
        </>
    )
}
export default WelcomeBannerView
