import React, {useEffect} from "react";
import $ from 'jquery';

const PageLoaderView = ({active}) => {
    useEffect(() => {
        if (active) $(".page_loader").fadeIn("fast");
        else $(".page_loader").fadeOut("fast");
    }, [active]);

    return (
        <div className="page_loader"/>
    );
};

export default PageLoaderView;