import Header from "./components/header";
import React, {Fragment} from "react";
import Footer from "./components/footer";
import {PageLoader, WhatsappFloatingButton} from "src/components";
import {useTranslation} from "react-i18next";

const MainLayoutView = ({loading, children, withOverlay}) => {
    const {t} = useTranslation();
    return (
        <Fragment>
            <PageLoader active={loading}/>
            <WhatsappFloatingButton number={t('phone')}/>
            <Header withOverlay={withOverlay}/>
            {children}
            <Footer/>
        </Fragment>
    );
};

export default MainLayoutView;