import {SET_SORT} from "./types";

const initState = {
    field: "",
    order: "",
};

export const Reducer = (state = initState, action) => {
    switch (action.type) {

        case SET_SORT:
            return Object.assign({}, state, {
                ...state,
                field: action.field,
                order: action.order,
            });

        default:
            return state;
    }
};

export default Reducer;