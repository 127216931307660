import {CLEAR_QUEUE, ERASE_ITEM, INSERT_ITEM} from "./types";

export const insertItem = (id) => ({
    type: INSERT_ITEM,
    item: id
});

export const eraseItem = (id) => ({
    type: ERASE_ITEM,
    item: id
});

export const clear = () => ({
    type: CLEAR_QUEUE,
});

