import './whatsapp-floating-button.style.scss';
import React, {useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookMessenger} from "@fortawesome/free-brands-svg-icons";
const WhatsappFloatingButton = ({number}) => {
    const [show, setShow] = useState(false);
    const {t} = useTranslation();

    return (
        <div className="whatsapp-floating-button float ">
            <a className="hover-pointer" onClick={() => setShow(true)}>
                <FontAwesomeIcon className="my-float" icon={faFacebookMessenger}/>
            </a>

            <Modal show={show} centered={true} onHide={() => setShow(false)} >
                <Modal.Header className="language-text-dir bg-light" closeButton={true} onHide={() => setShow(false)}>
                    <h5>{t('contactToSiteAdministrationHeader')}</h5>
                </Modal.Header>
                <Modal.Body className="d-flex flex-column bg-light">
                    <span className="language-text-dir language-text-align mb-3">{t('contactToSiteAdministrationBody')}</span>
                    <a className="language-text-align" href={`https://m.me/KurdSale/`} target="_blank" onClick={() => setShow(false)}>
                        <Button className="whatsapp-floating-button--contact-button">
                            <span className="m-2"> {t('contactToSiteAdministrationButton')} </span>
                            <FontAwesomeIcon icon={faFacebookMessenger}/>
                        </Button>
                    </a>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default WhatsappFloatingButton;
