import { FeaturedCars, LatestOffers, PageLoader, SubBanner, Filters, SideBanner } from "src/components";
import React, { Fragment, useEffect, useState } from "react";
import WelcomeBannerView from "src/components/welcome-banner/welcome-banner-view";
import GoogleAdsView from "src/components/google-ads/google-ads-view";
import useWindowDimensions from "src/hooks/useWindowDimensions";

import API from "src/classes/API";

import axios from 'axios';
const HomePage = (props) => {
 

  const { width } = useWindowDimensions();

  const [hasInitialData, setInitialData] = useState(false);


  useEffect(() => {


    const initialBrabdsData = localStorage.getItem('__initialBrands__');

    if (!initialBrabdsData) {

      try {

        axios.get(API.getInitialData)
          .then(({ data }) => {
            localStorage.setItem('__initialBrands__', JSON.stringify(data));
            setInitialData(true);
          });
      } catch (error) {
        // console.table('Failed to load initial Data');
      }

    } else {
      setInitialData(true);
    }

  }, []);



  if (!hasInitialData) {
    return <div className="page_loader" style={{ display: 'block' }} />
  }


  return (

    <Fragment>
      <WelcomeBannerView />
      <SubBanner />
      {(width <= 750) ?
        <GoogleAdsView
          path='/21894097782/Kurdsale_300x250(1)'
          size={[300, 250]}
          id='div-gpt-ad-1617871697027-0'
          style={{ width: '300px', height: '250px', margin: '10px auto 10px auto' }}
        />
        :
        <GoogleAdsView
          path='/21894097782/Kurdsale_728x90_970x90_970x250(1)'
          size={[[970, 90], [970, 250], [728, 90]]}
          id='div-gpt-ad-1617871600777-0'
          style={{ textAlign: 'center', padding: '10px 0px 10px 0px' }}
        />
      }
      <SideBanner />

      <Filters hasMore />

      {(width <= 750) ?
        <GoogleAdsView
          path='/21894097782/Kurdsale_300x250(2)'
          size={[300, 250]}
          id='div-gpt-ad-1617871672756-0'
          style={{ width: '300px', height: '250px', margin: '10px auto 10px auto' }}
        />
        :
        <GoogleAdsView
          path='/21894097782/Kurdsale_728x90_970x90_970x250(2)'
          size={[[728, 90], [970, 90], [970, 250]]}
          id='div-gpt-ad-1617871549077-0'
          style={{ textAlign: 'center', padding: '10px 0px 10px 0px' }}
        />
      }
      <FeaturedCars watchlist={false} homeScreen />

      {(width <= 750) ?
        <GoogleAdsView
          path='/21894097782/Kurdsale_300x250(3)'
          size={[300, 250]}
          id='div-gpt-ad-1617871650849-0'
          style={{ width: '300px', height: '250px', margin: '10px auto 10px auto' }}
        />
        :
        <GoogleAdsView
          path='/21894097782/Kurdsale_728x90_970x90_970x250(3)'
          size={[[970, 90], [728, 90], [970, 250]]}
          id='div-gpt-ad-1617871393551-0'
          style={{ textAlign: 'center', padding: '10px 0px 10px 0px' }}
        />
      }

      <LatestOffers />
    </Fragment>
  )
};


export default HomePage;

