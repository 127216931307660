import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
   /* subject: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    email: Yup.string()
        .email('Invalid email')
        .required('Required'),
    number: Yup.string()

        .min(10, 'Invalid number')
        .max(13, 'Invalid number')
        .required('Required'),
    messag: Yup.string()
        .min(15, 'Too Short!')
        .required('Required')
*/});