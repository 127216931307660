import i18n from "i18next";
import {useTranslation, initReactI18next} from "react-i18next";
import translationEN from './lang/english/translation.json';
import translationAR from './lang/arabic/translation.json';
import translationFR from './lang/turkish/translation.json';


const resources = {
    en: {
        translation: translationEN
    },
    ar: {
        translation: translationAR
    },
    fr:{
        translation:translationFR
    }
};

const lang= localStorage.getItem('lang') ?? 'en';
i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: resources,
        lng: lang,
        fallbackLng: "en",

        interpolation: {
            escapeValue: false
        }
    });
export default i18n;
