import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {updateFilters} from "src/components/filters";
import CarGalleriesContainer from "./agent-cars.container";
import {useHistory} from "react-router-dom";
import queryString from "query-string";

const GalleriesRedux = () => {
    const filters = useSelector(state => state.filters);
    const dispatch = useDispatch();
    const history = useHistory();

    const {query: {page: _page = "1"}} = queryString.parseUrl(history.location.search);
    const [page, setPage] = useState(parseInt(_page));
    const handleFilterChange = (value) => {
        dispatch(updateFilters('region', value));
        setPage(1);
    };

    useEffect(() => {
        return history.listen((location, action) => {
            const {query: {page}} = queryString.parseUrl(location.search);
            setPage(parseInt(page));
        })
    });


    return (
        <CarGalleriesContainer
            page={page}
            filters={filters}
            onFilterChange={handleFilterChange}
        />
    )
};

export default GalleriesRedux;