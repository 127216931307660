
import { SubBanner, SideBanner } from "src/components";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import API from "src/classes/API";


const ServicesView = (description) => {
    const { t } = useTranslation();
    // const description = t("servicesList", {returnObjects: true})
    const [data, setData] = useState(null);

    useEffect(() => {
        axios.get(API.services)
            .then(response => {
                setData(response.data);
            });
    }, []);
    return (
        <Fragment>
            <SubBanner title={t("home")} name={t("servicesTerms")} />
            <SideBanner />
            <div className="contact-2 content-area-5 language-text-dir">
                <div className="container">
                    {/*!-- Main title --*/}
                    <div className="main-title text-center">
                        <h1>{t("servicesTerms")}</h1>
                    </div>
                    {/*<!-- Contact info -->*/}
                    <div className="content language-text-align">
                    {/* {data && (
                        <ul>
                    {data.Content.split('\r\n').map((item, idx) =>
                            
                                <li className="service-info"key={idx}>{item}</li>
                                )}</ul>)} */}

                        {data && (
                            <ul>
                                {data.Content.split('\n').map((item, index) => (
                                    <li className="bg-white m-2 p-2" key={index}>{item}</li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ServicesView;