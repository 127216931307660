import {Route} from "react-router-dom";
import React from "react";
import PropTypes from "prop-types";

const AppRouteView = props => {
const {layout: Layout, component: Component, children, withOverlay, ...rest} = props;


    return (
        <Route {...rest}>
            <Layout withOverlay={withOverlay}>
                <Component/>
            </Layout>
        </Route>
    )
};

AppRouteView.propTypes = {
    layout: PropTypes.any,
    component: PropTypes.any,

    path: PropTypes.string,
    exact: PropTypes.bool,
};

export default AppRouteView;