import React from "react";
import PopupGalleryButtonView from "./popup-gallery-button.view";

const PopupGalleryButtonContainer = ({images, title}) => {
    return (
        <PopupGalleryButtonView title={title} images={images}/>
    )
};


export default PopupGalleryButtonContainer;