import React from "react";
import propsType from "prop-types";

const MultiSelect = ({data, name, onChange}) => {
    return (
        <div id="options-content" className="collapse">
            <h3 className="sidebar-title">{name}</h3>
            {data.map((brand, index) =>
                <div key={index} className="checkbox checkbox-theme checkbox-circle">
                    <input id={brand.id} type="checkbox" onChange={onChange}/>
                    <label htmlFor={brand.id}>
                        {brand.title}
                    </label>
                </div>)}
            <br/>
        </div>
    );
};

MultiSelect.propsType = {
    data: propsType.arrayOf(propsType.shape({
        id: propsType.oneOf([propsType.string, propsType.number])
    })).isRequired,
    name: propsType.string,
    onChange: propsType.func
};
export default MultiSelect;