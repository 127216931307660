import React, {useState} from 'react';
import {Image} from "react-bootstrap";
import {AppStoreImage, GooglePlayImage, KurdsaleLogo} from "src/layouts/main-layout/components/images";
import axios from "axios";
import API from "src/classes/API";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import useAxios from "axios-hooks";
import GoogleAdsView from 'src/components/google-ads/google-ads-view';

const Footer = ({onSubmit, ...props}) => {
    const {t} = useTranslation();
    const [{ data: _data, loading, error: fetchError }] = useAxios({
        url: API.contactUs,
        method: "GET",
    });
    const data = _data?.data; 
    const {contactInfo} = props;
    var year = new Date().getFullYear();
    // const subscribeText = contactInfo?.description ?? "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit";
    const [email, setEmail] = useState("");

    const ContactInfo = (props) => {

        const location = data?.address;
        // const phone = t("phone", "en");
        // const mobile = t("mobile", "en");
        // const twitterLink = t("twitterLink", "en");
        // const googleLink = t("googleLink", "en");

        const email = data?.email;
        const facebookLink = t("facebookLink", "en");
        const instagramLink = t("instagramLink", "en");

        return (
            <div className="footer-item clearfix language-text-align">
                <Image src={KurdsaleLogo} alt="logo" className="f-logo" fluid={true}/>
                <ul className="contact-info">
                    <li className="justify-content-start d-flex language-text-align">
                        <i className="flaticon-pin"/>{location}
                    </li>
                    <li className="justify-content-start d-flex language-text-align">
                        <i className="flaticon-mail"/><a href="mailto:info@kurdsale.com">{email}</a>
                    </li>
                </ul>
                <div className="clearfix"/>
                <div className="social-list-2">
                    <ul>
                        <li><a href={facebookLink} className="facebook-bg" target="_blank"><i
                            className="fa fa-facebook"/></a></li>
                        { /*<li><a href={twitterLink} className="twitter-bg"><i className="fa fa-twitter"/></a></li>
                        <li><a href={googleLink} className="google-bg"><i className="fa fa-google-plus"/></a></li>*/}
                        <li><a href={instagramLink} className="linkedin-bg" target="_blank"><i
                            className="fa fa-instagram"/></a></li>
                    </ul>
                </div>
            </div>
        );
    }

    const UsefulLinks = (props) => {
        return (
            <div className="footer-item">
                <h4>
                    {t("usefulLinks")}
                </h4>
                <ul className="links">
                    <li>
                        <Link to="/">{t("home")}</Link>
                    </li>
                    <li>
                        <Link to="/about-us">{t("aboutUs")}</Link>
                    </li>
                    <li>
                        <Link to="/services">{t("servicesTerms")}</Link>
                    </li>
                    <li>
                        <Link to="/faq">{t("faq")}</Link>
                    </li>
                    <li>
                        <Link to="/contact-us">{t("contactUs")}</Link>
                    </li>
                    <li>
                        <Link to="/saved">{t("favoriteCars")}</Link>
                    </li>
                </ul>
            </div>
        );
    }

    const MobileAppsLinks = () => {
        return (
            <div className="recent-posts footer-item">
                <h4>{t("kurdsaleApp")}</h4>
                <div className="media mb-4">
                    <a className="pr-3" href="https://play.google.com/store/apps/details?id=com.kurdsale&hl=en_US">
                        <Image className="media-object w-50" src={GooglePlayImage} fluid={true}/>
                    </a>
                </div>
                <div className="media mb-4">
                    <a className="pr-3"
                       href="https://apps.apple.com/us/app/kurdsale-%D9%83%D9%88%D8%B1%D8%AF-%D8%B3%D9%8A%D9%84/id1007362393">
                        <Image className="media-object w-50" src={AppStoreImage} fluid={true}/>
                    </a>
                </div>
            </div>

        );
    }


    const handleSubmit = (evt) => {
        axios.post(API.sendEmail, evt).then(data => {
        }).catch(res => console.log);
        alert(`Submitting Name ${email}`)
    };

    return (
        <footer className="footer ">
            <div className="container footer-inner">
                <div className="row language-flex-direction">
                    <div className="col-xl-4 col-lg-2 col-md-6 col-sm-6">
                        <ContactInfo contactInfo={contactInfo}/>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 language-text-align language-text-dir">
                        <UsefulLinks/>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 language-text-align language-text-dir">
                        <MobileAppsLinks/>
                    </div>
                    {/* <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 language-text-align language-text-dir">
                        <div className="footer-item clearfix">
                            <h4>{t("subscribe")}</h4>
                            <div className="Subscribe-box">
                                <p>{t("subscribeText")}</p>
                                <form className="form-inline" onSubmit={handleSubmit}>
                                    <input type="text" className="form-control mb-sm-0" id="inlineFormInputName3"
                                           placeholder={t("emailAddress")}
                                           onChange={e => setEmail(e.target.value)}/>
                                    <button type="submit" className="btn"><i className="fa fa-paper-plane"/></button>
                                </form>
                                <GoogleAdsView 
        path='/21894097782/Kurdsale_1x1'
        size={[1, 1]} 
        id='div-gpt-ad-1617871782490-0' 
        style={{width:'1px',height:'1px'}}
        />
                            </div>
                        </div>
                    </div> */}
                    <div className="col-xl-12 col-lg-12">
                        <p className="copy sub-footer">© <a href="/">{year} KSELL HOLDING LIMITED</a> - All
                            Rights Reserved 
                            {/* | Powered By <a href="https://imagine-sy.com/" target="_blank">Imagine</a> */}
                            </p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

Footer.defaultProps = {
    subscribeText: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit.",
    contactInfo:
        {
            location: "60 St. - Near Alton mall - facing Taj supermarket Sulaymaniyah - Iraqi Kurdistan",
            email: "info@kurdsale.com",
            phone: "+964 75 13359921",
            mobile: "+964 77 11519509",
            facebookLink: "https://www.facebook.com/KurdSale/",
            twitterLink: "twitter.com",
            instagramLink: "https://www.instagram.com/ksell_iq",
            googleLink: "google.com",
        }
};

export default Footer;