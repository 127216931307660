import "./assets/scss/layout/router.scss";
import './App.css';
import "./i18n"
import React, { useEffect, useState } from 'react';
import {
    AboutUsPage,
    CarDetailsPage,
    CarGalleriesPage,
    CarSearchFiltersPage,
    carsSearchFilterPath,
    ComparePage,
    comparePath,
    ContactUsPage,
    Error404Page,
    FaqPage,
    GalleryPage,
    DependenciesCarsListingPage,
    DependencyCarsListingPage,
    DependenciesPage,
    HomePage,
    ServicesPage,
    SpecialNumbersPage,
    WatchlistPage,
    watchlistPath
} from "src/pages";


import {BrowserRouter as Router} from "react-router-dom";
import {AppRoute} from "./components";
import {MainLayout} from "./layouts";
import Switch from "react-bootstrap/Switch";
import axios from 'axios';
import "./App.scss"
import API from "src/classes/API";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import DownloadAppDialog from "./components/download-app-dialog/download-app-dialog-view";

const App = props => {
    const lang = localStorage.getItem('lang');
    localStorage.getItem("deviceToken") || axios.get(API.getToken).then(({data}) => data?.deviceToken && localStorage.setItem('deviceToken', data.deviceToken));

    document.body.classList.add(`language-${lang}`);

    const [isWebsiteOpen, setIsWebsiteOpen] = useState(false);

    useEffect(() => {
      setIsWebsiteOpen(true);
      return () => setIsWebsiteOpen(false);
    }, []);
  
    
    return (
        <Router>
                {isWebsiteOpen && ( <DownloadAppDialog />)}

            <Switch>
                
                <AppRoute path="/" exact layout={MainLayout} component={HomePage}/>
                <AppRoute path="/search/:slug" exact layout={MainLayout} component={HomePage}/>

                <AppRoute path="/404" exact layout={MainLayout} component={Error404Page}/>

                <AppRoute path="/car-details/:id/:name" exact layout={MainLayout} component={CarDetailsPage}/>
                {/* <AppRoute path="/private-cars" exact layout={MainLayout} component={PrivateCarsPage}/> */}
                <AppRoute path="/personal/:id/:name" exact layout={MainLayout} component={DependenciesCarsListingPage}/>
                <AppRoute path="/companies/:id/:name" exact layout={MainLayout} component={DependenciesPage}/>
                <AppRoute path="/companies/:depName/:id/:name" exact layout={MainLayout} component={DependencyCarsListingPage}/>

                <AppRoute path="/special-numbers" exact layout={MainLayout} component={SpecialNumbersPage}/>

                {/* <AppRoute path="/showrooms" exact layout={MainLayout} component={GalleryPage}/>
                <AppRoute path="/dealerships" exact layout={MainLayout} component={GalleryPage}/> */}
                <AppRoute path="/companies" exact layout={MainLayout} component={GalleryPage}/>

                <AppRoute path="/about-us" exact layout={MainLayout} component={AboutUsPage}/>
                <AppRoute path="/faq" exact layout={MainLayout} component={FaqPage}/>
                <AppRoute path="/contact-us" exact layout={MainLayout} component={ContactUsPage}/>

                <AppRoute path="/showrooms/:id/:name" exact layout={MainLayout} component={CarGalleriesPage}
                          withOverlay={true}/>
                <AppRoute path="/dealerships/:id/:name" exact layout={MainLayout} component={CarGalleriesPage}
                          withOverlay={true}/>
                {/* <AppRoute path="/companies/:id" exact layout={MainLayout} component={CarGalleriesPage}
                          withOverlay={true}/> */}

                <AppRoute path="/services" exact layout={MainLayout} component={ServicesPage}/>

                <AppRoute path={watchlistPath} exact layout={MainLayout} component={WatchlistPage}/>
                <AppRoute path={comparePath} exact layout={MainLayout} component={ComparePage}/>
                <AppRoute path={carsSearchFilterPath} exact layout={MainLayout} component={CarSearchFiltersPage}/>
                <AppRoute path="/car-search-filters/:slug" exact layout={MainLayout} component={CarSearchFiltersPage}/>

                {/* <AppRoute path="/:slug" exact layout={MainLayout} component={HomePage}/> */}

                {/* <Route path="/car-search-filters/:slug" render={(props) => <CarSearchFiltersPage {...props} />}/>   */}
                

                {/*<AppRoute path="/" layout={MainLayout} component={Error404Page}/>*/}
            </Switch>
        </Router>
    );
};


export default App;
