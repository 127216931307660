import React, {Fragment} from "react";
import {Shimmer} from "react-shimmer";
import {generateArray, generateToken} from "src/helper";

const ShimmerBox = ({number}) => (
    <div className="car-box-2" key={generateToken(32)}>
        <div className="row">
            <div className="col-lg-5 col-md-5 col-pad">
                <div className="car-thumbnail">
                    <span className="car-img">
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: 'auto',
                            width: '100%',
                            borderRadius: 8,
                            overflow: "hidden",
                            backgroundColor: "#f6f7f8"
                        }}>
                            <Shimmer width={360} height={360 * 9 / 16}/>
                        </div>
                    </span>
                </div>
            </div>
            <div className="col-lg-7 col-md-7 col-pad align-self-center">
                <div className="detail">
                    <Shimmer width={385} height={36} className="m-auto"/>
                    {number > 0 && <Fragment>
                        <div className="location"/>
                        <ul className="facilities-list clearfix">
                            {generateArray(number).map(i =>
                                <li key={generateToken(32)} className="mb-2">
                                    <Shimmer width={360} height={24} className="w-75 m-auto"/>
                                </li>
                            )}
                        </ul>
                    </Fragment>}
                </div>
            </div>
        </div>
    </div>
);

export default ShimmerBox;