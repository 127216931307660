import React, { Fragment } from "react";
import { Banner, SideBanner, SubBanner } from "../../components";
import { useTranslation } from "react-i18next";
import DocumentMeta from 'react-document-meta';
import { CarDetailsSection } from './components';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import { RightSidebar } from "src/pages/car-details/components";
import { RelatedCars } from "src/components";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import Image from "react-bootstrap/Image";
import GoogleAdsView from "src/components/google-ads/google-ads-view";
import { Container } from "react-bootstrap";

const CarDetailsView = ({ car: { id, Images, Name, Features, Description, Country, Phones, Price, Tag, City, Location, Agency, Year, Mileage, AdNumber, AdDate, Sold } }) => {
    const { t } = useTranslation();
    const { width } = useWindowDimensions();
    const AutoplaySlider = withAutoplay(AwesomeSlider);



    return (
        <Fragment>
            <DocumentMeta title={Name} description={Name} canonical={window.location.href} />
            <div className="image-slider" style={{ background: '#f0f0f0', height: '100% !important' }}>
                <Container fluid={true} className="mr-md-5 ml-md-5">

                    {Agency?.image.length == 0
                        ? <SubBanner title={t("home")} name={t("carDetails")} />
                        : <AutoplaySlider
                            play={true}
                            interval={6000}
                        >

                            {Agency?.image.map(image => <div className="w-100"> <Image className="w-100" fluid src={image} /> </div>)}
                        </AutoplaySlider>
                    }

                </Container>
            </div>
            
            <SideBanner />
                        <div className="car-details-page ]language-text-dir language-text-align">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12 col-xs-12">
                            <CarDetailsSection
                                id={id}
                                images={Images}
                                name={Name}
                                agency={Agency}
                                country={Country}
                                price={Price}
                                tag={Tag}
                                city={City}
                                description={Description}
                                location={Location}
                                features={Features}
                                sold={Sold}
                                phones={Phones}
                                mileage={Mileage}
                                adNumber={AdNumber}
                                adDate={AdDate}
                                Mileage={Mileage}
                                year={Year}

                            />
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <RightSidebar
                                id={id}
                                name={Name}
                                phones={Phones}
                                agency={Agency}
                                features={Features}
                                price={Price}
                                sold={Sold}
                                city={City}
                                year={Year}
                                mileage={Mileage}
                                adNumber={AdNumber}
                                adDate={AdDate}
                                Mileage={Mileage}

                            // social={social}
                            />
                        </div>
                    </div>
                    {/* <RelatedCars id={id}/> */}
                </div>
            </div>
        </Fragment>
    );
};

export default CarDetailsView;
