import React from "react";
import { ActionsButtons, ImageLoader, PopupGalleryButton } from "../../index";
import { OverlayTrigger, Tooltip, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import Axios from "axios";
import API from "src/classes/API";

const OffersBox = ({ isCar ,name, price, mainImage, id, url, loading, overLay }) => {
    const WrapWithLink = ({ children, url, active }) => !active ? <a href={url}> {children}  </a> : children;
    const pushAction = () => {
        Axios.post(API.action, { 'category': isCar ? 'ad' : 'dependency', 'actions': id, 'name': 'click', 'source': 'web' });
    }

    return (
        <WrapWithLink url={url} active={overLay}>
            <div className="cars language-text-dir language-text-align">
                <div className="car-box">
                    <div className="car-thumbnail">
                        <div className="car-thumbnail">
                            <a href={url} className="car-img">
                                {!!price && <div className="price-box">${price}</div>}
                                <ImageLoader src={mainImage} loading={loading} />
                            </a>
                        </div>
                        { <div className="carbox-overlap-wrapper" style={{ paddingTop: "9%" }}>
                            <div className="overlap-box">
                                <div className="overlap-btns-area">
                                    <ActionsButtons id={id} detailsUrl={url} wishlist />
                                </div>
                            </div>
                        </div>}

                    </div>
                    <div className="detail" onClick={pushAction}>
                        <OverlayTrigger
                            key={`car-${id}`}
                            placement={'bottom'}
                            overlay={
                                <Tooltip id={`tooltip-${'bottom'}`}>
                                    {name}
                                </Tooltip>
                            }
                        >
                            <h1 className="title text-overflow-ellipsis">
                                <a href={url}>{name}</a>
                            </h1>
                        </OverlayTrigger>
                    </div>
                </div>
            </div>
        </WrapWithLink>

    );
};
OffersBox.propTypes = {
    name: PropTypes.string,
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    tag: PropTypes.string,
    mainImage: PropTypes.string.isRequired,
    images: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    url: PropTypes.string,
};

export default OffersBox;