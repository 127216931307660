import React, { Fragment, useState, useEffect } from "react";
import { BoxShimmer, Pagination, SideBanner, SubBanner } from "../../components";
import SidebarFilters from "./filters";
import CarDetailsBox from "../../components/featured-cars/components/car-details-box/car-details-box";
import { generateArray, generateToken } from "src/helper";
import NoResultFound from "src/components/feedback/no-result-found";
import { SortAction } from "./components";
import SidebarCarBox from "./components/car-box"
import classes from "classnames";
import SidebarShimmerBox from "./components/car-box-shimmer"
import API from "src/classes/API";
import axios from 'axios';


const CarSearchFilters = (props) => {

    const { cars, onPageChange, onSortChange, page, totalPages, loading } = props;
    const [box, setBox] = useState(0);

    const [hasInitialData, setInitialData] = useState(false);


    useEffect(() => {


        const initialBrabdsData = localStorage.getItem('__initialBrands__');

        if (!initialBrabdsData) {

            try {

                axios.get(API.getInitialData)
                    .then(({ data }) => {
                        localStorage.setItem('__initialBrands__', JSON.stringify(data));
                        setInitialData(true);
                    });
            } catch (error) {
                // console.table('Failed to load initial Data');
            }

        } else {
            setInitialData(true);
        }

    }, []);


    const handleChange = (box) => {
        setBox(box);
    };

    if (!hasInitialData) {
        return <div className="page_loader" style={{ display: 'block' }} />
    }

    return (
        <Fragment>
            <SubBanner />
            <SideBanner />
            <div className="featured-car content-area language-text-dir language-text-align">
                <div className="container">
                    <div className="row language-justify-content">
                        <div className="col-lg-4 col-md-12">
                            <SidebarFilters />
                        </div>
                        <div className="col-lg-8 col-md-12">
                            {/*<!-- Option bar start -->*/}
                            <div className="option-bar clearfix">
                                <SortAction />
                                <div className="sorting-options float-right">
                                    <button className={classes("change-view-btn", { "active-view-btn": box === 1 })}
                                        onClick={() => handleChange(1)}>
                                        <i className="fa fa-th-list" />
                                    </button>
                                    <button className={classes("change-view-btn", { "active-view-btn": box === 0 })}
                                        onClick={() => handleChange(0)}>
                                        <i className="fa fa-th-large" />
                                    </button>
                                </div>

                            </div>
                            <div className="row">
                                {loading ?
                                    <ShimmerBox value={box} />
                                    : cars.length === 0 ?
                                        <div className="w-100 d-flex justify-content-center">
                                            <NoResultFound />
                                        </div>
                                        : <CarBox cars={cars} value={box} />
                                }
                            </div>
                            <Pagination onPageChange={onPageChange} page={page} totalPages={totalPages} />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

const CarBox = ({ value, cars }) => {
    return (
        <Fragment>
            {value ? cars.map((car) =>
                <SidebarCarBox key={generateToken(12)} car={car} />)
                : cars.map((car) =>
                    <div key={generateToken(12)}
                        className="col-lg-6 col-md-6 col-sm-12 filtr-item">
                        <div className="cars">
                            <div>
                                <CarDetailsBox car={car} />
                            </div>
                        </div>
                    </div>)
            }
        </Fragment>
    );
};

const ShimmerBox = ({ value }) => {
    return (
        <Fragment>
            {value ? generateArray(8).map(() =>
                <SidebarShimmerBox number={9} />
            )
                : generateArray(8).map(() =>
                    <div key={generateToken(12)} className="col-lg-6 col-md-6 col-sm-12 filtr-item">
                        <div className="cars">
                            <BoxShimmer number={9} />
                        </div>
                    </div>)

            }
        </Fragment>
    )
};

CarSearchFilters.defaultProps = {};
export default CarSearchFilters;