import React from "react";
import SortActionView from "./sort-action.view";
import {useDispatch} from "react-redux";

import {sortOptions} from './sort-action.config';
import {setSort} from "./redux/actions";

const SortActionRedux = () => {
    const dispatch = useDispatch();
    const handleSort = sort => dispatch(setSort(sort.field, sort.order));

    return (
        <SortActionView options={sortOptions} onSort={handleSort}/>
    )
};

export default SortActionRedux;