import React from "react";
import "./special.numbers.style.scss"
import Image from "react-bootstrap/Image";
import {numbersImages, regionImages} from "../../../special.numbers.config"

const SpecialBoxView = ({number: {agency: {city, id}, number}}) => {
    // console.table(city,"city");
    const image = city ? regionImages[city] : regionImages["Erbil"];
    const map = Array.prototype.map;
    const images = map.call(number, item => numbersImages[item]);
    const detailsUrl = `/agencies/${id}`;

    return (
        <div className="car-thumbnail">
            <div className="car-thumbnail special-container">
                <a href={detailsUrl} className="car-img">
                    <Image src={image} className="special-background"/>
                    <div className="special-numbers">
                        {images.map(img => <Image src={img} className="special-number"/>)}
                    </div>
                </a>
            </div>
        </div>
    );
};


export default SpecialBoxView;