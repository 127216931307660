import React from "react";
import ActionsButtonsContainer from "src/components/actions-buttons/actions-buttons.container";
import {useDispatch, useSelector} from "react-redux";
import {toggleCompareList, toggleWatchList} from "src/components/actions-buttons/redux/actions";
import {useHistory} from 'react-router-dom';
import Axios from "axios";
import API from "src/classes/API";

const ActionsButtonsRedux = ({id,detailsUrl, wishlist,navigate}) => {
    const dispatch = useDispatch();
    const watchList = useSelector(state => state.user.watchList);
    const compareList = useSelector(state => state.user.compareList);
    const history = useHistory();

    const handleCompareClick = () => {
        dispatch(toggleCompareList(id, history));
    }
    const handleWatchListClick = () => {
        Axios.post(API.action,{'category':'ad','actions':id,'name':'click_favorite','source':'web',});
        dispatch(toggleWatchList(id));
    }


    return (
        <ActionsButtonsContainer
        id={id}
        navigate={navigate}
        detailsUrl={detailsUrl}
            wishlist={wishlist}
            // inCompareList={compareList.some(_id => _id === id)}
            inWatchList={watchList.some(_id => _id === id)}
            onWatchlistClick={handleWatchListClick}/>
    );
};


export default ActionsButtonsRedux;