import './popup-gallery-button.style.scss';

import React, {useEffect} from "react";
import {generateToken} from "src/helper";

import $ from 'jquery';
import 'lightgallery';
import 'jquery';
import 'lg-zoom';
import 'lg-autoplay';
import 'lg-fullscreen';
import 'lg-thumbnail';
import 'lg-share';

import {Image} from "react-bootstrap";

const description = (title) => `<p>© <a href="/">2022 Deia company</a>-All Rights Reserved </p>`;
const PopupGalleryButtonView = ({images, title}) => {
    useEffect(() => {
        $('.car-magnify-gallery').lightGallery({
            fullScreen: true,
            autoplay: true,
            progressBar: true,
            autoplayControls: true,
            zoom: true,
            share: true,
            facebook: true,
            twitter: true,
            googlePlus: false,
            pinterest: false,
        });
    });

    return (
        <div className="car-magnify-gallery">
            {images?.map((item, index) =>
                <a key={generateToken(16)}
                   href={item.src}
                   className={`${index === 0 ? "overlap-btn" : "hidden"}`}
                   data-sub-html={description(title)}

                >

                    {index === 0 && <i className="fa fa-expand"/>}
                    <Image className="hidden" src={item.thumbnail} alt="hidden-img"/>
                </a>)}
        </div>
    );
}

export default PopupGalleryButtonView;
