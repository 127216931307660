import React, {useEffect, useState} from "react";
import FeaturedCarsContainer from "./featured-cars.container";
import {useDispatch, useSelector} from "react-redux";
import {updateFilters} from "src/components/filters";
import PropTypes from 'prop-types';
import {useHistory, useLocation} from "react-router-dom";
import queryString from "query-string";

const FeaturedCarsRedux = ({isWatchlist, title, homeScreen}) => {
    const filters = useSelector(state => state.filters);
    const sort = useSelector(state => state.sort);
    const watchList = useSelector(state => state.user.watchList);
    const dispatch = useDispatch();
    const history = useHistory();

    const {query: {page: _page = "1"}} = queryString.parseUrl(history.location.search);
    const [page, setPage] = useState(parseInt(_page));
    const handleFilterChange = (value) => {
        dispatch(updateFilters('region', value));
        setPage(1);
    };

    useEffect(() => {
        return history.listen((location, action) => {
            const {query: {page}} = queryString.parseUrl(location.search);
            setPage(parseInt(page));
        })
    });

    return (
        <FeaturedCarsContainer
            page={page}
            sort={sort}
            isHome = {homeScreen}
            title={title}
            filters={filters}
            onFilterChange={handleFilterChange}
            watchlist={isWatchlist ? watchList : null}
        />
    )
};

FeaturedCarsRedux.propTypes = {
    watchlist: PropTypes.bool.isRequired,
}

export default FeaturedCarsRedux;