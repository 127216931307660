import {CLEAR_QUEUE, ERASE_ITEM, INSERT_ITEM} from "./types";

const initState = {
    loadingQueue: ["LOADING_DOCUMENT"],
};

const Reducer = (state = initState, action) => {
    switch (action.type) {
        case INSERT_ITEM:
            return Object.assign({}, state, {
                loadingQueue: [...state.loadingQueue.filter(item => item !== action.item), action.item],
            });

        case ERASE_ITEM:
            return Object.assign({}, state, {
                loadingQueue: state.loadingQueue.filter(item => item !== action.item),
            });

        case CLEAR_QUEUE:
            return Object.assign({}, state, {
                loadingQueue: [],
            });

        default:
            return state;
    }
};

export default Reducer;