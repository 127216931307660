import React, { useState } from "react";
import PropTypes from 'prop-types';
import { BoxShimmer, FeedbackWrapper, SideBanner, SubBanner } from "../../components";
import { generateArray } from "src/helper";
import RegionFilter from "src/components/region-filter";
import InfiniteScroll from 'react-infinite-scroll-component';
import OffersBox from "src/components/latest-offers/component/offers-box-view";
import { useTranslation } from "react-i18next";
import GoogleAdsView from "src/components/google-ads/google-ads-view";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import API from "src/classes/API";
import Observer from "@researchgate/react-intersection-observer";
import Axios from "axios";
import classes from 'classnames';

const DependenciesView = ({ url, galleries, regions, selectedRegion, selectedLevel, hasMore, onLoadMore, onFilterChange, handleLevelChange, loading, title }) => {

    const levels = [
    {
        'name': 'Luxury',
        'value': 1,
    },
    {
        'name': 'Mid Range',
        'value': 2,
    },
    {
        'name': 'Economy',
        'value': 3,
    }];
    const { width } = useWindowDimensions();
    var [tempList, setTempList] = useState([]);
    var [allItemsList, setAllItemsList] = useState([]);
    const {t} = useTranslation();

    const onChangeImperession = (id) => {
        if (!allItemsList.includes(id)) {
            allItemsList.push(id);
            tempList.push(id);
            // console.debug(`dep templength ${tempList.length}`);
            if (tempList.length % 4 === 0) {
                //   console.debug(`dep its 4`);
                //   console.debug(tempList);
                Axios.post(API.pushImperession, { 'items': tempList.slice(-4), 'source': 'web', 'type': 'dependency' });
                tempList = [];
                //   console.debug(`dep clear list`);
                //   console.debug(tempList);
            }
        }
    }

    return (
        <div className="gallery">
            <SubBanner />
            {(width <= 750) ?
                <GoogleAdsView
                    path='/21894097782/Kurdsale_300x250(1)'
                    size={[300, 250]}
                    id='div-gpt-ad-1617871697027-0'
                    style={{ width: '300px', height: '250px', margin: '10px auto 10px auto' }}
                />
                :
                <GoogleAdsView
                    path='/21894097782/Kurdsale_728x90_970x90_970x250(1)'
                    size={[[970, 90], [970, 250], [728, 90]]}
                    id='div-gpt-ad-1617871600777-0'
                    style={{ textAlign: 'center', padding: '10px 0px 10px 0px' }}
                />
            }
            <SideBanner />
            {(width <= 750) ?
                <GoogleAdsView
                    path='/21894097782/Kurdsale_300x250(2)'
                    size={[300, 250]}
                    id='div-gpt-ad-1617871672756-0'
                    style={{ width: '300px', height: '250px', margin: '10px auto 10px auto' }}
                />
                :
                <GoogleAdsView
                    path='/21894097782/Kurdsale_728x90_970x90_970x250(2)'
                    size={[[728, 90], [970, 90], [970, 250]]}
                    id='div-gpt-ad-1617871549077-0'
                    style={{ textAlign: 'center', padding: '10px 0px 10px 0px' }}
                />
            }
            <div className="featured-car mt-5 language-text-dir">
                <div className="container">
                    <div className="main-title mt2">
                        <h1>{title}</h1>
                        <RegionFilter regions={regions} value={selectedRegion} onChange={onFilterChange} />
                        <div className="list-inline-listing">

                            <ul className="filters filteriz-navigation clearfix">
                                <li className={classes("btn filtr-button filtr", { active: selectedLevel === null })}
                                    data-filter="all"
                                    onClick={() => handleLevelChange(null)}>
                                    {t("all")}
                                </li>
                                {levels.map(({ name, value }, index) => title !== "all" &&
                                    <li key={index}
                                        className={classes("btn filtr-button filtr", { active: selectedLevel === value })}
                                        data-filter={value} onClick={() => handleLevelChange(value)}>
                                        {name}
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                    <FeedbackWrapper noResultFound={!loading && galleries.length === 0}>
                        <InfiniteScroll
                            className="row filter-portfolio"
                            dataLength={galleries.length}
                            next={onLoadMore}
                            hasMore={hasMore}
                            loader={
                                generateArray(12).map((i) =>
                                    <div key={`dummy-${i}`}
                                        className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="cars">
                                            <BoxShimmer number={0} />
                                        </div>
                                    </div>
                                )
                            }>
                            {galleries.map(({ name, image, id }, index) => {
                                return (
                                    <>
                                        {(index != 0 && index % 9 == 0) &&
                                            <div key={`agency-${id}`} className="col-lg-12 col-md-12 col-sm-12 filtr-item">
                                                {console.log(index + 1)}
                                                {(width <= 750) ?
                                                    <GoogleAdsView
                                                        path='/21894097782/Kurdsale_300x250(3)'
                                                        size={[300, 250]}
                                                        id='div-gpt-ad-1617871650849-0'
                                                        style={{ width: '300px', height: '250px', margin: '10px auto 10px auto' }}
                                                    />
                                                    :
                                                    <GoogleAdsView
                                                        path='/21894097782/Kurdsale_728x90_970x90_970x250(3)'
                                                        size={[[970, 90], [728, 90], [970, 250]]}
                                                        id='div-gpt-ad-1617871393551-0'
                                                        style={{ textAlign: 'center', padding: '10px 0px 10px 0px' }}
                                                    />
                                                }
                                            </div>
                                        }
                                        <div key={`agency-${id}`} className="col-lg-4 col-md-6 col-sm-12 filtr-item">
                                            <Observer onChange={onChangeImperession(id)}>
                                                <div className="cars">
                                                    <OffersBox
                                                        id={id}
                                                        name={name}
                                                        //  images={images}
                                                        //  mainImage={image}
                                                        mainImage={image}
                                                        loading={loading}
                                                        url={`${url}/${id}/${name.replace(/ /g, '-').toLowerCase()}`}
                                                    />
                                                </div>
                                            </Observer>
                                        </div>
                                    </>
                                )
                            }
                            )}
                        </InfiniteScroll>
                    </FeedbackWrapper>
                </div>
            </div>
        </div>
    );
};

DependenciesView.propTypes = {
    hasMore: PropTypes.bool.isRequired,
    galleries: PropTypes.array.isRequired,
    regions: PropTypes.array.isRequired,
    selectedRegion: PropTypes.string,
    onLoadMore: PropTypes.func,
    onFilterChange: PropTypes.func,
    loading: PropTypes.bool,
    path: PropTypes.string,
};

export default DependenciesView;