import './car-details-section.style.scss';

import classes from "classnames";
import { ActionsButtons, GoogleMap, PopupGalleryButton } from "src/components";
import React, { Fragment, useState } from "react";
import PropTypes from 'prop-types';
import { generateToken } from "src/helper";
import { Button, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CallDialogButton from "src/components/call-dialog-button";
import GoogleAdsView from '../google-ads/google-ads-view';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import PhoneDialog from "src/components/call-dialog-button";
import RatingDialog from '../rating-dialog/rating-dialog';

const CarDetailsSectionView = ({ id, sold, images, name, mileage, year, phones, adDate, adNumber,agency, country, tag, price, city, features, description, location, }) => {
    const { t } = useTranslation();
    const url = window.location.href;
    const isComparePage = url.indexOf("compare") !== -1;
    const { width } = useWindowDimensions();
    const Price = (sold == 1 && price == null) ? t('sold') : price != '' && price != null ? `${price} $` : t('callUs');

    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const [showRatingDialog, setShowRatingDialog] = useState(false);

    const handleShowRatingDialog = () => {
        setShowRatingDialog(true);
    };

    const handleCloseRatingDialog = () => {
        setShowRatingDialog(false);
    };
    var general = [

        {
            'title': t('mileage'),
            'value': mileage,
        },

        {
            'title': t('price'),
            'value': Price,
        },

        {
            'title': t('city'),
            'value': city,
        },
        {
            'title': t('year'),
            'value': year,
        },
        {
            'title': t('adNumber'),
            'value': adNumber,
        },
        {
            'title': t('adDate'),
            'value': adDate,
        },
    ];
    return (
        <div className="car-details-section">
            <div id={`car-details-slider-${id}`} className="carousel car-details-sliders slide slide-2 lang-en">
                <div className="carousel-inner car-thumbnail">
                    <div className="carbox-overlap-wrapper h-auto" style={{ margin: "150px 0", paddingTop: "5%"  }}>
                        <div className="overlap-box">
                            <div className="overlap-btns-area">
                                <ActionsButtons id={id} wishlist navigate={false} />
                                <PopupGalleryButton title={name} images={images} />
                            </div>
                        </div>
                    </div>

                    {images?.map(({ src, thumbnail }, index) =>
                        <div key={index}
                            className={classes("item carousel-item", { "active": index === 0 })}
                            data-slide-number={index}>
                            <Image fluid src={src} />
                        </div>)}
                </div>

                {/* main slider carousel nav controls */}
                <ul className="carousel-indicators car-properties list-inline nav nav-justified">
                    {images?.map(({ thumbnail }, index) =>
                        <li key={index}
                            className={classes("list-inline-item", { "active": index === 0 })}>
                            <a id={classes(`carousel-selector-${index}`)}
                                className={classes({ "selected": index === 0 })} data-slide-to={index}
                                data-target={`#car-details-slider-${id}`}>
                                <img src={thumbnail} className="img-fluid" />
                            </a>
                        </li>
                    )}
                </ul>


                <div className="heading-car-2 language-text-align">

                    <OverlayTrigger key={generateToken(32)} placement={'bottom'}
                        overlay={
                            <Tooltip id={`tooltip-${'bottom'}`}>
                                {name}
                            </Tooltip>
                        }
                    >
                        
                        {/* eslint-disable-next-line react/style-prop-object */}
                        <h3 className="text-overflow-ellipsis language-text-dir">{name}</h3>
                        

                    </OverlayTrigger>
                    <div className="price-location">
                   
                        <span className="location"> <i className="flaticon-pin" />{country} - {city}</span>
                        {price && <span className="car-price ml-2">{price && `$${price}`}</span>}
                        {sold == 1 && <div style={{ borderRadius: '5px', backgroundColor: 'red', color: 'white',textAlign:'center',padding:'5px', width:'60px' }}> {t('sold')} </div>}
                    </div>
                </div>
            </div>
            
            {features?.map(({ title, items }, idx) =>
                <div key={idx}
                    className="widget-2 advanced-search bg-grea-2 d-lg-none d-xl-none language-text-align language-text-dir">
                    <h3 className="sidebar-title">{t(`${title}`)}</h3>
                    <ul>
                        {items?.filter(({ type, value }) => isComparePage || (!type && value))?.map(({ title, value, type, renderer, ...rest }, index) =>
                            <li key={index}>
                                <div className="justify-content-start d-flex language-text-align">
                                    <Fragment>
                                        <span
                                            className="ml-1 mr-1 text-nowrap"> {title}: </span> {value ? value : t("na")}
                                    </Fragment>

                                </div>
                            </li>
                        )}
                        {title === t('general') ? <>
                        <li key={0} className="d-flex justify-content-between align-items-center">
                                    <div className="justify-content-start d-flex language-text-align">
                                        <Fragment>
                                            <span className="ml-1 mr-1 text-nowrap">{t('gallery')}: </span>
                                            {agency.name}
                                        </Fragment>
                                    </div>
                                    <button
                                    style={{ background: "#FFD700", color: "#000000" }}><div onClick={handleShowRatingDialog}>{t('rate')} <i className="fa fa-star-o"></i></div></button>
                                    {showRatingDialog && (
                                        <RatingDialog id={id} onClose={handleCloseRatingDialog} />
                                    )}

                                </li>
                            {general?.map(({ title, value }, index) =>
                                <li key={index}>
                                    <div className="justify-content-start d-flex language-text-align">
                                        <Fragment>
                                            <span
                                                className="ml-1 mr-1 text-nowrap"> {title}: </span> {value ? value : t("na")}
                                        </Fragment>
                                    </div>
                                </li>
                            )}</> : <></>}
                        {title === t('general') ? <div className='call-dialog-button '>
                            <Button
                                onClick={handleClick}
                                style={{ background: "#f0151f", color: "#ffffff", marginTop: "20px", marginLeft: "5px" }}
                            >
                                {" "}
                                {t('callNow')}{" "}
                            </Button>
                            <PhoneDialog id={id} open={open} onClose={handleClose} phones={phones} title={t('callNow')} />
                        </div> : <></>}
                        {(title) ?
                            <>
                                {items?.filter(({ type, value }) => isComparePage || (type != 'mobile'))?.map(({ title, value, type, renderer, ...rest }, index) =>
                                    (type === 'call-button') &&
                                    <>
                                        <CallDialogButton
                                            id={id} number={rest.mobile} numberExtra={rest.mobileExtra}
                                            title={t("callNow")} />
                                        <>
                                            {(width <= 750) ?
                                                <GoogleAdsView
                                                    path='/21894097782/Kurdsale_300x250_Sidebar'
                                                    size={[300, 250]}
                                                    id='div-gpt-ad-1617871722756-0'
                                                    style={{ width: '300px', height: '250px', margin: '10px auto 10px auto' }}
                                                /> : ''
                                            }
                                        </>
                                    </>
                                )}
                            </>
                            : ''
                        }
                    </ul>
                </div>)}

            {!!description && <div className="Description mb-50">
                <h3 className="heading-2">{t("description")}</h3>
                <p>{description}</p>
            </div>}

            {/* location start */}
            {!!location &&
                <div className="location mb-50 language-text-align language-text-dir">
                    <div className="map">
                        <h3 className="heading-2 ">{t("location")}</h3>
                        <div id="map" className="contact-map position-relative">
                            <GoogleMap location={location} name={name} size="600x350" />
                        </div>
                    </div>
                </div>}
        </div>
    );
};

CarDetailsSectionView.propTypes = {
    id: PropTypes.string.isRequired,
    images: PropTypes.array.isRequired,
    features: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    price: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
    tag: PropTypes.string,
    city: PropTypes.string,
    description: PropTypes.string,
};

export default CarDetailsSectionView;
