import React from "react";
import useAxios from "axios-hooks";
import API from "src/classes/API";
import {ErrorFeedback} from "src/components";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import AwesomeSlider from "react-awesome-slider";
import Image from "react-bootstrap/Image";
import {Shimmer} from "react-shimmer";
import Axios from "axios";

const SubBanner = () => {
    const AutoplaySlider = withAutoplay(AwesomeSlider);
    // const pushAction = (id)=>{
    //     Axios.post(API.action,{'category':'dependency', 'actions':id, 'name':'banner_click','source':'web',});
    // }
    const [{data, loading, error}] = useAxios({
        url: API.mainBanner,
        method: "GET"
    })
    // const image = data.banner.album[0].image;
    // const link = data.banner.album[0].link;
    if (error)
        return (<ErrorFeedback/>);

    if (loading)
        return <Shimmer width={1800} height={360}/>;
    return (
        <div className="image-slider" style={{background: 'black', height: '360px'}} >
            <AutoplaySlider
                play={true}
                interval={6000}
            >
                {data?.banner.album.map(({image, link, dependency_id}) =>
                    <div className="w-100" onClick={()=>{
                        Axios.post(API.action,{'category':'dependency', 'actions':dependency_id, 'name':'banner_click','source':'web',});
                        // console.debug(`click banner ${dependency_id}`);

                    }}>
                        <a href={link} target="_blank"> <Image className="w-100" fluid src={image}/></a>
                    </div>
                )}
            </AutoplaySlider>
        </div>
    );

};

export default SubBanner;