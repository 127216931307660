import {ErrorFeedback, LoadingFeedback, NoResultFoundFeedback} from "src/components/feedback";
import React, {Fragment} from "react";

const FeedbackContainer = ({children}) => <div className="w-100 d-flex justify-content-center"> {children} </div>;
const FeedbackWrapperView = ({loading, error, noResultFound, withContainer, children, container: CustomContainer}) => {
    const Container = CustomContainer ?? withContainer ? FeedbackContainer : Fragment;

    if (!!loading) return <Container> <LoadingFeedback/> </Container>
    if (!!error) return <Container> <ErrorFeedback error={error}/></Container>
    if (noResultFound) return <Container><NoResultFoundFeedback/></Container>

    return children;
};

export default FeedbackWrapperView;