import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import useAxios from "axios-hooks";
import { generateToken } from "src/helper";
// import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { BadgeIcon, DollarIcon, LocationIcon, CalendarIcon, RoadIcon, SettingIcon } from './home_cars_icons';
import API from "src/classes/API";
import Axios from "axios";

const CarDetailsView = ({ car: { id,name, Price, Year, Mileage, City, AdNumber, Card,Sold }, detailsUrl }) => {
    const [{ data: _data }] = useAxios({
        url: API.cardDefinitionsIcons,
        method: "GET",
    });
    
    const { t } = useTranslation();
    const cardIcons = _data?.slice(0,4).map((e)=>({icon:e.icon,title:e.title}));
    const cards = [];
    const cardItems = Card.slice(0, 4).map((element) => ({ title:element.title,value: element.value ?? '', icon: element.icon ?? SettingIcon }));
    const price = (Sold == 1 && Price == null) ? t('sold') : Price != '' && Price != null ? `${Price} $` : t('callUs');

    for (let i = 0; i < cardIcons?.length; i++) {
        const value = cardItems.filter(item => item.title === cardIcons[i].title).map(item => item.value);
        const newValue = value;
        cards.push({icon:cardIcons[i].icon ?? SettingIcon,value:value,});
    };

    const features = [
        { value: price, icon: DollarIcon },
        { value: Year, icon: CalendarIcon },
        { value: Mileage, icon: RoadIcon },
        { value: City, icon: LocationIcon },
        { value: AdNumber, icon: BadgeIcon },
        ...cards,
    ];
    while (features.length < 9) {
        features.push({ value: '', icon: SettingIcon });
    }
    const push = ()=>{
        Axios.post(API.action,{'category':'ad','actions':id,'name':'click','source':'web',});
    }

    return (
        <div className="detail language-text-dir language-text-align" onClick={push}>
            
            <OverlayTrigger
                key={generateToken(32)}
                placement={'bottom'}
                overlay={
                    <Tooltip id={`tooltip-${'bottom'}`}>
                        {name}
                    </Tooltip>
                }
            >
                <h1 className="title text-overflow-ellipsis">
                    <Link onClick={push} className="language-text-align" to={detailsUrl}>{name}</Link>
                </h1>
            </OverlayTrigger>

            <div className="location" />
            {/* <div className="location" /> */}
            <ul className="facilities-list clearfix ">

                {features?.map((item, index) => {
                    return (
                        item.value === null ? <></> : <OverlayTrigger
                            key={generateToken(32)}
                            placement={'bottom'}
                            overlay={
                                <Tooltip id={`tooltip-${'bottom'}`}>
                                    {item.value}
                                </Tooltip>
                            }
                        >
                        <li key={index} className="text-overflow-ellipsis">
                            <span className={`icon m-1`} style={{ display: "inline-block" }} /><img  style={{width:'14px', heigt:'14px',margin:'5px'}} src={item.icon} alt=''/><span
                                className="">{item.value ?? t("na")}</span>
                            </li>
                        </OverlayTrigger>
                    )
                })}

            </ul>
        </div>
    );
};

CarDetailsView.defaultProps = {};

export default CarDetailsView;

