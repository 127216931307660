import './filter-select.scss';
import React from "react";
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputBase from "@material-ui/core/InputBase";

const FilterSelect = ({ name, title, options, value, onChange, defaultValue }) => {
    const { t } = useTranslation();


    const handleChange = (event) => {
        if (typeof onChange == 'function')
            onChange(event.target.value);
    }

    return (
        <Select
            className="filter-select search-fields language-text-dir"
            name={t({ name })}
            onChange={handleChange}
            value={value}
            defaultValue={defaultValue ? defaultValue : ''}
            displayEmpty
            MenuProps={{
                disableScrollLock: true,
            }}
        >
            <MenuItem className='language-text-dir' selected value={null}> {t(`${title}`)}</MenuItem>
            {options?.map((item, index) => <MenuItem className='language-text-dir' key={index} value={item.value}>{item.title}</MenuItem>)}
        </Select>
    );
};

FilterSelect.propType = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    option: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
    })).isRequired,
    max: PropTypes.number.isRequired,
    onChange: PropTypes.func,
};

FilterSelect.defaultProps = {
    max: 50,
};

export default FilterSelect;