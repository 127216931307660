import React, { Component } from "react";
import { Image } from "react-bootstrap";
import { watchlistPath } from "src/pages";
import classes from "classnames"
import "../main-layout.style.css"

import useAxios from "axios-hooks";
import { useTranslation } from "react-i18next";
import { LanguageSwitcher } from "src/components"
import { Link } from "react-router-dom";
import API from "src/classes/API";

const Header = ({ withOverlay }) => {
    const { t } = useTranslation();

    const [{ data, loading, error }] = useAxios({
        url: API.menuDependencies,
        method: "GET"
    });

    let apiData;
    if (data) {
        apiData = data?.dependencies.map(dependency => ({
            type: "SingleItem",
            prop: {
                id: "navbarDropdownMenuLink",
                mainTitle: dependency.name,
                dependencyId: dependency.id,
                href: dependency.cars_listing === 1 ?
                `/personal/${dependency.id}/${dependency.name.replace(/ /g, '-').toLowerCase()}` :
                `/companies/${dependency.id}/${dependency.name.replace(/ /g, '-').toLowerCase()}`
            }
        }));
    } else {
        apiData = [];
    }
    if (loading) {
        return <p>loading...</p>
    } else if (error) {
        return <p>error.</p>
    } else {

        const values = [
            {
                type: "SingleItem",
                prop: {
                    id: "navbarDropdownMenuLink",
                    mainTitle: t("home"),
                    href: "/"
                }
            },
            ...apiData,
            {
                type: "SingleItem",
                prop: {
                    id: "navbarDropdownMenuLink",
                    mainTitle: t("favoriteCars"),
                    href: watchlistPath
                }
            }
        ];

        return (
            <header
                className={classes("main-header sticky-header language-text-align language-text-dir header-shrink")}>
                <div className="container main-header--container">
                    <nav className="navbar navbar-expand-lg navbar-light">
                    <Link className="navbar-brand company-logo" to="/">
                            <Image src="/img/logos/logo.png" alt="logo" fluid={true} />
                        </Link>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar"
                            aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="fa fa-bars" />
                        </button>
                        <div className="navbar-collapse collapse w-100 m-auto" id="navbar">
                            <ul className="navbar-nav m-auto">
                                {values.map((object, index) => {
                                    switch (object.type) {
                                        case "SingleItem":
                                            return <SingleItem key={index} data={object.prop} active={index} />;
                                        case "ContactItem":
                                            return <ContactItem key={index} data={object.prop} />;
                                        case "ButtonItem":
                                            return <ButtonItem key={index} data={object.prop} />;
                                        default:
                                            return "";
                                    }
                                })}
                            </ul>
                        </div>
                        
                        <LanguageSwitcher />

                    </nav>
                </div>
            </header>
        );
    }
};


class SingleItem extends Component {
    render = () => {
        const { data, active } = this.props;
        const setDependencyId = () => sessionStorage.setItem('dependenciesId', data.dependencyId);
        return (
            <li className={`nav-item dropdown ${active === 0 ? "active" : ""}`}>
                <a className="nav-link" onClick={setDependencyId} href={data.href} id={data.id}
                    aria-haspopup="true" aria-expanded="false">{data.mainTitle}
                </a>
            </li>

        );
    };
}

class MultipleItem extends Component {
    render = () => {
        const { data } = this.props;
        return (
            <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" id={data.id}
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{data.mainTitle}
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    {data.list.map((item, index) =>
                        <li key={index} className="dropdown-submenu">
                            <a className="dropdown-item dropdown-toggle" href="#">{item.title}</a>
                            <ul className="dropdown-menu">
                                {item.value.map((element, idx) =>
                                    <li key={idx}>
                                        <a className="dropdown-item"
                                            href={element.href}>{element.value}</a>
                                    </li>)}
                            </ul>
                        </li>)}
                </ul>
            </li>
        );
    }
}

class MultiListItem extends Component {
    render = () => {
        const { data } = this.props;
        return (
            <li className="nav-item dropdown megamenu-li">
                <a className="nav-link dropdown-toggle" href="" id={data.id}
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{data.mainTitle}</a>
                <div className="dropdown-menu megamenu" aria-labelledby="dropdown01">
                    <div className="megamenu-area">
                        <div className="row">
                            {data.list.map((item, index) =>
                                <div key={index} className="col-sm-6 col-md-4 col-lg-4">
                                    <div className="megamenu-section">
                                        <h6 className="megamenu-title">{item.title}</h6>
                                        {item.value.map((element, idx) =>
                                            <a key={idx} className="dropdown-item"
                                                href={element.href}>{element.value}</a>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </li>
        );
    };
}

class ContactItem extends Component {
    render = () => {
        const { data } = this.props;
        return (
            <li className="nav-item dropdown">
                <Link className="nav-link" to={data.href}>{data.value}</Link>
            </li>
        );
    };
}

class ButtonItem extends Component {
    render = () => {
        const { data } = this.props;
        return (
            <li className="nav-item dropdown">
                <Link to={data.href} className="nav-link h-icon">
                    <i className={data.className} />
                </Link>
            </li>
        );
    };
}

export default Header;
export { SingleItem, MultiListItem, MultipleItem, ContactItem, ButtonItem };