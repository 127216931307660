import './right-sidebar.style.scss';

import React, { Fragment, useState } from "react";
import { Collapse, Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import classes from 'classnames';
import { useTranslation } from "react-i18next";
import { FacebookShareButton, TelegramShareButton, WhatsappShareButton } from "react-share";
import PhoneDialog from "src/components/call-dialog-button";
import useWindowDimensions from 'src/hooks/useWindowDimensions';
// import { Button } from '@material-ui/core';
import Axios from "axios";
import API from 'src/classes/API';
import RatingDialog from 'src/components/rating-dialog/rating-dialog';

const RightSidebarView = ({ id, name, price, phones, isShow, toggleShow, city, features, adNumber, adDate, agency, mileage, year, sold }) => {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();
    const url = window.location.href;
    const Price = (sold == 1 && price == null) ? t('sold') : price != '' && price != null ? `${price} $` : t('callUs');
    const isComparePage = url.indexOf("compare") !== -1;
    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    var general = [

        {
            'title': t('mileage'),
            'value': mileage,
        },

        {
            'title': t('price'),
            'value': Price,
        },

        {
            'title': t('city'),
            'value': city,
        },
        {
            'title': t('year'),
            'value': year,
        },
        {
            'title': t('adNumber'),
            'value': adNumber,
        },
        {
            'title': t('adDate'),
            'value': adDate,
        },
    ];
    const pushAction = () => {
        Axios.post(API.action, { 'category': 'ad', 'actions': id, 'name': 'share', 'source': 'web', });
    }
    const [showRatingDialog, setShowRatingDialog] = useState(false);

    const handleShowRatingDialog = () => {
        setShowRatingDialog(true);
    };

    const handleCloseRatingDialog = () => {
        setShowRatingDialog(false);
    };
    return (
        <div className="sidebar-right language-text-align language-text-dir">
            {/* Advanced search start */}
            {features?.map(({ title, items }, idx) =>
                <div key={idx} className="widget advanced-search d-none d-xl-block d-lg-block">
                    <h3 className={classes("sidebar-title", { collapsed: !isShow(idx) })} onClick={() => toggleShow(idx)}>
                        <FontAwesomeIcon className="sidebar-title--icon m-language-2"
                            icon={isShow(idx) ? faMinusCircle : faPlusCircle} />
                        {title}
                    </h3>

                    <Collapse in={isShow(idx)}>
                        <ul>
                            {items?.filter(({ type, value }) => isComparePage || (!type && value))?.map(({ title, value, type, renderer, ...rest }, index) =>
                                <>
                                    <li key={index}>
                                        <div className="justify-content-start d-flex language-text-align">
                                            <Fragment>
                                                <span
                                                    className="ml-1 mr-1 text-nowrap"> {title}: </span> {value ? value : t("na")}
                                            </Fragment>
                                        </div>
                                    </li>
                                </>
                            )}
                            {title === t('general') ? <>
                                <li key={0} className="d-flex justify-content-between align-items-center">
                                    <div className="justify-content-start d-flex language-text-align">
                                        <Fragment>
                                            <span className="ml-1 mr-1 text-nowrap">{t('gallery')}: </span>
                                            {agency.name}
                                        </Fragment>
                                    </div>
                                    <button
                                    style={{ background: "#FFD700", color: "#000000" }}><div onClick={handleShowRatingDialog}>{t('rate')} <i className="fa fa-star-o"></i></div></button>
                                    {showRatingDialog && (
                                        <RatingDialog id={id} onClose={handleCloseRatingDialog} />
                                    )}

                                </li>
                                {general?.map(({ title, value }, index) =>
                                    <li key={index}>
                                        <div className="justify-content-start d-flex language-text-align">
                                            <Fragment>
                                                <span
                                                    className="ml-1 mr-1 text-nowrap"> {title}: </span> {value ? value : t("na")}
                                            </Fragment>
                                        </div>
                                    </li>
                                )}</> : <></>}
                        </ul>

                    </Collapse>
                    {title === t('general') ? sold == 1 | phones.length == 0 ? <></> : <div className='call-dialog-button '>
                        <Button
                            onClick={handleClick}
                            style={{ background: "#f0151f", color: "#ffffff", marginTop: "20px", marginLeft: "5px" }}
                        >
                            {" "}
                            {t('callNow')}{" "}
                        </Button>
                        <PhoneDialog id={id} open={open} onClose={handleClose} phones={phones} title={t('callNow')} />
                    </div> : <></>}
                </div>)}
            {/* Social sinks start */}

            <div className="widget social-links language-text-align language-text-dir">
                <p> {t('informationWarning')}</p>
                <h3 className="sidebar-title">{t("share")}</h3>
                <ul className="social-list clearfix">
                    <li>
                        <FacebookShareButton url={url} quote={name} hashtag="#kurdsale">
                            <a className="facebook-bg" title="Facebook" onClick={pushAction}>
                                <i className="fa fa-facebook" style={{ color: 'white' }} />
                            </a>
                        </FacebookShareButton>
                    </li>
                    <li>
                        <WhatsappShareButton title={name} separator=" " url={url}>
                            <a className="whatsapp-bg" title="Whatsapp" onClick={pushAction}>
                                <i className="fa fa-whatsapp" style={{ color: 'white' }} />
                            </a>
                        </WhatsappShareButton>
                    </li>
                    <li>
                        <TelegramShareButton title={name} url={url}>
                            <a className="telegram-bg" title="Telegram" onClick={pushAction}>
                                <i className="fa fa-telegram" style={{ color: 'white' }} />
                            </a>
                        </TelegramShareButton>
                    </li>
                </ul>


            </div>
        </div>
    );
};

export default RightSidebarView;


