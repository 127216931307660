import React, { Fragment } from "react";
import { ImageLoader } from "../../../../index";
import { Link } from "react-router-dom";
import { ActionsButtons, PopupGalleryButton } from "src/components";
import 'lightgallery';
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Axios from "axios";
import API from "src/classes/API";
import { toggleWatchList } from "src/components/actions-buttons/redux/actions";
import { useDispatch, useSelector } from "react-redux";
import classes from 'classnames';

const CarBoxView = ({ car, detailsUrl }) => {
    const dispatch = useDispatch();
    const watchList = useSelector(state => state.user.watchList);
    const { t } = useTranslation();
    const handleWatchListClick = () => {
        Axios.post(API.action, { 'category': 'ad', 'actions': car.id, 'name': 'click_favorite', 'source': 'web', });
        dispatch(toggleWatchList(car.id));
    }
    const push = ()=>{
        Axios.post(API.action,{'category':'ad','actions':car.id,'name':'click','source':'web',});
    }
    const cardStyle = {
        position: 'relative',
        width: '100%',
        height: '100%'
    };

    const overlapBtnStyle = {
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: 1
    };

    const tagStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1,
        backgroundColor: '#ff0000',
        color: '#ffffff',
        padding: '5px'
    };
    return (
        <div className="car-thumbnail" style={cardStyle}>
        
            <a href={`/car-details/${car.id}/${car.name.replace(/ /g, '-').toLowerCase()}`} onClick={push}>

                <div className="car-thumbnail">
                    <Link to={detailsUrl}>
                        {car.Sold == 1 ? <div className="tag" >{t('sold')}</div>
                            : <Fragment />}
                        <ImageLoader src={car.image} />
                    </Link>
                </div>
            </a>
            <div className="carbox-overlap-wrapper">
                {/* <div className="overlap-box"> */}
                {/* <div className="overlap-btns-area"> */}
                <ActionsButtons id={car.id} detailsUrl={detailsUrl} wishlist />
                {/* <PopupGalleryButton title={car.name} images={car.images}/> */}
                {/* </div> */}
                {/* </div> */}
            </div>
        </div>
    )
};

export default CarBoxView;