import SpecialNumberView from "./special.numbers.view";
import React, {useState} from "react";
import useAxios from "axios-hooks";
import API from "../../classes/API";

const SpecialNumbersContainer = props => {
    const [page, setPage] = useState(1);
    const pageSize = 12;

    const handlePageChange = (page) => {
        setPage(page);
    };

    const [{data: _data, loading, error}] = useAxios({
        url: API.specialNumbers,
        method: 'PUT',
        data: {
            skip: (page - 1) * pageSize,
            take: pageSize,
        },
    });

    const data = _data ?? {
        numbers: [],
        total: 0,
        totalFiltered: 0
    };
// console.log(data,"numbers");
    const {numbers, totalFiltered} = data;
    const totalPages = Math.ceil(totalFiltered / pageSize);

    return (
        <SpecialNumberView
            loading={loading}
            numbers={numbers}
            totalPages={totalPages}
            onPageChange={handlePageChange}
        />
    );
};

export default SpecialNumbersContainer;