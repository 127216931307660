import React, {useState} from "react";
import RightSidebarView from "./right-sidebar.view";


const RightSidebarContainer = ({show, ...rest}) => {
    const [showArray, setShowArray] = useState(show);
    const isShow = (id) => showArray.some(_id => id === _id);
    const toggleShow = (id) => isShow(id) ? setShowArray([...showArray.filter(_id => _id !== id)]) : setShowArray([...showArray, id]);

    return (
        <RightSidebarView isShow={isShow} toggleShow={toggleShow} {...rest}/>
    );
};

RightSidebarContainer.defaultProps = {
    show: [0, 1],
};

export default RightSidebarContainer;