import "../../components/featured-cars/featured-cars.style.scss";
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { SubBanner, SideBanner } from "../../components";
import FeaturedCarsView from "src/components/featured-cars/featured-cars.view";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import GoogleAdsView from "src/components/google-ads/google-ads-view";

const DependenciesCarsView = ({
  page,
  cars,
  regions,
  title,
  selectedRegion,
  totalPages,
  onPageChange,
  onFilterChange,
  loading,
}) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  // console.log('regions',regions);

  return (
    <Fragment>
      <SubBanner title={t("home")} name={t("privateCars")} />
      {width <= 750 ? (
        <GoogleAdsView
          path="/21894097782/Kurdsale_300x250(1)"
          size={[300, 250]}
          id="div-gpt-ad-1617871697027-0"
          style={{
            width: "300px",
            height: "250px",
            padding: "10px 0px 10px 0px",
            margin: "10px auto 10px auto",
          }}
        />
      ) : (
        <GoogleAdsView
          path="/21894097782/Kurdsale_728x90_970x90_970x250(1)"
          size={[
            [970, 90],
            [970, 250],
            [728, 90],
          ]}
          id="div-gpt-ad-1617871600777-0"
          style={{ textAlign: "center", padding: "10px 0px 10px 0px" }}
        />
      )}
      <SideBanner />
      {width <= 750 ? (
        <GoogleAdsView
          path="/21894097782/Kurdsale_300x250(2)"
          size={[300, 250]}
          id="div-gpt-ad-1617871672756-0"
          style={{
            width: "300px",
            height: "250px",
            padding: "10px 0px 10px 0px",
            margin: "10px auto 10px auto",
          }}
        />
      ) : (
        <GoogleAdsView
          path="/21894097782/Kurdsale_728x90_970x90_970x250(2)"
          size={[
            [728, 90],
            [970, 90],
            [970, 250],
          ]}
          id="div-gpt-ad-1617871549077-0"
          style={{ textAlign: "center", padding: "10px 0px 10px 0px" }}
        />
      )}
      <FeaturedCarsView
        page={page}
        cars={cars}
        loading={loading}
        onFilterChange={onFilterChange}
        regions={regions}
        selectedRegion={selectedRegion}
        totalPages={totalPages}
        onPageChange={onPageChange}
        title={title}
      />
    </Fragment>
  );
};

DependenciesCarsView.propTypes = {
  totalPages: PropTypes.number.isRequired,
  cars: PropTypes.array.isRequired,
};

DependenciesCarsView.defaultProps = {
  totalPages: 10,
};

export default DependenciesCarsView;
