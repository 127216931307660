import React, {Fragment} from "react";
import {Shimmer} from "react-shimmer";
import {generateArray, generateToken} from "src/helper";

const CarDetailsBoxShimmerView = ({number}) => (
    <div className="car-box" key={generateToken(32)}>
        <div className="car-thumbnail">
            <a className="car-img">
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: 'auto',
                    width: '100%',
                    borderRadius: 8,
                    overflow: "hidden",
                    backgroundColor: "#f6f7f8"
                }}>
                    <Shimmer width={360} height={360 * 9 / 16}/>
                </div>
            </a>
        </div>
        <div className="detail">
            <Shimmer width={360} height={36} className="w-100 mb-2"/>
            {number > 0 && <Fragment>
                <div className="location"/>
                <ul className="facilities-list clearfix">
                    {generateArray(number).map(i =>
                        <li key={generateToken(32)} className="mb-2">
                            <Shimmer width={360} height={24} className="w-75 m-auto"/>
                        </li>
                    )}
                </ul>
            </Fragment>}
        </div>
    </div>
);

export default CarDetailsBoxShimmerView;