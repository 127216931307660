import React, {useEffect, useReducer, useState} from "react";
import useAxios from "axios-hooks";
import API from "../../classes/API";
import {withRouter} from "react-router-dom";
import AgenciesView from "./agencies.view";
import {ErrorFeedback} from "src/components/feedback";

const AgenciesContainer = ({filters, onFilterChange, match: {path}}) => {

    let url, title;
    switch (path) {
        case '/showrooms':
            url = API.galleries;
            title = 'carsGalleries';
            break;
        case '/dealerships':
            url = API.agencies;
            title = 'carsAgents';
            break;
        case '/companies':
            url = API.companies;
            title = 'carsRentalCompanies';
            break;
        default:
            url = "NA";
            title = "NA";
    }

    const pageSize = 12;
    const [page, setPage] = useState(1);
    const handleLoadMore = () => setPage(page + 1);


    const [{data: _data, loading: fetchLoading, error: fetchError}] = useAxios({
        url: url,
        method: 'PUT',
        data: {
            region: filters.region,
            skip: 0,
            take: (page) * pageSize,
        },
    });

    const [{data: _regionsData, loading: regionsLoading, error: regionsError}] = useAxios({
        method: 'GET',
        url: API.filterRegions,
    });

    const [agencies, dispatch] = useReducer((myArray, {type, value}) => {
        switch (type) {
            case "add":
                return [...myArray, ...value.filter(({id}) => !myArray.some(({id: _id}) => id === _id))];
            case "clear":
                return [];
            default:
                return myArray;
        }
    }, []);

    useEffect(() => {
        setPage(1);
        dispatch({type: 'clear'});
    }, [filters]);


    const loading = fetchLoading || regionsLoading;
    const error = fetchError || regionsError;

    if (!!error) return <ErrorFeedback error={error}/>;

    const regions = _regionsData ? [
        {title: "All", value: -1},
        ..._regionsData,
    ] : [];

    const selectedRegion = filters['region'];
    const data = _data ?? {
        agencies: [],
        total: 0,
        totalFiltered: 0
    };

    const {agencies: newAgencies, totalFiltered} = data;

    if (!loading) {
        if (agencies.length === 0 && newAgencies.length !== 0) {
            dispatch({type: "add", value: newAgencies}); //newAgencies.map(item => ({key: item.id, ...item})));
        } else if (agencies.length !== 0 && newAgencies.length !== 0 && agencies[agencies.length - 1].id !== newAgencies[newAgencies.length - 1].id) {
            dispatch({type: "add", value: newAgencies}); //newAgencies.map(item => ({key: item.id, ...item})));
        }
    }

    return (
        <AgenciesView
            total={totalFiltered}
            url={path}
            loading={loading}
            selectedRegion={selectedRegion}
            onFilterChange={onFilterChange}
            regions={regions}
            onLoadMore={handleLoadMore}
            hasMore={agencies.length < totalFiltered}
            galleries={agencies}
            title={title}
        />
    );

};

export default withRouter(AgenciesContainer);