import { useState } from "react";
import API from "src/classes/API";
import React, { Fragment } from "react";
import { Collapse, Modal, Button } from "react-bootstrap";
import Axios from "axios";

const RatingDialog = ({ id, onClose }) => {
    const [rating, setRating] = useState(0);
    const [feedback, setFeedback] = useState('');

    const handleRatingChange = (newRating) => {
        setRating(newRating);
    };

    const handleFeedbackChange = (event) => {
        setFeedback(event.target.value);
    };

    const handleSubmit = () => {
        Axios.post(API.ratingCar, {
            'ad_id': id,
            'review': feedback,
            'rate': rating,
        });
        console.debug(`id ${id} feedback ${feedback} rate ${rating}`);
        onClose();
    };

    return (
        <Modal show centered onHide={onClose} className="">
            <div className="bg-light">
                <Modal.Header closeButton>
                    <Modal.Title>Rate Your Experience</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="rating-stars d-flex justify-content-center">
                        {[1, 2, 3, 4, 5].map((value) => (
                            <i
                                key={value}
                                className={`fa fa-star${value <= rating ? '' : '-o'} fa-2x`}
                                style={{ color: 'gold', cursor: 'pointer' }}
                                onClick={() => handleRatingChange(value)}
                            />
                        ))}
                    </div>
                    <div className="feedback">
                        <textarea
                            className="form-control mt-3"
                            rows="3"
                            placeholder="Enter your feedback"
                            value={feedback}
                            onChange={handleFeedbackChange}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button variant="success" onClick={handleSubmit}>
                        Submit
                    </Button>
                </Modal.Footer>
            </div>
        </Modal>
    );
};

export default RatingDialog;