import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {updateFilters} from "src/components/filters";
import AgenciesContainer from "./agencies.container";

const AgenciesRedux = () => {
    const filters = useSelector(state => state.filters);
    const dispatch = useDispatch();

    const handleFilterChange = (value) => {
        dispatch(updateFilters('region', value));
    };

    return (
        <AgenciesContainer
            filters={filters}
            onFilterChange={handleFilterChange}
        />
    )
};

export default AgenciesRedux;