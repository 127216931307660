class API {
    static baseURL = "https://api.kurdsale.com/";
    static newBaseURL = "https://ksell.net/api/api/";
    static cars =API.newBaseURL + "ad-cars-listing";
    static carDetails = API.newBaseURL + "ad-car-details-website";
    static filterRegions = API.newBaseURL + "filter-regions";
    static aboutUs = API.newBaseURL + "about-us";
    static faq = API.newBaseURL + "faqs-list";
    static services = API.newBaseURL + "terms-conditions";
    static contactUs = API.newBaseURL + "contact-us";
    static latestOffers = API.newBaseURL + "latest-offers";
    static cardDefinitionsIcons = API.newBaseURL + "card-definition-categories";
    static menuDependencies = API.newBaseURL + "menu-dependencies";
    static dependencies = API.newBaseURL + "dependency-details-list";
    static dependencyDetails = API.newBaseURL + "dependency-details";
    static ratingCar = API.newBaseURL + "ad-rating";

    static relatedCars = API.baseURL + "relatedCars";
    static compareCars = API.baseURL + "cars";
    static features = API.baseURL + "features";
    static filterInitialize = API.newBaseURL + "filter-initialize";
    static getInitialData = API.newBaseURL + "get-initialize-data";
    static galleries = API.baseURL + "galleries";
    static companies = API.baseURL + "rental-companies";
    static agencies = API.baseURL + "agents";
    static agent = API.newBaseURL + "dependency-details";
    static action = API.newBaseURL + "new-action";
    static pushImperession = API.newBaseURL + "matomo-impressions-array";

    static privateCars = API.baseURL + "private-properties";
    static specialNumbers = API.baseURL + "special-numbers";
    static sendEmail = API.baseURL + "send-mail";
    static sideBanner = API.baseURL + "side-banner";
    static mainBanner = API.newBaseURL + "banner/1";
    static welcomeBanner = API.newBaseURL + "banner/2";
    static reaction = API.baseURL + "add-reaction";
    static getToken = API.baseURL + "get-token";

}

export default API;
