import React from "react";
import WatchlistView from "./watchlist.view";
import {useSelector} from "react-redux";

const WatchlistContainer = ({}) => {
    const watchlist = useSelector(state => state.user.watchlist);

    return (
        <WatchlistView watchlist={watchlist}/>
    );
};

export default WatchlistContainer;