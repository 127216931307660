import "./featured-cars.style.scss";

import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import { FeaturedCarCard, FeaturedCarCardShimmer } from "./components";
import { generateToken } from "src/helper";
import { Pagination, RegionFilter } from "src/components";
import NoResultFound from "src/components/feedback/no-result-found";
import { useTranslation } from "react-i18next";
import { SortAction } from "src/pages/sidebar-search-filters/components";
import { Col, Row } from "react-bootstrap";
import Observer from "@researchgate/react-intersection-observer";
import Axios from "axios";
import API from "src/classes/API";

const FeaturedCarsView = ({
  cars,
  regions,
  selectedRegion,
  totalPages,
  page,
  onPageChange,
  onFilterChange,
  loading,
  title,
  isWhatchList,
  isHome
}) => {
  const { t } = useTranslation();
  const handleFilterChange = (value) =>
  typeof onFilterChange === "function" && onFilterChange(value);

  var [tempList, setTempList] = useState([]);
  var [allItemsList, setAllItemsList] = useState([]);
 
 const onChangeImperession = async(id)=>{
  if(!allItemsList.includes(id))
  {
    allItemsList.push(id);
    tempList.push(id);
    if(tempList.length % 12 === 0){
      Axios.post(API.pushImperession,{'items':tempList.slice(-12)});
      tempList = [];
    } 
  }
}
  const renderCars = () => {
    if (loading) {
      return Array.from(Array(12).keys()).map((_, index) => (
        <div key={index} className="col-lg-4 col-md-6 col-sm-12">
          <div className="cars">
            <FeaturedCarCardShimmer number={9} />
          </div>
        </div>
      ));
    } else {
      if (cars.length === 0) {
        return (
          <div className="w-100 d-flex justify-content-center">
            <NoResultFound />
          </div>
        );
      } else {
        return cars?.map((car, index) => (
          <div
            key={generateToken(16)}
            className="col-lg-4 col-md-6 col-sm-12 filtr-item"
          >
            
            <Observer onChange={onChangeImperession(car.id)}>
              <div className="cars"> {<FeaturedCarCard car={car} />}
              </div>
            </Observer>
          </div>
        ));
      }
    }
  };

  return (
    <div className="featured-car mt-5 language-text-dir language-text-align">
      <div className="container">
        <div className="main-title mt2">
          <h1>{t(title)}</h1>
          <Row>
            <Col
              lg={3}
              md={12}
              sm={12}
              className="mb-lg-0 mt-lg-0 mb-3 mt-2 justify-content-center d-flex"
            >
            {isHome?<SortAction />:<></>}
            </Col>
            <Col lg={6} md={12} sm={12}>
              {regions.length > 0 && (
                <RegionFilter
                  regions={regions}
                  onChange={handleFilterChange}
                  value={selectedRegion}
                />
              )}
            </Col>
            
          </Row>
          {/*<SortSelector/>*/}
        </div>

        <div className="row filter-portfolio">{renderCars()}</div>
        {/*<!-- Page navigation start -->*/}
        <Pagination
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      </div>
    </div>
  );
};

FeaturedCarsView.propTypes = {
  totalPages: PropTypes.number.isRequired,
  cars: PropTypes.array.isRequired,
};

FeaturedCarsView.defaultProps = {
  totalPages: 10,
  title: "featuredCars",
};

export default FeaturedCarsView;
 // const carRefs = useRef(cars.map(() => React.createRef()));
  // const [seenCars, setSeenCars] = useState([]);

  // useEffect(() => {
  //   const observer = new IntersectionObserver((entries) => {
  //     entries.forEach((entry) => {
  //       console.debug(`added ${entry.dataset.carId}`);
  //       if (entry.isIntersecting) {
  //         const carId = entry.target.dataset.carId;
  //         setSeenCars((prevSeenCars) => [...prevSeenCars, carId]);
  //       }
  //     });
  //   });
  
  //   carRefs.current.forEach((ref) => {
  //     console.debug(`added ${ref.current}`);
  //     observer.observe(ref.current);
  //   });
  
  //   return () => {
  //     console.debug(`disconnect`);
  //     observer.disconnect();
  //   };
  // }, []);

// const onChange = (id)=>{
//   const timer = setTimeout(() => {
//     onChangeImperession(id)
//   }, 5000);
// }
// const onChangeImperession = (id) => {
//   if (!allItemsList.includes(id)) {
//     setTempList([...tempList, id]); // add new item to temp list
//     setAllItemsList([...allItemsList, id]); // add new item to all items list
//   }
//   console.debug(tempList.length);
//     if (tempList.length === 10) {
//       setTempList([]); // log the response data to the console // clear temp list
//     console.debug(`viewed true`);
//     Axios.post(API.pushImperession, {'items': tempList })
//       .then((response) => {
//         console.debug('response'); // log the response data to the console
//         console.log(response.data);
//       })
//   }else{
//     console.debug(`viewed false`);
//   }
// };