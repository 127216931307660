import {useTranslation} from "react-i18next";
import LanguageSwitcherView from "src/components/language-switcher/language-switcher.view";
import React from "react";

const LanguageSwitcherContainer = () => {
  const lang = localStorage.getItem('lang') ?? "en";
  const handleLanguageChange = lang => {
    localStorage.setItem('lang', lang);
    window.location.reload();
  }

  return (
      <LanguageSwitcherView language={lang} onLanguageChange={handleLanguageChange}/>
  );
}

export default LanguageSwitcherContainer;
