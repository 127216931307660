import React, { useEffect, useState } from "react";
import CarSearchFilters from "./sidebar-search-filters-view";
import useAxios from "axios-hooks";
import API from "../../classes/API";
import stringQuery from "query-string";
import { Redirect } from "react-router-dom";
const navigateTo = (page, totalPages) => {
  const { query } = stringQuery.parse(window.location.search);

  // change 2022 issue-1 issue-3
  let path = window.location.pathname;

  if (path && path.includes("search")) {
    let convertedPath = path.endsWith("/") ? path.slice(0, -1) : path;
    return {
      pathname: convertedPath + "/",
      search: stringQuery.stringify({
        ...query,
        page: Math.max(1, Math.min(page, totalPages)),
      }),
    };
  }
  // end

  return {
    search: stringQuery.stringify({
      ...query,
      page: Math.max(1, Math.min(page, totalPages)),
    }),
  };
};
const SidebarSearchFiltersContainer = ({ page, filters, sort }) => {
  // const [page, setPage] = useState(1);
  const pageSize = 8;
  const handlePageChange = (page) => {
    // setPage(page);
  };

  const [{ data: _data, loading, error }] = useAxios({
    url: API.cars,
    method: "PUT",
    data: {
      filters: filters,
      sort: sort,
      skip: (page - 1) * pageSize,
      take: pageSize,
    },
  });

  const data = _data ?? {
    cars: [],
    total: 0,
    totalFiltered: 0,
  };
  const { cars: _cars, totalFiltered } = data;
  const totalPages = Math.ceil(totalFiltered / pageSize);
  const cars = _cars.map(
    ({ id, name, image, images, Card,  Price,
      Year,
      Mileage,
      City,
      AdNumber,}) => ({
      id,
      images,
      // features,
      Card,
      Price,
      Year,
      Mileage,
      City,
      AdNumber,
      name,
      image,
      // tag,
    })
  );
  if (data.totalFiltered > 0 && cars.length === 0 && page !== 1) {
    return <Redirect to={navigateTo(1, totalPages)} />;
  }

  return (
    <CarSearchFilters
      cars={cars}
      page={page}
      totalPages={totalPages}
      onPageChange={handlePageChange}
      loading={loading}
    />
  );
};

export default SidebarSearchFiltersContainer;
