import {
    CLEAR_COMPARE_LIST,
    TOGGLE_COMPARE_LIST_ITEM,
    TOGGLE_WATCHLIST_ITEM
} from "src/components/actions-buttons/redux/types";

const initState = {
    compareList: JSON.parse(localStorage.getItem("compareList")) ?? [],
    watchList: JSON.parse(localStorage.getItem("watchList")) ?? [],
};

const inArray = (array, item) => array.some(_item => _item === item);
const Reducer = (state = initState, action) => {
    switch (action.type) {
        case TOGGLE_WATCHLIST_ITEM:
            const newWatchList = inArray(state.watchList, action.id) ? state.watchList.filter(_id => _id !== action.id) : [...state.watchList, action.id];
            localStorage.setItem("watchList", JSON.stringify(newWatchList));

            return Object.assign({}, state, {
                ...state,
                watchList: newWatchList
            });

        case TOGGLE_COMPARE_LIST_ITEM:
            const newCompareList = inArray(state.compareList, action.id) ? state.compareList.filter(_id => _id !== action.id) : [...state.compareList, action.id];
            localStorage.setItem("compareList", JSON.stringify(newCompareList));

            return Object.assign({}, state, {
                ...state,
                compareList: newCompareList,
            });


        case CLEAR_COMPARE_LIST:
            localStorage.setItem("compareList", JSON.stringify([]));
            return Object.assign({}, state, {
                ...state,
                compareList: [],
            });


        default :
            return state;
    }
};

export default Reducer;