import MainLayoutView from "./main-layout-view";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {eraseItem} from "./redux/actions";

const MainLayoutRedux = (props) => {
    const loading = useSelector(state => state.mainLayout.loadingQueue).length !== 0;
    const dispatch = useDispatch();

    const LOADING_DOCUMENT = "LOADING_DOCUMENT";
    const onLoad = () => dispatch(eraseItem(LOADING_DOCUMENT));

    useEffect(() => {
        window.addEventListener('load', onLoad);
        return () => window.removeEventListener('load', onLoad);
    }, []);

    return (
        <MainLayoutView loading={loading} {...props}/>
    );
};

export default MainLayoutRedux;