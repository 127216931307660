import './no-result-found.scss';
import React from "react";
import {SearchIcon} from "./icons";

const NoResultFound = props => {
    const text = props.text || "Sorry, No result found";
    const details = props.details || "We can’t find any item.";

    return (
        <div className="feedback-no-result-found">
            <SearchIcon className="feedback-no-result-found--icon"/>
            <span className="feedback-no-result-found--text"> {text} </span>
            <span className="feedback-no-result-found--details"> {details} </span>
        </div>
    );
};

export default NoResultFound;