import React, { useState, useEffect, Fragment } from 'react';
import Image from "react-bootstrap/Image";
import useAxios from "axios-hooks";
import { SideBanner, SubBanner } from "src/components";
import { erasePageLoaderItem, insertPageLoaderItem } from "src/layouts/main-layout";

import propTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import axios from 'axios';
import API from 'src/classes/API';

const AboutUs = props => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const text = t("aboutUsList");

    // if (loading) {
    //     dispatch(insertPageLoaderItem("aboutUS"));
    //     return "loading";
    // }
    // const [data, setData] = useState(null);

    const [{ data: _data, loading, error: fetchError }] = useAxios({
        url: API.aboutUs,
        method: "GET",
    });
    var abouUsList = _data?.Content.split('\n');
    // console.debug(abouUsList?.slice(1));
    // console.debug(_data?.Content);
    // const aboutWelcome = abouUsList[0];
    // const aboutMessage = abouUsList[1];
    return (
        <Fragment>
            <SubBanner title={t("home")} name={t("aboutUs")} />
            <SideBanner />
            <div className="about-car  content-area-5 language-text-align language-text-dir">
                <div className="main-title text-center ">
                    <h1>{t("aboutUs")}</h1>

                    <p></p>
                </div>
                <div className="container">

                    <div className="row">

                        <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 m-auto">
                            <div className="about-slider-box simple-slider">
                                <Image className="d-block w-80" src="/img/logos/header-logo.png" alt="about" />
                            </div>
                        </div>
                        <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-xs-12">
                            <div className="about-text">
                                <div>
                                    {abouUsList?.map((item, index) => (
                                        <div
                                            key={item}
                                        >


                                            {index == 0 
                                            ? (<h3 className="text-red ">{item}</h3>) 
                                             : (<i style={{ whiteSpace: "pre-wrap" }}>{item}</i>)
                                             }{item.name}
                                        </div>
                                    ))}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>

    );
};
AboutUs.propTypes = {
    image: propTypes.string.isRequired,
    firstText: propTypes.string.isRequired,
    secondText: propTypes.string,
};
AboutUs.defaultProps = {
    image: "https://picsum.photos/1920/500/?blur",
};
export default AboutUs;