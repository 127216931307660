import React,{useEffect} from 'react'
import FiltersContainer from "./filters.container.js";
import {clearFilters, setDefaultFilter, updateFilters} from "./redux/actions";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from 'prop-types';
import {updatePage} from "src/components/featured-cars";

import { useParams } from 'react-router-dom'


const FiltersRedux = ({reduxSelector, ...rest}) => {

    let { slug } = useParams();


    const filters = useSelector(state => state[reduxSelector]);
    const dispatch = useDispatch();
   
    const handleChange = (name, value) => {
        dispatch(updateFilters(name, value));
        // dispatch(updatePage(1));


    };

    const handleClear = () => {
        window.localStorage.removeItem('__slug__');
        dispatch(clearFilters());
        // dispatch(updatePage(1));
    }

    useEffect(() => {
        if(slug){
            dispatch(setDefaultFilter(slug,filters));
        }
       },[])

    return (
        <FiltersContainer
            filters={filters}
            onChange={handleChange}
            onClear={handleClear}
            {...rest}
        />
    );
};

FiltersRedux.propTypes = {
    stateSelector: PropTypes.string,
};

FiltersRedux.defaultProps = {
    reduxSelector: 'filters',
};

export default FiltersRedux;