import React, { useEffect, useState } from 'react'
import FiltersContainer from "./filters.container.js";
import {clearFilters, setDefaultFilter, updateFilters} from "../../../components/filters/redux/actions";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from 'prop-types';

import { useParams } from 'react-router-dom'

const FiltersRedux = ({reduxSelector}) => {

    let { slug } = useParams();

    const filters = useSelector(state => state[reduxSelector]);

    const dispatch = useDispatch();

    const [isInit,setInit] = useState(true)


    const handleChange = (name, value) => {
        setInit(false);
        dispatch(updateFilters(name, value));
    };

    const handleClear = () => {
        dispatch(clearFilters());
    };


   useEffect(() => {
    if(slug){
        dispatch(setDefaultFilter(slug,filters));
    }
   },[])
    

    return (
        <FiltersContainer
            filters={filters}
            onChange={handleChange}
            onClear={handleClear}
            isInit={isInit}
        />
    );
};

FiltersRedux.propTypes = {
    stateSelector: PropTypes.string,
};

FiltersRedux.defaultProps = {
    reduxSelector: 'filters',
};

export default FiltersRedux;