import React from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {generateToken} from "src/helper";

const CarDetailsLoader = ({detailsUrl, name}) => {
    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 'auto',
            width: '100%',
            borderRadius: 8,
            overflow: "hidden",
            backgroundColor: "#ffffff",
        }}>
            <OverlayTrigger
                key={generateToken(16)}
                placement={'bottom'}
                overlay={
                    <Tooltip id={`tooltip-${'bottom'}`}>
                        {name}
                    </Tooltip>
                }
            >
                <h1 className="title text-overflow-ellipsis">
                    <a href={detailsUrl}>{name}</a>
                </h1>
            </OverlayTrigger>
        </div>
    );
};
CarDetailsLoader.defaultProps = {
    width: 300,
    height: 30,
};
export default CarDetailsLoader;