const baseUrl = "http://kurdsale.com/cars/special-numbers/big_images/";
export const numbersImages = {
    0: baseUrl + "s_83490.jpg",
    1: baseUrl + "s_554991.jpg",
    2: baseUrl + "s_268842.jpg",
    3: baseUrl + "s_349993.jpg",
    4: baseUrl + "s_135184.jpg",
    5: baseUrl + "s_384785.jpg",
    6: baseUrl + "s_246016.jpg",
    7: baseUrl + "s_779347.jpg",
    8: baseUrl + "s_30238.jpg",
    9: baseUrl + "s_273709.jpg"
};

export const regionImages = {
    Erbil: baseUrl + "16027erbil2.jpg",
    Duhok: baseUrl + "32520duhok2.jpg",
    Sulaymaniah: baseUrl + "60810sulaymaniah2.jpg",
    Tameem: baseUrl + "94636ejr0lprk2-12.jpg"
};
/*[
    {"type": "header", "version": "4.9.4", "comment": "Export to JSON plugin for PHPMyAdmin"},
    {"type": "database", "name": "kurdsale_numbers"},
    {
        "type": "table", "name": "cars_album", "database": "kurdsale_numbers", "data":
            [
                {
                    "ID": "1",
                    "ParentID": "3",
                    "CountryID": "2",
                    "CityID": "215",
                    "Image": "16027erbil2.jpg",
                    "ImageMobile": "26138erbil4.jpg",
                    "ImageIpad": "77166ipad-p-erbil.jpg",
                    "Order": "1",
                    "NowDate": "04\/29\/2015",
                    "Active": "1"
                },
                {
                    "ID": "2",
                    "ParentID": "3",
                    "CountryID": "2",
                    "CityID": "214",
                    "Image": "60810sulaymaniah2.jpg",
                    "ImageMobile": "74939sulaymaniah4.jpg",
                    "ImageIpad": "88226ipad-p-sulaymaniyah.jpg",
                    "Order": "2",
                    "NowDate": "05\/04\/2015",
                    "Active": "1"
                },
                {
                    "ID": "3",
                    "ParentID": "3",
                    "CountryID": "2",
                    "CityID": "243",
                    "Image": "32520duhok2.jpg",
                    "ImageMobile": "14163duhok4.jpg",
                    "ImageIpad": "5553ipad-p-duhok.jpg",
                    "Order": "3",
                    "NowDate": "05\/04\/2015",
                    "Active": "1"
                },
                {
                    "ID": "4",
                    "ParentID": "4",
                    "CountryID": "2",
                    "CityID": "215",
                    "Image": "25019r-arbil.jpg",
                    "ImageMobile": "46950pu-erbil2.jpg",
                    "ImageIpad": "7383ipad-pu-erbil.jpg",
                    "Order": "4",
                    "NowDate": "05\/04\/2015",
                    "Active": "1"
                },
                {
                    "ID": "5",
                    "ParentID": "4",
                    "CountryID": "2",
                    "CityID": "214",
                    "Image": "89849r-sulaymani.jpg",
                    "ImageMobile": "91623pu-sulaymaiyah2.jpg",
                    "ImageIpad": "71759ipad-pu-sulaymaniyah.jpg",
                    "Order": "5",
                    "NowDate": "05\/04\/2015",
                    "Active": "1"
                },
                {
                    "ID": "6",
                    "ParentID": "4",
                    "CountryID": "2",
                    "CityID": "243",
                    "Image": "49922r-duhok.jpg",
                    "ImageMobile": "66038pu-duhok2.jpg",
                    "ImageIpad": "48802ipad-pu-duhok.jpg",
                    "Order": "6",
                    "NowDate": "05\/04\/2015",
                    "Active": "1"
                },
                {
                    "ID": "7",
                    "ParentID": "5",
                    "CountryID": "2",
                    "CityID": "215",
                    "Image": "71243y-arbil.jpg",
                    "ImageMobile": "70547t-erbil2.jpg",
                    "ImageIpad": "61781ipad-t-erbil.jpg",
                    "Order": "7",
                    "NowDate": "05\/04\/2015",
                    "Active": "1"
                },
                {
                    "ID": "8",
                    "ParentID": "5",
                    "CountryID": "2",
                    "CityID": "214",
                    "Image": "99878y-sulaymani.jpg",
                    "ImageMobile": "94471t-sulaymaniyah2.jpg",
                    "ImageIpad": "8253ipad-t-sulaymaniyah.jpg",
                    "Order": "8",
                    "NowDate": "05\/04\/2015",
                    "Active": "1"
                },
                {
                    "ID": "9",
                    "ParentID": "5",
                    "CountryID": "2",
                    "CityID": "243",
                    "Image": "23466y-duhok.jpg",
                    "ImageMobile": "6969t-duhok2.jpg",
                    "ImageIpad": "9346ipad-t-duhok.jpg",
                    "Order": "9",
                    "NowDate": "05\/04\/2015",
                    "Active": "1"
                },
                {
                    "ID": "10",
                    "ParentID": "3",
                    "CountryID": "2",
                    "CityID": "245",
                    "Image": "94636ejr0lprk2-12.jpg",
                    "ImageMobile": "72672tameem.jpg",
                    "ImageIpad": "34156tameem.jpg",
                    "Order": "10",
                    "NowDate": "12\/02\/2015",
                    "Active": "1"
                }
            ]
    }
]*/
