import React from "react";
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import './banner.style.scss';
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";

const BannerView = ({ images }) => {
    const AutoplaySlider = withAutoplay(AwesomeSlider);
    return (
        
        <div className="image-slider" style={{ background: '#f0f0f0', height: '100% !important' }}>
                <Container fluid={true} className="mr-md-5 ml-md-5">

                 <AutoplaySlider
                        play={true}
                        interval={6000}
                    >

                        {images.map(image => <div className="w-100"> <Image className="w-100" fluid src={image.Image} /> </div>)}
                    </AutoplaySlider>
                

                </Container>
            </div>
    );
};

BannerView.defaultProps = {
    images: [
        "./banner.jpg"
    ],
};
export default BannerView;
