import React, {Fragment} from "react";
import "../../components/featured-cars/featured-cars.style.scss"
import {Pagination, SideBanner, SubBanner} from "src/components";
import {BoxShimmer} from "../../components";
import NoResultFound from "src/components/feedback/no-result-found";
import {generateToken} from "src/helper";
import classes from "classnames";
import SpecialBox from "./component";
import {useTranslation} from "react-i18next";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import GoogleAdsView from "src/components/google-ads/google-ads-view";

const SpecialNumbersView=({numbers, totalPages, onPageChange, loading})=>{
    const {t}=useTranslation();
    const { width } = useWindowDimensions();
    const renderCars = () => {
        if (loading) {
            return Array.from(Array(12).keys()).map((_, index) =>
                <div key={index} className="col-lg-4 col-md-6 col-sm-12">
                    <div className="cars"><BoxShimmer number={6}/></div>
                </div>
            );
        } else {
            if (numbers.length === 0) {
                return (
                    <div className="w-100 d-flex justify-content-center">
                        <NoResultFound/>
                    </div>
                )
            } else {
                return numbers.map((number, index) =>
                    <div key={generateToken(16)} className="col-lg-4 col-md-6 col-sm-12 filtr-item">
                        <div className="cars"> {<SpecialBox number={number}/>} </div>
                    </div>
                )
            }
        }
    };

    return(
        <Fragment>
            <SubBanner title={t("home")} name={t("specialNumbers")}/>
            {(width<=750)?
        <GoogleAdsView 
        path='/21894097782/Kurdsale_300x250(1)'
        size={[300, 250]} 
        id='div-gpt-ad-1617871697027-0' 
        style={{width:'300px',height:'250px',padding: '10px 0px 10px 0px',margin:'10px auto 10px auto'}}
        />
        :
        <GoogleAdsView 
         path='/21894097782/Kurdsale_728x90_970x90_970x250(1)'
         size={[[970, 90], [970, 250], [728, 90]]} 
         id='div-gpt-ad-1617871600777-0' 
         style={{textAlign:'center',padding: '10px 0px 10px 0px'}}
         />
        }
            <SideBanner/>
            {(width<=750)?
        <GoogleAdsView 
        path='/21894097782/Kurdsale_300x250(2)'
        size={[300, 250]} 
        id='div-gpt-ad-1617871672756-0'
        style={{width:'300px',height:'250px',padding: '10px 0px 10px 0px',margin:'10px auto 10px auto'}}
        />
        :
        <GoogleAdsView 
        path='/21894097782/Kurdsale_728x90_970x90_970x250(2)'
        size={[[728, 90], [970, 90], [970, 250]]} 
        id='div-gpt-ad-1617871549077-0'
        style={{textAlign:'center',padding: '10px 0px 10px 0px'}}
        />
        }
            <div className="featured-car mt-5">
                <div className="container">
                    <div className="main-title mt2">
                        <h1>{t("specialNumbers")}</h1>
                    </div>
                    <div className="row filter-portfolio">
                        {renderCars()}
                    </div>
                    {/*<!-- Page navigation start -->*/}
                    <Pagination onPageChange={onPageChange} totalPages={totalPages}/>
                </div>
            </div>
        </Fragment>
    );
};
export default SpecialNumbersView;