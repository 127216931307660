import React from "react";
import {useTranslation} from "react-i18next";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const SortActionView = ({options, onSort}) => {
    const {t} = useTranslation();

    return (
        <div className="sorting-options2 language-text-align language-text-dir">
            <strong className="sort">{t("sortBy")}:</strong>
            <Select className="search-fields language-text-align ml-2 mr-2"
                    style={{width: 200, height: 24, padding: 10}}
                    defaultValue={JSON.stringify(options[0].value)}
                    displayEmpty
                    name="default-order"
                    MenuProps={{
                        disableScrollLock: true,
                    }}
                    onChange={(e) => onSort(JSON.parse(e.target.value))}>
                {options.map(({title, value}, index) =>
                    <MenuItem className="language-text-align" key={index}
                              value={JSON.stringify(value)}>{t(`${title}`)}
                    </MenuItem>
                )}
            </Select>
        </div>
    );
};
export default SortActionView;