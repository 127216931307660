import React from "react";
import {Marker, StaticGoogleMap} from "react-static-google-map";

const GoogleMapView = ({location: {x_map, y_map}, size}) => (

    <a
        href={`https://www.google.com/maps/search/?api=1&query=${x_map},${y_map}`}
        className="w-100"
        target="_blank"
    >
        <StaticGoogleMap
            size={size}
            center={`${x_map},${y_map}`}
            apiKey="AIzaSyC8Naj1ZHfKplwusqnmmRQ5CIuJEo6M7ts"
            zoom="14"
            cache={true}
            language="ar"
            width="100%"
        >
            <Marker
                location={{lat: x_map, lng: y_map}}
                color="red"
                label="SSSS"
            />
        </StaticGoogleMap>
    </a>
);

export default GoogleMapView;