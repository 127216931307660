import React, { useState } from "react";
import { RangeSlider } from "src/components/forms";
import { generateToken } from "src/helper";
import FilterSelect from "src/components/forms/select/filter-select";
import { filtersTypes } from "./filters.config";
import { useTranslation } from "react-i18next";

import { useHistory } from "react-router-dom";
import FilterStatics from "src/classes/Filter.Statics";


const storeLocalItem = (key, value) => {
  localStorage.setItem(key, value);
}


const getLocalItem = (key) => {
  return localStorage.getItem(key)
}


const SidebarFilters = ({
  filtersConfig,
  moreFiltersConfig,
  filtersValue,
  onChange,
  onClear,
  data
}) => {


  const { t } = useTranslation();

  // let history = useHistory();

  const [activeBrand, setActiveBrand] = useState('');
  const [activeModel, setActiveModel] = useState('');
  const [activeGroup, setActiveGroup] = useState('');
  const [activeKind, setActiveKind] = useState('');
  const [activeRegion, setActiveRegion] = useState('');



  const handleOnSelectFilter = (name, value, item = null) => {


    try {


      var title = "";

      title = item.options.find((option) => option.value === value)?.en;
      var url = ''


      if ([FilterStatics.specifc1, FilterStatics.specifc2, FilterStatics.specifc3, FilterStatics.specifc4].indexOf(name) !== -1) {
        window.localStorage.removeItem('__slug__');
      }


      switch (name) {
        case FilterStatics.specifc1:
          storeLocalItem("activeBrand", title);
          setActiveBrand(title);
          url = "" + title;
          break;

        case FilterStatics.specifc2:
          storeLocalItem("activeModel", title);
          setActiveModel(title);
          url = `${getLocalItem("activeBrand")}_${title}`
          break;

        case FilterStatics.specifc3:
          storeLocalItem("activeKind", title);
          setActiveKind(title);
          url = `${getLocalItem("activeBrand")}_${getLocalItem(
            "activeModel"
          )}_${getLocalItem("activeGroup")}_${title}`
          break;

        case FilterStatics.specifc4:
          storeLocalItem("activeRegion", title);
          setActiveRegion(title);

          var slug = window.localStorage.getItem('__slug__');

          if (slug) {

            var splitSlug = slug.split('_');

            // console.table('splitSlug', splitSlug)

            // Add
            if (splitSlug.length === 4) {
              url = `${slug}_${title}`
            }

            // Replace 
            if (splitSlug.length === 5) {
              url = splitSlug.slice(0, -1).join('_') + '_' + title;

            }


          } else if (activeBrand && activeModel && activeGroup && activeKind) {

            url = `${activeBrand}_${activeModel}_${activeGroup}_${activeKind}_${title}`

          }

          break;

        default:
          break;
      }

      if (url !== '') {

        if (activeRegion !== '' && name !== 'region') {
          url += '_' + activeRegion;
        }
        window.history.pushState('', 'KurdSale', '/car-search-filters/' + url);

      }
      onChange(name, value);


    } catch (error) {
      // console.table(error);
      onChange(name, value);
    }


  };


  const handleOnClear = () => {

    setActiveBrand('')
    setActiveModel('')
    setActiveGroup('')
    setActiveKind('')
    setActiveRegion('')


    window.history.pushState('', 'KurdSale', '/car-search-filters');
    onClear();
  }

  //Ossama Slider 3 : choose the right widget for the case 
  const _getDefaultvalue = (type) => {

    switch (type) {




      case FilterStatics.specifc2:
        return data && data.activeModel ? data.activeModel.value : false

      case FilterStatics.specifc3:
        return data && data.activeGroup ? data.activeGroup.value : false

      case FilterStatics.specifc4:
        return data && data.actibeKind ? data.actibeKind.value : false


      default:
        return false;

    }
  }

  return (
    <div className="sidebar-left language-text-dir language-text-align">
      <div className="widget advanced-search2 widget-3">
        <h3 className="sidebar-title">{t("searchCars")}</h3>
        {filtersConfig.map((item) => {
          switch (item.type) {
            case filtersTypes.NORMAL_SELECT:
              return (
                <FilterSelect
                  key={generateToken(16)}
                  name={item.name}
                  title={item.title}
                  options={item.options}
                  value={_getDefaultvalue(item.name) ? _getDefaultvalue(item.name) : filtersValue[item.name]}
                  onChange={(value) => handleOnSelectFilter(item.name, value, item)}
                />
              );
            case filtersTypes.RANGE_SLIDER:
              return (
                <RangeSlider
                  key={generateToken(16)}
                  name={item.name}
                  title={t(`${item.title}`)}
                  range={item.range}
                  unit={item.unit}
                  step={item.step}
                  value={filtersValue[item.name]}
                  onChange={(value) => onChange(item.name, value)}
                />
              );
            default:
              return null;
          }
        })}

        {/* <a
          className="show-more-options"
          data-toggle="collapse"
          data-target="#options-content"
          aria-expanded="true"
        >
          <i className="fa fa-minus-circle" /> {t("otherFeatures")}
        </a> */}

        <div id="options-content" className="collapse show">
          {moreFiltersConfig.map((item) => {
            switch (item.type) {
              case filtersTypes.NORMAL_SELECT:
                return (
                  <FilterSelect
                    key={generateToken(16)}
                    name={item.name}
                    title={item.title}
                    options={item.options}
                    value={filtersValue[item.name]}
                    onChange={(value) => handleOnSelectFilter(item.name, value, item)}
                  />
                );

              case filtersTypes.RANGE_SLIDER:
                return (
                  <RangeSlider
                    key={generateToken(16)}
                    name={item.name}
                    title={t(`${item.title}`)}
                    unit={item.unit}
                    range={item.range}
                    step={item.step}
                    value={filtersValue[item.name]}
                    onChange={(value) => handleOnSelectFilter(item.name, value, item)}
                  />
                );
              default:
                return null;
            }
          })}
        </div>
        <div className="form-group mb-0">
          <button className="search-button btn" onClick={handleOnClear}>
            {t("reset")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SidebarFilters;
