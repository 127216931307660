import React from "react";
import useDfpSlot from "src/hooks/useDfpSlot";
const GoogleAdsView=({path,size,id,style})=>{
  const pathname=window.location.pathname;
    useDfpSlot({
        path: path,
        size: size,
        id: id,
        pathname:pathname
       })
    return (
    <div
      id={id}
      style={style}
    ></div>
    )
}
export default GoogleAdsView;
