import React, { useEffect, useState } from "react";
import classes from "classnames";
import { Link } from "react-router-dom";
import stringQuery from "query-string";

const navigateTo = (page, totalPages) => {
  const { query } = stringQuery.parse(window.location.search);

  // change 2022 issue-1 issue-2
  let path = window.location.pathname;

  if (path) {
    let convertedPath = path.endsWith("/") ? path.slice(0, -1) : path;
    return {
      pathname: convertedPath + "/",
      search: stringQuery.stringify({
        ...query,
        page: Math.max(1, Math.min(page, totalPages)),
      }),
    };
  }
  // end

  return {
    search: stringQuery.stringify({
      ...query,
      page: Math.max(1, Math.min(page, totalPages)),
    }),
  };
};

// change 2022 issue-5
const Pagination = ({ page, totalPages }) => {
  const [arrOfCurrButtons, setArrOfCurrButtons] = useState([]);

  const numOfButtons = [];
  for (let i = 1; i <= totalPages; i++) {
    numOfButtons.push(i);
  }

  useEffect(() => {
    let tempNumberOfButtons = arrOfCurrButtons;

    let dotsInitial = "...";
    let dotsLeft = "... ";
    let dotsRight = " ...";

    if (numOfButtons.length < 6) {
      tempNumberOfButtons = numOfButtons;
    } else if (page >= 1 && page <= 3) {
      tempNumberOfButtons = [1, 2, 3, 4, dotsInitial, numOfButtons.length];
    } else if (page === 4) {
      const sliced = numOfButtons.slice(0, 5);
      tempNumberOfButtons = [...sliced, dotsInitial, numOfButtons.length];
    } else if (page > 4 && page < numOfButtons.length - 2) {
      // from 5 to 8 -> (10 - 2)
      const sliced1 = numOfButtons.slice(page - 2, page);
      // sliced1 (5-2, 5) -> [4,5]
      const sliced2 = numOfButtons.slice(page, page + 1);
      // sliced1 (5, 5+1) -> [6]
      tempNumberOfButtons = [
        1,
        dotsLeft,
        ...sliced1,
        ...sliced2,
        dotsRight,
        numOfButtons.length,
      ];
      // [1, '...', 4, 5, 6, '...', 10]
    } else if (page > numOfButtons.length - 3) {
      // > 7
      const sliced = numOfButtons.slice(numOfButtons.length - 4);
      // slice(10-4)
      tempNumberOfButtons = [1, dotsLeft, ...sliced];
    }

    setArrOfCurrButtons(tempNumberOfButtons);
  }, [page, totalPages]);

  return (
    <div className="pagination-box text-center lang-en">
      <nav aria-label="Page navigation example">
        <ul className="pagination">
          <li className={`page-item ${page === 1 ? "disabled" : ""}`}>
            <Link to={navigateTo(page - 1, totalPages)} className="page-link">
              <i className="fa fa-angle-left" />
            </Link>
          </li>
          {arrOfCurrButtons.map((i, index) => {
            var pageNum = i;
            if (pageNum === "...") {
              pageNum = arrOfCurrButtons[arrOfCurrButtons.length - 3] + 1;
            } else if (pageNum === "... ") {
              pageNum = arrOfCurrButtons[3] - 2;
            } else if (pageNum === " ...") {
              pageNum = arrOfCurrButtons[3] + 2;
            }
            return (
              <li key={i} className="page-item">
                <Link
                  to={navigateTo(pageNum, totalPages)}
                  className={classes("page-link", { active: page === pageNum })}
                >
                  {i}
                </Link>
              </li>
            );
          })}
          <li
            className={`page-item ${
              page === numOfButtons.length ? "disabled" : ""
            }`}
          >
            <Link to={navigateTo(page + 1, totalPages)} className="page-link">
              <i className="fa fa-angle-right" />
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
