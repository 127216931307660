import React, { Fragment, useEffect, useState } from "react";
import { SideBanner, SubBanner } from "src/components";
import propTypes from 'prop-types';
import { generateToken } from "src/helper";
import { useTranslation } from "react-i18next";
import Axios from "axios";
import API from "src/classes/API";

const Faq = props => {

    const { t } = useTranslation();

    const [data, setData] = useState(null);

    useEffect(() => {
        Axios.get(API.faq)
            .then(response => {
                setData(response.data);
            });
    }, []);

    return (
        <Fragment>
            <SubBanner title={t("home")} name={t("faq")} />
            <SideBanner />
            <div className="faq content-area-5 language-text-dir">
                <div className="container">
                    <div className="main-title text-center">
                        <h1>{t("faq")}</h1>
                        <p></p>
                    </div>
                    <div className="row language-text-align">
                        <div className="col-lg-10 offset-lg-1 ">
                            <div id="faq" className="faq-accordion">
                                <div className="card m-b-0">
                                    {data && (
                                        data.map(({ question, answer }, idx) => (
                                                 <Fragment key={generateToken(12)}>
                                                 <div className="card-header">
                                                     <a className="card-title collapsed" data-toggle="collapse"
                                                         data-parent="#faq"
                                                         href={`#collapse${idx}`}>
                                                         {question}
                                                     </a>
                                                 </div>
                                                 <div id={`collapse${idx}`} className="card-block collapse">
                                                     <div className="p-text">
                                                         {answer}
                                                     </div>
                                                 </div>
                                             </Fragment>
                                            ))
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

Faq.propTypes = {
    questions: propTypes.array.isRequired,
};
Faq.defaultProps = {
    questions: [
        {
            question: "How can i choose multiple Car",
            answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec luctus tincidunt aliquam. Aliquam gravida massa at sem vulputate interdum et vel eros. Maecenas eros enim, tincidunt vel turpis vel, dapibus tempus nulla. Donec vel nulla dui"
        },
        {
            question: "How can i choose multiple Car",
            answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec luctus tincidunt aliquam. Aliquam gravida massa at sem vulputate interdum et vel eros. Maecenas eros enim, tincidunt vel turpis vel, dapibus tempus nulla. Donec vel nulla dui"
        }
    ]
};
export default Faq;