import classes from "classnames";
import {generateToken} from "src/helper";
import React from "react";
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";

const RegionFilter = ({regions, value: _value, onChange}) => {
    const {t} = useTranslation();
    return (
        <div className="list-inline-listing">
            <ul className="filters filteriz-navigation clearfix">
                <li className={classes("btn filtr-button filtr", {active: _value === null})}
                    data-filter="all"
                    onClick={() => onChange(null)}>
                    {t("all")}
                </li>
                {regions.map(({title, value}, index) => title !== 'All' &&
                    <li key={index}
                        className={classes("btn filtr-button filtr", {active: _value === value})}
                        data-filter={value} onClick={() => onChange(value)}>
                        {title}
                    </li>
                )}
            </ul>
        </div>
    );
}
RegionFilter.propTypes = {
    regions: PropTypes.array.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
};

export default RegionFilter;