import React from "react";
import CarBox from './car-box';
import CarDetails from './car-details';

const CarDetailsBox = ({car}) => (
    <div className="car-box">
        <CarBox car={car} detailsUrl={`/car-details/${car.id}/${car.name.replace(/ /g, '-').toLowerCase()}`}/>
        <a href={`/car-details/${car.id}/${car.name.replace(/ /g, '-').toLowerCase()}`}>
        <CarDetails car={car} detailsUrl={`/car-details/${car.id}/${car.name.replace(/ /g, '-').toLowerCase()}`}/>
        </a>
    </div>
);

export default CarDetailsBox;