import API from "src/classes/API";
import React from "react";
import useAxios from 'axios-hooks';
import axios from 'axios';
import ContactUs from "./contact-us-view";
import {Error404Page} from "src/pages";
import {useDispatch} from "react-redux";
import {erasePageLoaderItem, insertPageLoaderItem} from "src/layouts/main-layout";

const ContactUsContainer = () => {
    
    
    const handleSubmit = (values, {setErrors}) => {
        // console.log(values);
    
        axios.post(API.sendEmail, values).then(data => {
        }).catch(res => setErrors({
            "name": "Invalid name",
            "mail": "Invalid mail",
            "subject": "Invalid subject",
            "number": "Invalid number",
            "message":"Invalid message"
        }));
    };



    return (
        <ContactUs  onSubmit={handleSubmit}/>
    );

};
export default ContactUsContainer;