import React from "react";
import useAxios from "axios-hooks";
import API from "../../classes/API";
import LatestOffersView from "./latest-offers-view";
import {ErrorFeedback} from "src/components/feedback";

const LatestOffersDataWrapper = props => {

    const [{data, loading, error}] = useAxios({
        url: API.latestOffers,
        method: 'GET',
        data:{
            skip:0,
            take:6
        }
    });

    if(!!error) return <ErrorFeedback error={error}/>


    return (
        <LatestOffersView cars={data} loading={loading}/>
    )
};
export default LatestOffersDataWrapper;