import RangeSliderView from "./range-slider.view";
import React, {useState} from "react";
import propTypes from "prop-types";

const RangeSliderContainer = ({title, name, range,step, value: _value, unit, onChange}) => {
    const [value, setValue] = useState(_value ?? {start: range.min, end: range.max});
    const handleChange = () => {
        // const newValue = {start: Math.min(value.start, value.end), end: Math.max(value.start, value.end)};
        // setValue(value);

        if(typeof onChange === 'function')
            onChange(value);
    }

    return (
        <RangeSliderView
            title={title}
            range={range}
            value={value ?? {start: range.min, end: range.max}}
            name={name}
            unit={unit}
            step={step}
            onChange={setValue}
            onChangeComplete={handleChange}
        />
    )
};

RangeSliderContainer.propTtpes = {
    title: propTypes.string.isRequired,
    range: propTypes.shape({
        min: propTypes.oneOf([propTypes.string, propTypes.number]).isRequired,
        max: propTypes.oneOf([propTypes.string, propTypes.number]).isRequired,
    }).isRequired,
    unit: propTypes.string.isRequired,
    onChange: propTypes.func,
};
export default RangeSliderContainer;