import React from "react";
import useAxios from 'axios-hooks';
import API from "src/classes/API";
import CompareCars from "./compare-cars-view";
import NoResultFound from "src/components/feedback/no-result-found";
import queryString from "query-string";
import {useTranslation} from "react-i18next";

const CompareCarsContainer = () => {
    const href = window.location.href;
    let {query: {first: firstId, second: secondId}} = queryString.parseUrl(href);
    // const {t} = useTranslation();


    const [{data: firstCar, loading: firstLoading, error: firstError}] = useAxios({
        url: `${API.carDetails}/${firstId}`,
        method: 'GET',
    });

    const [{data: secondCar, loading: secondLoading, error: secondError}] = useAxios({
        url: `${API.carDetails}/${secondId}`,
        method: 'GET',
    });


    if (!firstId || !secondId) {
        return (
            <div className="w-100 d-flex justify-content-center">
                <NoResultFound/>
            </div>
        );
    }

    const loading = firstLoading || secondLoading;
    const error = firstError || secondError;

    if (loading) {
        return "Loading.."
    } else if (!!error) {
        return "Error !!";
    }

    return (
        <CompareCars
            cars={[firstCar, secondCar]}
        />
    );
};

export default CompareCarsContainer;