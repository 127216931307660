import React from "react";
import CarDetailsView from "./car-details-view";
import {Link, Redirect, withRouter} from "react-router-dom";
import useAxios from "axios-hooks";
import API from "../../classes/API";
import {erasePageLoaderItem, insertPageLoaderItem} from "src/layouts/main-layout";
import {useDispatch} from "react-redux";

const CarDetailsData = ({match: {params: {id}}}) => {
    const dispatch = useDispatch();

    const [{data, loading, error}] = useAxios({
        url: `${API.carDetails}/${id}`,
        method: 'GET',
    });

    if (loading) {
        dispatch(insertPageLoaderItem("CarDetailsData"));
        return "Loading";
    } else if (error) {
        dispatch(erasePageLoaderItem("CarDetailsData"));
        if (error.response.status === 404)
            return <Redirect to={'/404'}/>
        return "Error";
    }
    dispatch(erasePageLoaderItem("CarDetailsData"));

    return (
        <CarDetailsView car={data}/>
    )
};

export default withRouter(CarDetailsData);
