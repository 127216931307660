import React, {Fragment} from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {generateToken} from "src/helper"
import "./special.numbers.style.css";
import {useTranslation} from "react-i18next";

const SpecialDetailsView = ({number: {agency: {name, id}, price: _price, date: _date, mobile, mobileExtra, type, currency}}) => {

    const {t} = useTranslation();

    const options = {year: 'numeric', month: 'long', day: 'numeric'};
    const date = _date ? new Date(_date).toLocaleDateString() : "NA";
    const price = !!_price ? `${_price}${currency}` : t('callUs');

    let features = [
        {value: date, icon: "flaticon-calendar-1"},
        {value: type, icon: "flaticon-gear"},
        {value: price, icon: "fa fa-dollar"},
        {value: mobile, icon: "flaticon-phone"},
    ];
    if (!!mobileExtra) {
        features = [
            ...features,
            {value: mobileExtra, icon: "flaticon-phone"},
        ]
    }

    const detailsUrl = `/agencies/${id}`;

    return (
        <div className="detail language-text-dir language-text-align">
            <OverlayTrigger
                key={generateToken(32)}
                placement={'bottom'}
                overlay={
                    <Tooltip id={`tooltip-${'bottom'}`}>
                        {name}
                    </Tooltip>
                }
            >
                <h1 className="title text-overflow-ellipsis ">
                    <a href={detailsUrl}>{name}</a>
                </h1>
            </OverlayTrigger>

            <div className="location"/>
            <ul className="special-facilities-list clearfix">
                {features?.map((item, index) =>
                    <OverlayTrigger
                        key={generateToken(32)}
                        placement={'bottom'}
                        overlay={
                            <Tooltip id={`tooltip-${'bottom'}`}>
                                {item.value}
                            </Tooltip>
                        }
                    >
                        <li key={index} className="text-overflow-ellipsis language-text-dir">
                            {item.value ?
                                <Fragment>
                                    <span className={`${item.icon} m-2`}
                                          style={{display: "inline-flex"}}/><span> {item.value} </span>
                                </Fragment>
                                : <Fragment>
                                    <span className={`${item.icon} m-2`} style={{display: "inline-flex"}}/><a
                                    href="tel:+9647513359921"> {t("contactUs")} </a>
                                </Fragment>
                            }
                        </li>
                    </OverlayTrigger>
                )}
            </ul>
        </div>
    );
};

SpecialDetailsView.defaultProps = {};

export default SpecialDetailsView;
