import {UPDATE_PAGE} from "src/components/featured-cars/redux/types";
import * as queryString from "query-string";

const {query: {page}} = queryString.parseUrl(window.location.href);
const initState = parseInt(page ?? "1");

const pageReducer = (state = initState, acton) => {
    switch (acton.type) {
        case UPDATE_PAGE:
            return acton.page;

        default:
            return state;
    }
};

export default pageReducer;
