import React, { Fragment, useState } from "react";
import { GoogleMap, SideBanner, SubBanner } from "src/components";
import { generateToken } from "src/helper";
import useAxios from "axios-hooks";
import classes from "classnames";
import { useFormik } from 'formik';
import { validationSchema } from './contact-us.validation'
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Error404Page } from "..";
import { useDispatch } from "react-redux";
import API from "src/classes/API";
import Axios from "axios";


const ContactUs = ({ onSubmit }) => {
    const dispatch = useDispatch();
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const [showSuccess, setShowSuccess] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        number: '',
        message: ''
    });
    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };
    const handleSubmited = async (event) => {
        event.preventDefault();
        try {
            const response = await Axios.post(API.contactUs, { message: `name: ${formData.name} - email: ${formData.email} - number: ${formData.number} - message: ${formData.message}`, subject: formData.subject });
            setFormData({
                name: '',
                email: '',
                subject: '',
                number: '',
                message: ''
            });
            setShowSuccess(true);
        } catch (error) {
        }
    }

    const [{ data: _data, loading, error: fetchError }] = useAxios({
        url: API.contactUs,
        method: "GET",
    });
    const data = _data?.data;
    // use the data from the API call
    const { t } = useTranslation();
    const contactInfo = [
        {
            icon: "flaticon-pin",
            title: "officeAddress",
            value: data?.address,

        },
        {
            icon: "flaticon-phone", title: "phoneNumber", value: `${data?.first_phone}    ${data?.first_phone_desc}`, href: data?.first_phone
        },
        {
            icon: "flaticon-phone", title: "phoneNumber", value: `${data?.second_phone}    ${data?.second_phone_desc}`, href: data?.second_phone
        },
        {
            icon: "flaticon-mail", title: "emailAddress", value: data?.email, href: data?.email
        },

    ];


    const location = { x_map: data?.location.x_map, y_map: data?.location.y_map };
    const formik = useFormik({
        initialValues: {
            name: '',
            mail: '',
            subject: '',
            number: '',
            message: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values, { setErrors }) => {
            if (typeof onSubmit === 'function')
                onSubmit(values, { setErrors });
        },
    });
    // const { handleChange, handleSubmit, values: { name: formName, mail: formEmail, subject: formSubject, number: formPhone, message: formMessage } } = formik;
    if (loading) {
        // dispatch(insertPageLoaderItem("services"));
        return "loading";
    } else if (fetchError) {
        return (<Error404Page />);
    } else {
        return (
            <Fragment>
                <SubBanner title={t("home")} name={t("contactUs")} />
                <SideBanner />
                <div className="contact-2 content-area-5 language-text-dir">
                    <div className="container">
                        {/*!-- Main title --*/}
                        <div className="main-title text-center">
                            <h1>{t("contactUs")}</h1>
                            <p></p>
                        </div>
                        {/*<!-- Contact info -->*/}
                        <div className="contact-info ">
                            <div className="row">
                                {contactInfo.map(({ icon, title, value, href }, index) =>
                                    <div key={generateToken(12)}
                                        className={classes("col-md-3 col-sm-6 mrg-btn-50", { "lang-en-dir": index !== 0 })}>
                                        <i className={icon}></i>
                                        <h5>{t(`${title}`)}</h5>
                                        <a href={href}>{value}</a>
                                    </div>
                                )}
                            </div>
                        </div>
                        <form onSubmit={handleSubmited}>
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="row language-text-align">
                                        <div className="col-md-6 text-left">
                                            <div className="form-group name">
                                                <input type="text" name="name" className="form-control"
                                                    placeholder={t("name")}
                                                    onChange={handleChange}
                                                    value={formData.name} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group email">
                                                <input type="text" name="email" className="form-control"
                                                    placeholder={t("email")}
                                                    onChange={handleChange}
                                                    value={formData.email} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group subject">
                                                <input type="text" name="subject" className="form-control"
                                                    placeholder={t("subject")}
                                                    onChange={handleChange}
                                                    value={formData.subject} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group number">
                                                <input type="text" name="number" className="form-control"
                                                    placeholder={t("number")}
                                                    onChange={handleChange}
                                                    value={formData.number} />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group message">
                                                <textarea className="form-control" name="message"
                                                    placeholder={`${t("writeMessage")} *`}
                                                    onChange={handleChange}
                                                    value={formData.message} />
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            
                                            <div className="send-btn text-center">
                                                <button type="submit" className="btn btn-md button-theme">{t("sendMessage")}
                                                </button>
                                            </div>
                                            {showSuccess && (
                                                <div className="success-message text-success text-center">
                                                    {t("messageSent")}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="opening-hours language-text-align">
                                        <h3>{t("openingHours")}</h3>
                                        <ul className="list-style-none">
                                            {data?.opening_hours.map(({ day, from, to }) =>
                                                <li key={generateToken(12)}>
                                                    <strong>{day}</strong> <span
                                                        className={classes({ "text-red": from === null })}>{from ? `${from} - ${to}` : "closed"}</span>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="location mb-50 language-text-align language-text-dir">
                            <div className="map">
                                <h3 className="heading-2 mt-lg-0 mt-4">{t("location")}</h3>
                                <div id="map" className="contact-map position-relative">
                                    <GoogleMap location={location} name={t('kurdsale')}
                                        size={isMobile ? "600x400" : "1200x200"} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }

};

ContactUs.defaultProps = {};

export default ContactUs;