import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Image, { Shimmer, } from 'react-shimmer';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

const shimmerFallback = <div><Shimmer className="w-100" width={1000} height={200} /></div>;
const SideBannerView = ({ images, loading }) => (
    <>
    <div className="mt-2 mb-2">
        {/* <Container fluid>
            <Row>
                {images.map(({image, href}, index) =>
                    <Col lg={6} md={12} className="mb-3 mb-lg-0">
                        {loading ? shimmerFallback :
                            <a target="_blank" href={href}>
                                <Image
                                    src={image}
                                    fadeIn={true}
                                    fallback={shimmerFallback}
                                    errorFallback={() => <FontAwesomeIcon icon={faExclamationTriangle} color={"red"}/>}
                                    NativeImgProps={{
                                        width: '100%',
                                        height: 'auto',
                                    }}
                                />
                            </a>
                        }
                    </Col>
                )}
            </Row>
        </Container> */}
    </div>
    </>
);
export default SideBannerView;