import 'react-input-range/lib/css/index.css';
import './range-slider.style.scss';

import React from "react";
import propTypes from "prop-types";
import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";

const StyledSlider = withStyles({
    root: {
        color: '#f0151f',
        // height: 16,
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -8,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 8,
        borderRadius: 4,
        inverted: true,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },
})(Slider);
// const OnWaySlider = ({min,max}) => {
//     min = 1965; 
//     max = 2022
//     return (
//         <Slider
//                     size="small"
//                     min={min}
//                     max={2022}
//                     // onChang={(event,value)=>onChange(
//                     //     min = value
//                     // )}
//                     defaultValue={70}
//                     aria-label="Small"
//                     valueLabelDisplay="auto"
//                 />
//     );
// }
const RangeSliderView = ({ title, name, range, value, unit, step, onChange, onChangeComplete }) => {
    const { t } = useTranslation();
    return (
        <div className="range-slider clearfix lang-en-dir">
            <label>{t(`${title}`)}</label>

            <div className="language-text-dir">
                <StyledSlider
                    className="range-slider-container"
                    min={range.min}
                    max={range.max}
                    value={[value.start, value.end]}
                    step={step}
                    onChange={(event, value) => onChange({start: value[0], end: value[1]},
                            //  range.min = value[0],range.max=  value[1] ,
                        
                        )}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                    getAriaValueText={value => `${value} ${unit}`}
                    valueLabelFormat={(value, index) => `${[t('from'), t('to')][index]} ${value}`}
                    onChangeCommitted={onChangeComplete}

                />
                
              
                

                {/* <Slider
                    size="small"
                    min={1960}
                    max={2022}
                    defaultValue={70}
                    aria-label="Small"
                    valueLabelDisplay="auto"
                /> */}
            </div>
            <div className="d-flex justify-content-between lang-en-dir">
                <span className="text-left"> {t('from')} <br /> {value.start} {unit}</span>
                <span className="text-right"> {t('to')} <br />{value.end} {unit}</span>
            </div>
        </div>
    );
}



RangeSliderView.propTtpes = {
    title: propTypes.string.isRequired,
    range: propTypes.shape({
        min: propTypes.oneOf([propTypes.string, propTypes.number]).isRequired,
        max: propTypes.oneOf([propTypes.string, propTypes.number]).isRequired
    }).isRequired,
    value: propTypes.shape({
        start: propTypes.oneOf([propTypes.string, propTypes.number]).isRequired,
        end: propTypes.oneOf([propTypes.string, propTypes.number]).isRequired
    }).isRequired,

    unit: propTypes.string.isRequired,
    onChange: propTypes.func,
};
RangeSliderView.defaultProps = {
    range: {
        min: 0,
        max: 15000,
    },
};
export default RangeSliderView;
