import React, {Fragment} from "react";
import {SideBanner, SubBanner} from "src/components";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {CarDetailsSection, RightSidebar} from "src/pages/car-details/components";
import {Link} from "react-router-dom";
import {generateToken} from "src/helper";
import {useTranslation} from "react-i18next";

const CompareCars = ({ cars}) => {
    const {t} = useTranslation();

    return (
        <Fragment>
            {/* <SubBanner title={t("home")} name={t("carCompare")}/>
            <SideBanner/>
            <div className="featured-car container-fluid content-area-19 ">
                <div className="row">
                    {cars?.map(({images, name, country, price, tag, city, id, features}, idx) =>
                        <Fragment>
                            <div className="col-lg-5 col-md-5">
                                <CarDetailsSection
                                    id={id}
                                    images={images}
                                    name={name}
                                    country={country}
                                    price={price}
                                    tag={tag}
                                    city={city}
                                    features={features}/>
                                <OverlayTrigger
                                    key={generateToken(32)}
                                    placement={'bottom'}
                                    overlay={
                                        <Tooltip id={`tooltip-${'bottom'}`}>
                                            {name}
                                        </Tooltip>
                                    }
                                >
                                    <h1 className="text-red title text-overflow-ellipsis language-text-align language-text-dir ">
                                        <Link className="text-red" to={`/car-details/${id}`}>{name}</Link>
                                    </h1>
                                </OverlayTrigger>
                                <RightSidebar id={id} features={features} show={[0, 1, 2, 3,4,5]}/>
                            </div>
                            {idx === 0 && <div className="col-lg-2 col-md-2">
                                <div className="vs">vs</div>
                            </div>}
                        </Fragment>
                    )};

                </div>
            </div> */}
        </Fragment>
    );
};
export default CompareCars;