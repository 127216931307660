import React, {Fragment} from "react";
import PropTypes from 'prop-types';
import classes from 'classnames';
import {MenuIcon} from '../featured-cars/components/car-details-box/car-details/home_cars_icons/index';

const ActionsButtonsView = ({wishlist,navigate, id, detailsUrl, inWatchList, inCompareList, onWatchlistClick}) => (
    <Fragment>
        {wishlist &&
        <a className={classes("overlap-btn wishlist-btn", {active: inWatchList})}
           onClick={onWatchlistClick}>
            <i className="fa fa-heart-o"/>
        </a>}
        {/* {/* {compare && */}

        {navigate==true ? <a className={classes("overlap-btn compare-btn",)}
           href={detailsUrl}>
               
            <i className="fa fa-expand"/>
            {/* <img  style={{width:'5px', heigt:'5px'}} src={MenuIcon} alt=''/> */}
        </a>:<></>}
    </Fragment>
);

ActionsButtonsView.propTypes = {
    wishlist: PropTypes.bool,
    compare: PropTypes.bool,
};

export default ActionsButtonsView;