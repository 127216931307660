import React, { Fragment } from "react";
import { Banner, Filters, SideBanner } from '../../components';
import FeaturedCarsView from "src/components/featured-cars/featured-cars.view";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import GoogleAdsView from "src/components/google-ads/google-ads-view";

const AgentCarsView = ({
    agent: { images, name }, cars, regions, selectedRegion, page, totalPages,
    onPageChange, onFilterChange, loading,
}) => {
    const { width } = useWindowDimensions();
    return (
        <Fragment>
            <Banner images={images} />
            {(width <= 750) ?
                <GoogleAdsView
                    path='/21894097782/Kurdsale_300x250(1)'
                    size={[300, 250]}
                    id='div-gpt-ad-1617871697027-0'
                    style={{ width: '300px', height: '250px', padding: '10px 0px 10px 0px', margin: '10px auto 10px auto' }}
                />
                :
                <GoogleAdsView
                    path='/21894097782/Kurdsale_728x90_970x90_970x250(1)'
                    size={[[970, 90], [970, 250], [728, 90]]}
                    id='div-gpt-ad-1617871600777-0'
                    style={{ textAlign: 'center', padding: '10px 0px 10px 0px' }}
                />
            }
            <SideBanner />
            {(width <= 750) ?
                <GoogleAdsView
                    path='/21894097782/Kurdsale_300x250(2)'
                    size={[300, 250]}
                    id='div-gpt-ad-1617871672756-0'
                    style={{ width: '300px', height: '250px', padding: '10px 0px 10px 0px', margin: '10px auto 10px auto' }}
                />
                :
                <GoogleAdsView
                    path='/21894097782/Kurdsale_728x90_970x90_970x250(2)'
                    size={[[728, 90], [970, 90], [970, 250]]}
                    id='div-gpt-ad-1617871549077-0'
                    style={{ textAlign: 'center', padding: '10px 0px 10px 0px' }}
                />
            }
            {/*<Filters/>*/}
            <FeaturedCarsView
                page={page}
                cars={cars}
                regions={[]}
                loading={loading}
                totalPages={totalPages}
                onPageChange={onPageChange}
                title={name}
            />

        </Fragment>
    );
};
export default AgentCarsView;